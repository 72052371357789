import React, {useState} from "react";
import styleContent from "../../../styles/modules/Block.module.scss";
import ModalTasksFilter from "./ModalTasksFilter";
import {ButtonIcon, getAccountFio, Loading, Pagination, Select,} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ErrorExecutionContractsTable} from "./ErrorExecutionContractsTable";
import ModalTasksFilterNew from "./ModalTasksFilterNew";
import {ROOT_URL} from "../../../config/url";
import {Avatar} from "../../../components/Avatar/Avatar";

function ErrorExecutionContractsPage(props) {
  const {
    list,
    setList,
    count,
    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    listEmployees,
    listProjects,

    deleteFilter,
    getProjectByFilter,
    filterProjects,
    setFilterProjects,
    getEmployeeByFilter,
    filterEmployees,
    setFilterEmployees,
  } = props;

  const [isModalFilter, setIsModalFilter] = useState(false);
  const [isModalFilterNew, setIsModalFilterNew] = useState(false);

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Ошибки по регистрации
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            {/*<ButtonIcon*/}
            {/*  tooltipText={"Фильтры&nbsp;поиска"}*/}
            {/*  iconName={"custom-search-filter"}*/}
            {/*  iconColor={"#20202075"}*/}
            {/*  size={24}*/}
            {/*  style={{marginLeft: 12}}*/}
            {/*  onClick={() => setIsModalFilter(!isModalFilter)}*/}
            {/*/>*/}
            <ButtonIcon
              tooltipText={"Фильтры&nbsp;поиска"}
              iconName={"custom-search-filter"}
              iconColor={"#20202075"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalFilterNew(!isModalFilterNew)}
            />
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 12}}
              onClick={() => (
                setList(null),
                  setIsUpdateList(!isUpdateList)
              )}
            />
            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 250,
                      title: 250,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {((!!filterEmployees &&
            filterEmployees.length > 0 &&
            !!listEmployees) ||
          (!!filterProjects && filterProjects.length > 0 && !!listProjects)) && (
          <div
            style={{
              marginBottom: 12,
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {!!filterEmployees &&
                !!listEmployees &&
                filterEmployees.map((filter) => (
                  <div
                    key={"filter1_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 4px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      marginBottom: 3,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Сотрудник
                    </span>
                    {!!getEmployeeByFilter(listEmployees, filter) && (
                      <span style={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            {!!getEmployeeByFilter(listEmployees, filter).account.avatar && isMD5Hash(getEmployeeByFilter(listEmployees, filter).account.avatar) && (
                                              <img height={16} width={16} style={{
                                                borderRadius: 16,
                                              }}
                                                   src={ROOT_URL + "/api/account/avatar/" + getEmployeeByFilter(listEmployees, filter).account.avatar}/>
                                            )}
                                            {!getEmployeeByFilter(listEmployees, filter).account.avatar && (
                                              <Avatar size={16}
                                                      value={getAccountFio(getEmployeeByFilter(listEmployees, filter).account)}/>
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(getEmployeeByFilter(listEmployees, filter).account)}
                                        </span>
                                      </span>
                    )}
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterEmployees,
                          filter,
                          setFilterEmployees
                        )
                      }
                    />
                  </div>
                ))}


              {!!filterProjects &&
                !!listProjects &&
                filterProjects.map((filter) => (
                  <div
                    key={"filter2_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      marginBottom: 3,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Проект
                    </span>
                    <span>
                      {!!getProjectByFilter(listProjects, filter) &&
                        getProjectByFilter(listProjects, filter).name}
                    </span>
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(filterProjects, filter, setFilterProjects)
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        )}

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div
            className={
              styleContent._block + " " + styleContent._block_bottom
            }
          >
            <div className={styleContent._block__content}>
              <ErrorExecutionContractsTable
                {...props}
              />
            </div>
          </div>
        )}

        {/*{!!isModalFilter && (*/}
        {/*  <ModalTasksFilter*/}
        {/*    useSearchFilter={{*/}
        {/*      filterProjects,*/}
        {/*      setFilterProjects,*/}
        {/*    }}*/}
        {/*    successfulRequest={() => (*/}
        {/*      setIsUpdateList(!isUpdateList)*/}
        {/*    )}*/}
        {/*    handleClose={() => setIsModalFilter(false)}*/}
        {/*  />*/}
        {/*)}*/}

        {!!isModalFilterNew && (
          <ModalTasksFilterNew
            useSearchFilter={{
              filterEmployees,
              setFilterEmployees,
              filterProjects,
              setFilterProjects,
            }}
            successfulRequest={() => (
              setIsUpdateList(!isUpdateList)
            )}
            handleClose={() => setIsModalFilterNew(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default ErrorExecutionContractsPage;
