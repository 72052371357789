import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {
  getAccountFio,
  getErrorMessageByKey,
  Icon,
  Input, isValidateNullText,
  Modal,
  Select,
  Textarea,
  useList,
  useNotification,
  useObject,
  useProfile,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../../../config/constants";
import ModalControlPointHistories from "./ModalControlPointHistories";

function ModalControlPointEdit(props) {
  const [histories, setHistories] = useState(null);
  const [isPriority, setIsPriority] = useState(false);
  const {profile} = useProfile();

  const {
    list: [list],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsPriority(
        !!getContractControlPoint(data, props.controlPoint) &&
        getContractControlPoint(data, props.controlPoint).is_priority
      );
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  useEffect(() => {
    if (!!object) {
      if (!!getContractControlPoint(object, props.controlPoint)) {
        axios.get(
          "api/contract-control-point/" +
          getContractControlPoint(object, props.controlPoint).uuid +
          "/histories",
          {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              'Content-Type': 'application/json',
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            }
          })
          .then((response) => {
            setHistories(response.data);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }, [object]);

  const inputDate = useRef(null);
  const inputNote = useRef(null);

  const selectWhomId = useRef(null);

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const date = inputDate.current.value;
    const whomId = selectWhomId.current.value;

    let note = null;
    if (!!getContractControlPoint(object, props.controlPoint) && !getContractControlPoint(object, props.controlPoint).deleted_at) {
      note = inputNote.current.value;
      if (isValidateNullText(note)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }
    }

    const jsonData = JSON.stringify({
      date_control: !!date && date.length > 0 ? date : null,
      is_priority: isPriority,
      whom_id:
        whomId.length > 0 ? (whomId === "null" ? null : Number(whomId)) : null,
      note_when_updating: note
    });

    axios
      .post(
        "/api/contract/" +
        props.contract.uuid +
        "/control-point/" +
        props.controlPoint.uuid +
        "/edit",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification(
          !!date && date.length > 0
            ? "Контрольная точка установлена"
            : "Контрольная точка убрана",
          "green"
        );
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getContractControlPoint = (item, controlPoint) => {
    let getContractControlPoint = null;

    if (!!item && !!item.control_points) {
      item.control_points.map((point) => {
        if (point.control_point.uuid === controlPoint.uuid) {
          getContractControlPoint = point;
        }
      });
    }

    return getContractControlPoint;
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  const isProfileInList = (list, profileId) => {
    let isProfile = false;
    if (!!list) {
      list.map((item) => {
        if (item.account.id === profileId) {
          isProfile = true;
        }
      });
    }
    return isProfile;
  };

  const [isModalItemHistories, setIsModalItemHistories] = useState(false);

  return (
    !!object &&
    !!list && (
      <>
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div className="Form__title">
            {(!!object.contract_number && object.contract_number) || "-"}
            <div className="Text__signature">{props.controlPoint.name}</div>
          </div>

          {!!getContractControlPoint(object, props.controlPoint) && !getContractControlPoint(object, props.controlPoint).deleted_at && (
            <div className="Form__field">
              <Textarea
                reference={inputNote}
                isFixed={true}
                label="Причина изменений *"
              />
            </div>
          )}

          <div className="Form__field">
            <Input
              reference={inputDate}
              type="date"
              isFixed={true}
              label="Дата"
              value={
                !!getContractControlPoint(object, props.controlPoint)
                  ? moment(
                    getContractControlPoint(object, props.controlPoint)
                      .date_control
                  ).format("YYYY-MM-DD")
                  : null
              }
            />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectWhomId}
              options={!!list ? getSelectOptions(list) : getSelectOptions([])}
              label="Исполнитель"
              value={
                !!getContractControlPoint(object, props.controlPoint)
                  ? getContractControlPoint(object, props.controlPoint).whom_id
                  : isProfileInList(list, profile.id)
                    ? profile.id
                    : "null"
              }
            />
          </div>

          <div className="Form__field Form__field_last">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isPriority
                  ? "2px solid #ffe082"
                  : "2px dashed #20202015",
                backgroundColor: isPriority ? "#ffe082" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsPriority(!isPriority)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{marginRight: 8}}>
                  <Icon
                    name={"custom-priority"}
                    size={22}
                    color={isPriority ? "#202020" : "#bdbdbd"}
                  />
                </span>
                Высокий приоритет
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isPriority && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center" style={{
            margin: "8px 0 0"
          }}>
            {!!histories && (
              <div className="Action__link"
                   onClick={() => setIsModalItemHistories(true)}>
                Просмотр истории изменении ({histories.length})
              </div>
            )}
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>

        {!!histories && !!isModalItemHistories && (
          <ModalControlPointHistories
            list={histories}
            successfulRequest={() => null}
            handleClose={() => setIsModalItemHistories(false)}
          />
        )}
      </>
    )
  );
}

export default ModalControlPointEdit;
