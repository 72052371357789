import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import moment from "moment";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  Input,
  useObject,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalAppealExecuted(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/appeal/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputDateEnd = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const dateClosing = inputDateEnd.current.value;


    const jsonData = JSON.stringify({
      date_closing: !!dateClosing && dateClosing.length > 0 ? dateClosing : null,
    });

    axios
      .put("/api/appeal/" + props.object.uuid + "/executed", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Завершено", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            Обращение
            <div className="Text__signature">Завершить</div>
          </div>

          <div className="Form__field Form__field_last">
            <Input
              reference={inputDateEnd}
              type="date"
              isFixed={true}
              label="Дата завершения"
              value={
                !!object.date_closing
                  ? moment(object.date_closing).format("YYYY-MM-DD")
                  : null
              }
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Завершить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalAppealExecuted;
