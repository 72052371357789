import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useAction,
  useList,
  useUnroll,
  useLocalStorage, useObject,
} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";
import {useCtrlKey} from "../../../hooks/useCtrlKey";

export const useProjects = () => {
  const [isArchive, setIsArchive] = useState(false);

  const [isSelect, setIsSelect] = useState(false);
  useCtrlKey(() => {
    setIsSelect(true)
  }, () => {
    setIsSelect(false)
  });
  const [selectProjects, setSelectProjects] = useState([]);

  const isSelectProject = (selectList, uuid) => {
    let isTrue = false
    selectList.forEach(u => {
      if (u === uuid) {
        isTrue = true;
      }
    });
    return isTrue
  }

  const isSelectAllProject = (list, selectList) => {
    return !!list && !!selectList && selectList.length === list.length
  }

  const changeSelectProject = (uuid) => {
    let isNew = true;
    let index = 0;

    selectProjects.forEach((u, i) => {
      if (u === uuid) {
        isNew = false;
        index = i;
      }
    });

    if (isNew) {
      setSelectProjects([...selectProjects, uuid]);
    } else {
      selectProjects.splice(index, 1);
      setSelectProjects([...selectProjects]);
    }
  };

  const changeSelectAllProject = (list, selectList) => {
    const selectProjects = []

    if (!!list && !!selectList && selectList.length !== list.length) {
      list.forEach(item => {
        selectProjects.push(item.uuid)
      });
    }

    setSelectProjects(selectProjects);
  };

  const {
    object: [objectAmount],
    update: [isUpdateObjectAmount, setIsUpdateObjectAmount, updateObjectAmount],
  } = useObject({
    apiUrl: "/api/projects/amount",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const [filterProjects, setFilterProjects] = useLocalStorage(
    "newFilterProjectsV2",
    {
      filterEmployees: [],
      // filterCuratorEmployees: [],
      // filterSpecialistEmployees: [],
    }
  );

  const [filterEmployees, setFilterEmployees] = useState(
    filterProjects.filterEmployees || []
  );
  // const [filterCuratorEmployees, setFilterCuratorEmployees] = useState(
  //   filterProjects.filterCuratorEmployees || []
  // );
  // const [filterSpecialistEmployees, setFilterSpecialistEmployees] = useState(
  //   filterProjects.filterSpecialistEmployees || []
  // );

  useEffect(() => {
    setFilterProjects({
      filterEmployees,
      // filterCuratorEmployees,
      // filterSpecialistEmployees,
    });
  }, [
    filterEmployees,
    // filterCuratorEmployees,
    // filterSpecialistEmployees,
  ]);

  const getParams = () => {
    let params = null;
    if (isArchive) {
      params = {
        ...params,
        archive: isArchive,
      };
    }
    return params;
  };

  const getUrlParams = () => {
    return {
      ...getParams(),
      employees:
        (!!filterEmployees &&
          filterEmployees.length > 0 &&
          filterEmployees.join(",")) ||
        "",
      // employees:
      //   (!!filterEmployees &&
      //     filterEmployees.length > 0 &&
      //     filterEmployees.join(",")) ||
      //   "",
      // curators:
      //   (!!filterCuratorEmployees &&
      //     filterCuratorEmployees.length > 0 &&
      //     filterCuratorEmployees.join(",")) ||
      //   "",
      // specialists:
      //   (!!filterSpecialistEmployees &&
      //     filterSpecialistEmployees.length > 0 &&
      //     filterSpecialistEmployees.join(",")) ||
      //   "",
    };
  };

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    params: [_paramsProjects, setParamsProjects],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/projects",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
  });

  useEffect(() => {
    setParamsProjects({ ...getUrlParams() });
  }, [
    isArchive,
    filterEmployees,
    // filterCuratorEmployees,
    // filterSpecialistEmployees,
  ]);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalProjectAdd, setIsModalProjectAdd] = useState(false);
  const [modalProjectEdit, setModalProjectEdit] = useState(null);
  const [modalProjectClientAdd, setModalProjectClientAdd] = useState(null);
  const [modalProjectEmployeeAdd, setModalProjectEmployeeAdd] = useState(null);
  const [isModalFilter, setIsModalFilter] = useState(false);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const { setAction } =
    useAction(
      ["projects", "tasks", "project-tasks", "general-tasks"],
      () => (updateList(), updateObjectAmount())
    );

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "projects", "tasks", "project-tasks", "general-tasks"]);
    } else {
      setAction(["projects", "tasks", "project-tasks", "general-tasks"]);
    }
  }, [list]);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const deleteFilter = (filters, filter, setFilter) => {
    let index = null;
    if (!!filters) {
      filters.forEach((sf, i) => {
        if (sf === String(filter)) {
          index = i;
        }
      });
    }
    if (!!index || String(index) === "0") {
      filters.splice(index, 1);
      setFilter([...filters]);
    }
  };

  const getEmployeeByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.account.id === Number(filter)) {
          object = o;
        }
      });
    }
    return object;
  };

  return {
    list,
    setList,
    count,
    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    isModalProjectAdd,
    setIsModalProjectAdd,

    isModalFilter,
    setIsModalFilter,
    filterEmployees,
    setFilterEmployees,
    // filterCuratorEmployees,
    // setFilterCuratorEmployees,
    // filterSpecialistEmployees,
    // setFilterSpecialistEmployees,
    listEmployees,
    getEmployeeByFilter,
    deleteFilter,

    isArchive,
    setIsArchive,

    modalProjectEdit,
    setModalProjectEdit,

    modalProjectClientAdd,
    setModalProjectClientAdd,

    modalProjectEmployeeAdd,
    setModalProjectEmployeeAdd,

    isSelect,
    selectProjects,
    setSelectProjects,
    isSelectProject,
    isSelectAllProject,
    changeSelectProject,
    changeSelectAllProject,

    objectAmount,
    isUpdateObjectAmount,
    setIsUpdateObjectAmount,
  };
};
