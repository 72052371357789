import React, {useState} from "react";
import ModalContractAdd from "./ModalContractAdd";
import ModalContractEdit from "./ModalContractEdit";
import ModalControlPointEdit from "./ModalControlPointEdit";
import ModalContractsImport from "./ModalContractsImport";
import ModalControlPointExecute from "./ModalControlPointExecute";
import styleTumbler from "../../../../../styles/modules/Tumbler.module.scss";
import {ButtonIcon, ButtonIconExport, Icon, Pagination, Search, Select, Tooltip,} from "@vokymlak/kabinet-ui";
import {ProjectContractsTable} from "./ProjectContractsTable";
import {ROOT_URL} from "../../../../../config/url";
import moment from "moment";
import ModalContractsEditBase from "./ModalContractsEditBase";
import ModalContractsEditExecution from "./ModalContractsEditExecution";
import ModalContractsArchive from "./ModalContractsArchive";
import ModalContractsReArchive from "./ModalContractsReArchive";
import ModalContractsDelete from "./ModalContractsDelete";
import styleCard from "../../../../../styles/modules/Card.module.scss";
import ModalNewContractsDelete from "./ModalNewContractsDelete";
import ModalContractsEditImport from "./ModalContractsEditImport";
import ModalAppealShow from "../../../../ScreenAppeals/AppealsType/ModalAppealShow";
import ModalAppealAdd from "../../../../ScreenAppeals/AppealsType/components/AppealAdd/ModalAppealAdd";

function ProjectContractsPage(props) {
  const {
    searchParam,

    object,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,

    setIsUpdateList,
    isUpdateList,

    modalContractAdd,
    setModalContractAdd,

    modalContractEdit,
    setModalContractEdit,

    setModalControlPointEdit,
    modalControlPointEdit,

    setModalContractImport,
    modalContractImport,

    isInfo,
    setIsInfo,
    isAmount,
    setIsAmount,
    isAddition,
    setIsAddition,

    controlGroups,
    changeSetIsViewControlGroup,

    setModalControlPointExecute,
    modalControlPointExecute,

    isArchive,
    setIsArchive,

    listContractGroups,
    contractGroup,
    setContractGroup,

    apiUrl,
    apiUrlParams,

    archiveCounts,

    selectView,
    setSelectView,

    selectContracts,
    setSelectContracts,

    isModalContractsEditBase,
    setIsModalContractsEditBase,
    isModalContractsEditExecute,
    setIsModalContractsEditExecute,
    isModalContractsArchive,
    setIsModalContractsArchive,
    isModalContractsReArchive,
    setIsModalContractsReArchive,
    isModalContractsDelete,
    setIsModalContractsDelete,
    isModalContractsEditImport,
    setIsModalContractsEditImport,


    getListTotal,
    getSelectList,
    isSelectCalc,
    setIsSelectCalc,

    isNewAdded,
    isModalNewContractsDelete,
    setIsModalNewContractsDelete,

    isDash,
    setIsDash,

    isYear,
    setIsYear,

    isKp,
    setIsKp,
    isEp,
    setIsEp,
    isSmp,
    setIsSmp,
    isEm,
    setIsEm,
    isAg,
    setIsAg,

    isUis,
    setIsUis,

    isRis,
    setIsRis,

    total,
    isUpdateTotal,
    setIsUpdateTotal,

    setIsUpdateArchiveCounts,
    isUpdateArchiveCounts,

    setIsRegistrationError,
    isRegistrationError,

    setIsCtExecution,
    isCtExecution,

    setIsClosureAgreement,
    isClosureAgreement,

    setIsRegistrationFormalization,
    isRegistrationFormalization,
  } = props;

  const [modalObjectShow, setModalObjectShow] = useState(null);
  const [modalObjectRegistrationFormalization, setModalObjectRegistrationFormalization] = useState(null);
  const [modalObjectClosureAgreement, setModalObjectClosureAgreement] = useState(null);

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Договоры
                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                    ({count})
                  </span>
                )}
              </div>
            </div>

            {!!object.duplicate_contracts && (
              <Tooltip
                text={object.duplicate_contracts
                  .map((d) => d.registry_number)
                  .join(", ")}
                style={{
                  marginRight: 2,
                  marginLeft: 2,
                  width: 22,
                  height: 22,
                }}
              >
                <Icon name={"custom-alert"} color={"#202020"} size={22}/>
              </Tooltip>
            )}

            {!!object.count_overdue_points &&
              object.count_overdue_points > 0 && (
                <div
                  className={"Collected__count Collected__count_red"}
                  style={{
                    marginRight: 2,
                    marginLeft: 2,
                    borderRadius: 24,
                  }}
                >
                  {object.count_overdue_points}
                </div>
              )}
            {!!object.count_current_points &&
              object.count_current_points > 0 && (
                <div
                  className={"Collected__count Collected__count_yellow"}
                  style={{
                    marginRight: 2,
                    marginLeft: 2,
                    borderRadius: 24,
                  }}
                >
                  {object.count_current_points}
                </div>
              )}
            {!!object.count_planned_points &&
              object.count_planned_points > 0 && (
                <div
                  className={"Collected__count Collected__count_green"}
                  style={{
                    marginRight: 2,
                    marginLeft: 2,
                    borderRadius: 24,
                  }}
                >
                  {object.count_planned_points}
                </div>
              )}

            <span style={{width: 100, marginLeft: 6, marginRight: 6}}>
              <Search
                reference={inputSearchText}
                isFixed={true}
                label={"Поиск"}
                onChange={() => onChangeSearchText()}
                value={searchParam}
              />
            </span>

            <ButtonIconExport
              tooltipText={"Выгрузить"}
              iconName={"custom-download"}
              iconColor={"#202020"}
              size={24}
              style={{marginLeft: 12}}
              apiUrl={apiUrl + "/export/xlsx?" + apiUrlParams}
              rootUrl={ROOT_URL}
              mimeType={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              fileName={(
                object.name.trim().replaceAll(" ", "_") +
                "_договоры_на_" +
                moment().format("DD_MM_YYYY") +
                ".xlsx"
              ).toLowerCase()}
            />

            <Tooltip text={'По&nbsp;годам'}
                     style={{height: 20, marginLeft: 8}}>
              <div className="Button__swith">
                <div
                  className={
                    "Swith" +
                    ((isYear &&
                        " Swith_active ") ||
                      "")
                  }
                  onClick={() =>
                    setIsYear(!isYear)
                  }
                ></div>
              </div>
            </Tooltip>


            {!!selectContracts && selectContracts.length > 0 && (
              <div
                style={{
                  backgroundColor: "#0061ff",
                  padding: "0 4px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 24,
                  marginLeft: 12,
                }}
              >
                <div
                  style={{
                    padding: "4px 6px",
                    color: "white",
                    fontSize: 14,
                    pointerEvents: "auto",
                    fontWeight: 700,
                    cursor: "default"
                  }}
                >
                  {selectContracts.length}
                </div>
                <ButtonIcon
                  tooltipText={"Отменить"}
                  iconName={"custom-circle-close"}
                  iconColor={"white"}
                  size={18}
                  onClick={() => setSelectContracts([])}
                />
              </div>
            )}

            {!!selectContracts && selectContracts.length > 0 && (
              <ButtonIcon
                tooltipText={"Калькулятор"}
                iconName={"custom-calculate"}
                iconColor={isSelectCalc ? "#0061ff" : "#202020"}
                size={18}
                style={{
                  marginLeft: 12,
                }}
                onClick={() => setIsSelectCalc(!isSelectCalc)}
              />
            )}
          </div>

          <div
            className="Title__settings"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "center",
            }}
          >
            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <Tooltip text={'_&nbsp;Показать'}
                         style={{height: 30}}>
                  <a
                    className={
                      styleTumbler._text +
                      ((isDash === "true" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={isDash === "true" ? () => setIsDash("null") : () => (setIsDash("true"))}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    _П
                  </a>
                </Tooltip>
              </li>
              <li className={styleTumbler._link}>
                <Tooltip text={'_&nbsp;Убрать'}
                         style={{height: 30}}>
                  <a
                    className={
                      styleTumbler._text +
                      ((isDash === "false" &&
                          " " + styleTumbler.active + " ") ||
                        "")
                    }
                    onClick={isDash === "false" ? () => setIsDash("null") : () => (setIsDash("false"))}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    _У
                  </a>
                </Tooltip>
              </li>
            </ul>

            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!selectView &&
                        selectView === "all" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setSelectView("all")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Все
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!selectView &&
                        selectView === "current" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setSelectView("current")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Действ.
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!selectView &&
                        selectView === "completed" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setSelectView("completed")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Заверш.
                </a>
              </li>
            </ul>

            {/*{!!listContractGroups && (*/}
            {/*  <ul*/}
            {/*    className={styleTumbler._}*/}
            {/*    style={{*/}
            {/*      marginRight: 2,*/}
            {/*      marginLeft: 4,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {listContractGroups.map((item, index) => (*/}
            {/*      <li className={styleTumbler._link} key={"item_" + index}>*/}
            {/*        <a*/}
            {/*          className={*/}
            {/*            styleTumbler._text +*/}
            {/*            ((!!contractGroup &&*/}
            {/*                contractGroup.uuid === item.uuid &&*/}
            {/*                " " + styleTumbler.active + " ") ||*/}
            {/*              "")*/}
            {/*          }*/}
            {/*          onClick={() =>*/}
            {/*            setContractGroup(*/}
            {/*              !!contractGroup && contractGroup.uuid === item.uuid*/}
            {/*                ? null*/}
            {/*                : item*/}
            {/*            )*/}
            {/*          }*/}
            {/*          style={{*/}
            {/*            cursor: "pointer",*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {item.name}*/}
            {/*        </a>*/}
            {/*      </li>*/}
            {/*    ))}*/}
            {/*  </ul>*/}
            {/*)}*/}

            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isKp &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => (setIsKp(!isKp), setIsEp(false))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  КП
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isEp &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => (setIsEp(!isEp), setIsKp(false))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  ЕП
                </a>
              </li>
            </ul>

            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isSmp &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setIsSmp(!isSmp)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  СМП
                </a>
              </li>
            </ul>

            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isEm &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setIsEm(!isEm)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  ЭМ
                </a>
              </li>
            </ul>

            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isAg &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setIsAg(!isAg)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  АГ
                </a>
              </li>
            </ul>

            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isRis === "true" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={isRis === "true" ? () => setIsRis("null") : () => (setIsRis("true"))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  РИС
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isRis === "false" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={isRis === "false" ? () => setIsRis("null") : () => (setIsRis("false"))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Черн.
                </a>
              </li>
            </ul>
            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isUis &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={isUis ? () => setIsUis(!isUis) : () => (setIsUis(!isUis))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  ЕИС
                </a>
              </li>
            </ul>
            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isRegistrationError &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => (
                    setIsRegistrationError(!isRegistrationError),
                      setIsClosureAgreement(false),
                      setIsRegistrationFormalization(false)
                  )}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  Ошиб.

                  <div
                    style={{
                      position: "absolute",
                      top: -8,
                      right: -8,
                      display: "flex",
                    }}
                  >
                    {(!!object.count_registration_errors &&
                      object.count_registration_errors > 0) && (
                      <div
                        className={"Collected__count Collected__count_red"}
                        style={{
                          padding: "0 3px",
                          borderRadius: 24,
                          height: 16,
                          fontSize: 12,
                          fontWeight: 700,
                          zIndex: 1
                        }}
                      >
                        {object.count_registration_errors}
                      </div>
                    )}
                  </div>
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isRegistrationFormalization &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => (
                    setIsRegistrationError(false),
                      setIsClosureAgreement(false),
                      setIsRegistrationFormalization(!isRegistrationFormalization)
                  )}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  Исп.
                  <div
                    style={{
                      position: "absolute",
                      top: -8,
                      right: -8,
                      display: "flex",
                    }}
                  >
                    {(!!object.count_registration_formalization &&
                      object.count_registration_formalization > 0) && (
                      <div
                        className={"Collected__count Collected__count_red"}
                        style={{
                          padding: "0 3px",
                          borderRadius: 24,
                          height: 16,
                          fontSize: 12,
                          fontWeight: 700,
                          backgroundColor: "#0061fc",
                          borderColor: "#0061fc",
                          zIndex: 1
                        }}
                      >
                        {object.count_registration_formalization}
                      </div>
                    )}
                  </div>
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isClosureAgreement &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => (
                    setIsRegistrationError(false),
                      setIsClosureAgreement(!isClosureAgreement),
                      setIsRegistrationFormalization(false)
                  )}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  Закр.
                  <div
                    style={{
                      position: "absolute",
                      top: -8,
                      right: -8,
                      display: "flex",
                    }}
                  >
                    {(!!object.count_closure_agreement &&
                      object.count_closure_agreement > 0) && (
                      <div
                        className={"Collected__count Collected__count_red"}
                        style={{
                          padding: "0 3px",
                          borderRadius: 24,
                          height: 16,
                          fontSize: 12,
                          fontWeight: 700,
                          backgroundColor: "#673ab7",
                          borderColor: "#673ab7",
                          zIndex: 1
                        }}
                      >
                        {object.count_closure_agreement}
                      </div>
                    )}
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div className="Title__settings">
            <Tooltip
              text={"Архив"}
              style={{
                marginLeft: 12,
                marginRight: 12,
                cursor: "pointer"
              }}
            >
              <div
                className="Button__icon Button__icon_24"
                onClick={() => setIsArchive(!isArchive)}
                style={{position: "relative"}}
              >
                <Icon name={"custom-archive"} size={24} color={isArchive ? "#202020" : "#20202075"}/>
                {!!archiveCounts && (
                  <div
                    style={{
                      borderRadius: 3,
                      position: "absolute",
                      left: 24,
                      top: "-8px",
                      backgroundColor: "#dbdbdb75",
                      fontSize: 13,
                      padding: "2px 4px",
                    }}
                  >
                    {archiveCounts.count}
                  </div>
                )}
              </div>
            </Tooltip>

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 12}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 56,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 250,
                      title: 250,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                    {
                      value: 750,
                      title: 750,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                }}
              >
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  pageNeighbours={3}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            {isNewAdded(list) && (
              <ButtonIcon
                tooltipText={"Удалить добавленные"}
                iconName={"custom-delete"}
                iconColor={"#202020"}
                size={32}
                style={{marginLeft: 12}}
                onClick={() => setIsModalNewContractsDelete(true)}
              />
            )}

            <ButtonIcon
              tooltipText={"Импорт"}
              iconName={"custom-cloud-upload"}
              iconColor={"#202020"}
              size={26}
              style={{marginLeft: 12}}
              onClick={() => setModalContractImport(object)}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setModalContractAdd(true)}
            />
          </div>
        </div>

        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 0,
            justifyContent: "center"
          }}
        >
          <div className="Title__text">
            <div
              style={{
                display: "flex",
                fontSize: 13,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "flex-start",
                border: "1px solid #ddd",
                borderRadius: 5,
                margin: 2,
                padding: "0 2px",
              }}
            >
              <div
                style={{
                  fontSize: 13,
                  fontWeight: 700,
                  margin: "2px 3px",
                  opacity: 0.5,
                }}
              >
                Дополнительно:
              </div>
              <div
                style={{
                  // opacity: isInfo ? 1 : 0.95,
                  color: isInfo ? "#0061fc" : "#202020",
                  // fontWeight: isInfo ? 700 : 400,
                  opacity: 1,
                  fontWeight: 700,
                  cursor: "pointer",
                  margin: "2px 3px",
                }}
                onClick={() => setIsInfo(!isInfo)}
              >
                ЕИС
              </div>
              <div
                style={{
                  // opacity: isAmount ? 1 : 0.95,
                  color: isAmount ? "#0061fc" : "#202020",
                  // fontWeight: isAmount ? 700 : 400,
                  opacity: 1,
                  fontWeight: 700,
                  cursor: "pointer",
                  margin: "2px 3px",
                }}
                onClick={() => setIsAmount(!isAmount)}
              >
                Сумма
              </div>
              <div
                style={{
                  // opacity: isAddition ? 1 : 0.95,
                  color: isAddition ? "#0061fc" : "#202020",
                  // fontWeight: isAddition ? 700 : 400,
                  opacity: 1,
                  fontWeight: 700,
                  cursor: "pointer",
                  margin: "2px 3px",
                }}
                onClick={() => setIsAddition(!isAddition)}
              >
                Результат и примечание
              </div>
              <div
                style={{
                  // opacity: isCtExecution ? 1 : 0.95,
                  color: isCtExecution ? "#0061fc" : "#202020",
                  // fontWeight: isCtExecution ? 700 : 400,
                  opacity: 1,
                  fontWeight: 700,
                  cursor: "pointer",
                  margin: "2px 3px",
                }}
                onClick={() => setIsCtExecution(!isCtExecution)}
              >
                Отметка источника
              </div>
            </div>
          </div>

          <div
            className="Title__settings"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                fontSize: 13,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "flex-start",
                border: "1px solid #ddd",
                borderRadius: 5,
                margin: 2,
                padding: "0 2px",
              }}
            >
              <div
                style={{
                  fontSize: 13,
                  fontWeight: 700,
                  margin: "2px 3px",
                  opacity: 0.5,
                }}
              >
                Контроль:
              </div>

              {!!controlGroups &&
                controlGroups.map((controlGroup, index) => (
                  <div
                    key={index}
                    style={{
                      // opacity: controlGroup.isView ? 1 : 0.95,
                      color: controlGroup.isView ? "#0061fc" : "#202020",
                      // fontWeight: controlGroup.isView ? 700 : 400,
                      opacity: 1,
                      fontWeight: 700,
                      cursor: "pointer",
                      margin: "2px 3px",
                    }}
                    onClick={() =>
                      changeSetIsViewControlGroup(controlGroups, controlGroup)
                    }
                  >
                    {controlGroup.name}
                  </div>
                ))}
            </div>
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 28px - 22px - 12px - 24px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <ProjectContractsTable {...props} isClient={false} setModalObjectShow={setModalObjectShow} setModalObjectRegistrationFormalization={setModalObjectRegistrationFormalization}
                                     setModalObjectClosureAgreement={setModalObjectClosureAgreement}/>
            </div>

            {!!selectContracts && selectContracts.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  bottom: 24,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  right: 48,
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              >
                {!isSelectCalc && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#202020",
                        padding: "8px 12px",
                        color: "white",
                        marginLeft: 3,
                        marginRight: 3,
                        cursor: "pointer",
                        borderRadius: 24,
                        fontSize: 15,
                        fontWeight: 700,
                        pointerEvents: "auto",
                      }}
                      onClick={() => setIsModalContractsEditImport(true)}
                    >
                      Импорт
                    </div>

                    <div
                      style={{
                        backgroundColor: "#0061ff",
                        padding: "8px 12px",
                        color: "white",
                        marginLeft: 3,
                        marginRight: 3,
                        cursor: "pointer",
                        borderRadius: 24,
                        fontSize: 15,
                        fontWeight: 700,
                        pointerEvents: "auto",
                      }}
                      onClick={() => setIsModalContractsEditBase(true)}
                    >
                      Основание
                    </div>
                    <div
                      style={{
                        backgroundColor: "#0061ff",
                        padding: "8px 12px",
                        color: "white",
                        marginLeft: 3,
                        marginRight: 3,
                        cursor: "pointer",
                        borderRadius: 24,
                        fontSize: 15,
                        fontWeight: 700,
                        pointerEvents: "auto",
                      }}
                      onClick={() => setIsModalContractsEditExecute(true)}
                    >
                      Промежуточное исполнение
                    </div>

                    {!isArchive && (
                      <div
                        style={{
                          backgroundColor: "#202020",
                          padding: "8px 12px",
                          color: "white",
                          marginLeft: 3,
                          marginRight: 3,
                          cursor: "pointer",
                          borderRadius: 24,
                          fontSize: 15,
                          fontWeight: 700,
                          pointerEvents: "auto",
                        }}
                        onClick={() => setIsModalContractsArchive(true)}
                      >
                        В архив
                      </div>
                    )}

                    {isArchive && (
                      <div
                        style={{
                          backgroundColor: "#202020",
                          padding: "8px 12px",
                          color: "white",
                          marginLeft: 3,
                          marginRight: 3,
                          cursor: "pointer",
                          borderRadius: 24,
                          fontSize: 15,
                          fontWeight: 700,
                          pointerEvents: "auto",
                        }}
                        onClick={() => setIsModalContractsReArchive(true)}
                      >
                        Из архив
                      </div>
                    )}

                    <div
                      style={{
                        backgroundColor: "#f53800",
                        padding: "8px 12px",
                        color: "white",
                        marginLeft: 3,
                        marginRight: 3,
                        cursor: "pointer",
                        borderRadius: 24,
                        fontSize: 15,
                        fontWeight: 700,
                        pointerEvents: "auto",
                      }}
                      onClick={() => setIsModalContractsDelete(true)}
                    >
                      Удалить
                    </div>
                  </div>
                )}

                {!!isSelectCalc && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={styleCard._block}
                      style={{
                        boxShadow: "0 8px 14px rgba(0, 0, 0, 0.21)"
                      }}
                    >
                      <div className={styleCard._block__title}
                           style={{
                             fontSize: 14
                           }}>
                        Начальная цена
                      </div>
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 19,
                          marginBottom: 0
                        }}
                      >
                        {(!!getListTotal(getSelectList(list, selectContracts)).startingPrice &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getListTotal(getSelectList(list, selectContracts)).startingPrice / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </div>
                    <div
                      className={styleCard._block}
                      style={{
                        boxShadow: "0 8px 14px rgba(0, 0, 0, 0.21)"
                      }}
                    >
                      <div className={styleCard._block__title}
                           style={{
                             fontSize: 14
                           }}>
                        Цена договора
                      </div>
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 19,
                          marginBottom: 0
                        }}
                      >
                        {(!!getListTotal(getSelectList(list, selectContracts)).amount &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getListTotal(getSelectList(list, selectContracts)).amount / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </div>
                    <div
                      className={styleCard._block}
                      style={{
                        boxShadow: "0 8px 14px rgba(0, 0, 0, 0.21)"
                      }}
                    >
                      <div className={styleCard._block__title}
                           style={{
                             fontSize: 14
                           }}>
                        Сумма текущего года
                      </div>
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 19,
                          marginBottom: 0
                        }}
                      >
                        {(!!getListTotal(getSelectList(list, selectContracts)).amountCurrentYear &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getListTotal(getSelectList(list, selectContracts)).amountCurrentYear / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </div>
                    <div
                      className={styleCard._block}
                      style={{
                        boxShadow: "0 8px 14px rgba(0, 0, 0, 0.21)"
                      }}
                    >
                      <div className={styleCard._block__title}
                           style={{
                             fontSize: 14
                           }}>
                        Сумма экономии
                      </div>
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 19,
                          marginBottom: 0
                        }}
                      >
                        {(!!getListTotal(getSelectList(list, selectContracts)).startingPriceAmount &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getListTotal(getSelectList(list, selectContracts)).startingPriceAmount / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </div>
                    <div
                      className={styleCard._block}
                      style={{
                        backgroundColor: "#f5e8be",
                        boxShadow: "0 8px 14px rgba(0, 0, 0, 0.21)"
                      }}
                    >
                      <div className={styleCard._block__title}
                           style={{
                             fontSize: 14
                           }}>
                        Сумма по плана закупки
                      </div>
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 19,
                          marginBottom: 0,
                        }}
                      >
                        {(!!getListTotal(getSelectList(list, selectContracts)).planAmount &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getListTotal(getSelectList(list, selectContracts)).planAmount / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </div>
                    <div
                      className={styleCard._block}
                      style={{
                        boxShadow: "0 8px 14px rgba(0, 0, 0, 0.21)"
                      }}
                    >
                      <div className={styleCard._block__title}
                           style={{
                             fontSize: 14
                           }}>
                        Сумма по платежам за год
                      </div>
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 19,
                          marginBottom: 0
                        }}
                      >
                        {(!!getListTotal(getSelectList(list, selectContracts)).amountPayments &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getListTotal(getSelectList(list, selectContracts)).amountPayments / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </div>
                    <div
                      className={styleCard._block}
                      style={{
                        boxShadow: "0 8px 14px rgba(0, 0, 0, 0.21)"
                      }}
                    >
                      <div className={styleCard._block__title}
                           style={{
                             fontSize: 14
                           }}>
                        Остаток оплаты по контракту
                      </div>
                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 19,
                          marginBottom: 0
                        }}
                      >
                        {(!!getListTotal(getSelectList(list, selectContracts)).amountAmountPayments &&
                            new Intl.NumberFormat("ru-RU").format(
                              (getListTotal(getSelectList(list, selectContracts)).amountAmountPayments / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {!!modalContractImport && (
          <ModalContractsImport
            project={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalContractImport(null)}
          />
        )}
        {!!modalContractAdd && (
          <ModalContractAdd
            project={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalContractAdd(null)}
          />
        )}
        {!!modalContractEdit && (
          <ModalContractEdit
            contract={modalContractEdit}
            project={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalContractEdit(null)}
          />
        )}

        {!!modalControlPointEdit && (
          <ModalControlPointEdit
            contract={modalControlPointEdit.item}
            controlPoint={modalControlPointEdit.controlPoint}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalControlPointEdit(null)}
          />
        )}
        {!!modalControlPointExecute && (
          <ModalControlPointExecute
            contractControlPoint={modalControlPointExecute}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalControlPointExecute(null)}
          />
        )}

        {!!isModalContractsEditBase && (
          <ModalContractsEditBase
            contracts={selectContracts}
            project={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalContractsEditBase(false)}
          />
        )}

        {!!isModalContractsEditExecute && (
          <ModalContractsEditExecution
            contracts={selectContracts}
            project={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalContractsEditExecute(false)}
          />
        )}

        {!!isModalContractsEditImport && (
          <ModalContractsEditImport
            contracts={selectContracts}
            project={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalContractsEditImport(false)}
          />
        )}

        {!!isModalContractsArchive && (
          <ModalContractsArchive
            contracts={selectContracts}
            project={object}
            successfulRequest={() => (setSelectContracts([]), setIsUpdateList(!isUpdateList))}
            handleClose={() => setIsModalContractsArchive(false)}
          />
        )}

        {!!isModalContractsReArchive && (
          <ModalContractsReArchive
            contracts={selectContracts}
            project={object}
            successfulRequest={() => (setSelectContracts([]), setIsUpdateList(!isUpdateList))}
            handleClose={() => setIsModalContractsReArchive(false)}
          />
        )}

        {!!isModalContractsDelete && (
          <ModalContractsDelete
            contracts={selectContracts}
            project={object}
            successfulRequest={() => (setSelectContracts([]), setIsUpdateList(!isUpdateList))}
            handleClose={() => setIsModalContractsDelete(false)}
          />
        )}

        {!!isModalNewContractsDelete && (
          <ModalNewContractsDelete
            contracts={list}
            project={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalNewContractsDelete(false)}
          />
        )}

        {!!modalObjectShow && (
          <ModalAppealShow
            object={modalObjectShow}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectShow(null)}
          />
        )}

        {!!modalObjectRegistrationFormalization && (
          <ModalAppealAdd
            type={"REGISTRATION_FORMALIZATION"}
            isDraft={false}
            project={object}
            contract={modalObjectRegistrationFormalization.contract}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectRegistrationFormalization(null)}
          />
        )}

        {!!modalObjectClosureAgreement && (
          <ModalAppealAdd
            type={"CLOSURE_AGREEMENT"}
            isDraft={false}
            project={object}
            contract={modalObjectClosureAgreement.contract}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectClosureAgreement(null)}
          />
        )}
      </>
    )
  );
}

export default ProjectContractsPage;
