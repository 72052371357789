import React, {useRef} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, isValidateNullText, Modal, Select, useNotification,} from "@vokymlak/kabinet-ui";

function ModalProjectsEditYear(props) {
  const {notification} = useNotification();
  const selectProjectYear = useRef(null);

  const changeAdd = (event) => {
    event.preventDefault();

    const projectYear = selectProjectYear.current.value;

    if (isValidateNullText(projectYear) || projectYear === "null") {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      projects: props.projects,
      project_year:
        projectYear.length > 0
          ? projectYear === "null"
            ? null
            : Number(projectYear)
          : null,
    });

    axios
      .put("/api/projects/edit/year", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Проекты
          <div className="Text__signature">Смена года</div>
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={selectProjectYear}
            options={[
              {
                value: "null",
                title: "Не выбран",
              },
              {
                value: "2020",
                title: "2020",
              },
              {
                value: "2021",
                title: "2021",
              },
              {
                value: "2022",
                title: "2022",
              },
              {
                value: "2023",
                title: "2023",
              },
              {
                value: "2024",
                title: "2024",
              },
              {
                value: "2025",
                title: "2025",
              },
              {
                value: "2026",
                title: "2026",
              },
              {
                value: "2027",
                title: "2027",
              },
              {
                value: "2028",
                title: "2028",
              },
              {
                value: "2029",
                title: "2029",
              },
              {
                value: "2030",
                title: "2030",
              },
            ]}
            label="Год проекта"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Применить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalProjectsEditYear;
