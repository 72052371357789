import React, {Fragment, useState} from "react";
import ModalProjectAdd from "./ModalProjectAdd";
import {NavLink} from "react-router-dom";
import {
  ButtonIcon,
  getAccountFio,
  Icon,
  Loading,
  Pagination,
  Search,
  Select,
  TextSearchMarker,
  Tooltip,
} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {ProcurementTasksTable} from "./ProcurementTasksTable";
import moment from "moment";
import ModalProjectEdit from "../Project/ModalProjectEdit";
import ModalProjectClientAdd from "./ModalProjectClientAdd";
import ModalProjectEmployeeAdd from "./ModalProjectEmployeeAdd";
import ProjectClientsList from "./ProjectClients/ProjectClientsList";
import ProjectEmployeesList from "./ProjectEmployees/ProjectEmployeesList";
import {ROOT_URL} from "../../../config/url";
import {Avatar} from "../../../components/Avatar/Avatar";
import ModalProjectsImport from "./ModalProjectsImport";
import ModalProjectsImportClean from "./ModalProjectsImportClean";
import ModalProjectImportClean from "../Project/ModalProjectImportClean";
import ModalProjectsFilterNew from "./ModalProjectsFilterNew";
import ModalProjectsEditYear from "./ModalProjectsEditYear";

export const ProjectsPage = (props) => {
  const {
    list,
    setList,
    count,
    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    isModalProjectAdd,
    setIsModalProjectAdd,

    isModalFilter,
    setIsModalFilter,
    filterEmployees,
    setFilterEmployees,
    // filterCuratorEmployees,
    // setFilterCuratorEmployees,
    // filterSpecialistEmployees,
    // setFilterSpecialistEmployees,
    listEmployees,
    getEmployeeByFilter,
    deleteFilter,

    isArchive,
    setIsArchive,

    modalProjectEdit,
    setModalProjectEdit,

    modalProjectClientAdd,
    setModalProjectClientAdd,

    modalProjectEmployeeAdd,
    setModalProjectEmployeeAdd,

    isSelect,
    selectProjects,
    setSelectProjects,
    isSelectProject,
    isSelectAllProject,
    changeSelectProject,
    changeSelectAllProject,

    objectAmount,
    isUpdateObjectAmount,
    setIsUpdateObjectAmount,
  } = props;

  const [tumbler, setTumbler] = useState("tasks");
  const [isModalProjectsEditYear, setIsModalProjectsEditYear] = useState(false);
  const [isModalProjectsImport, setIsModalProjectsImport] = useState(false);
  const [isModalProjectsImportClean, setIsModalProjectsImportClean] = useState(false);
  const [modalProjectImportClean, setModalProjectImportClean] = useState(null);

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Проекты
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <ButtonIcon
              tooltipText={"Фильтры&nbsp;поиска"}
              iconName={"custom-search-filter"}
              iconColor={"#20202075"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalFilter(!isModalFilter)}
            />
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

            <ButtonIcon
              tooltipText={"Архив"}
              iconName={"custom-archive"}
              iconColor={isArchive ? "#202020" : "#20202075"}
              size={24}
              style={{
                marginLeft: 16,
              }}
              onClick={() => setIsArchive(!isArchive)}
            />

            {!!selectProjects && selectProjects.length > 0 && (
              <div
                style={{
                  backgroundColor: "#0061ff",
                  padding: "0 3px 0 4px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 24,
                  marginLeft: 24,
                }}
              >
                <div
                  style={{
                    padding: "4px 6px",
                    color: "white",
                    fontSize: 14,
                    pointerEvents: "auto",
                    fontWeight: 700,
                    cursor: "default"
                  }}
                >
                  {selectProjects.length}
                </div>
                <ButtonIcon
                  style={{
                    marginBottom: 2,
                  }}
                  tooltipText={"Отменить"}
                  iconName={"custom-circle-close"}
                  iconColor={"white"}
                  size={18}
                  onClick={() => setSelectProjects([])}
                />
              </div>
            )}
          </div>

          <div className="Title__settings">
            {!!objectAmount && (
              <div style={{
                display: "flex",
                alignItems: "center",
              }}>

                {!!objectAmount.import && objectAmount.import.reduce((count, item) => count + item.count_queue, 0) > 0 && (
                  <div style={{
                    marginLeft: 6,
                    height: 32,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "right"
                  }}>
                    <div style={{
                      fontSize: 10,
                      fontWeight: 400,
                      color: "#0064ff",
                      cursor: "default"
                    }}>
                      В очереди
                      ({!!objectAmount.import && objectAmount.import.reduce((count, item) => count + item.count_queue, 0)})
                    </div>
                    <div
                      style={{
                        margin: "0",
                      }}
                      className="Button__text"
                      onClick={() => (setIsModalProjectsImportClean(true))}
                    >
                      Очистить импорт
                    </div>
                  </div>
                )}

                <ButtonIcon
                  tooltipText={
                    "В очереди:\n\r Общая&nbsp;за&nbsp;год&nbsp;" +
                    objectAmount.import.reduce((count, item) => count + item.count_project_contracts, 0) +
                    "\n\r ПФХД&nbsp;за&nbsp;год&nbsp;" +
                    objectAmount.import.reduce((count, item) => count + item.count_project_pfhd, 0) +
                    "\n\r Одиночные&nbsp;договоры&nbsp;" +
                    objectAmount.import.reduce((count, item) => count + item.count_project_contract, 0) +
                    "\n\r Группой&nbsp;договоры&nbsp;" +
                    objectAmount.import.reduce((count, item) => count + item.count_project_select_contracts, 0) +
                    "\n\r Отложенные&nbsp;группой&nbsp;" +
                    objectAmount.import.reduce((count, item) => count + item.count_slow_project_select_contracts, 0)
                  }
                  iconName={"custom-priority"}
                  iconColor={
                    !!objectAmount.import && objectAmount.import.reduce((count, item) => count + item.count_queue, 0) > 0
                      ? "#0064ff"
                      : "#202020"
                  }
                  size={24}
                  style={{
                    marginLeft: 12,
                    cursor: "default"
                  }}
                  onClick={() => setIsUpdateObjectAmount(!isUpdateObjectAmount)}
                />

              </div>
            )}

            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList), setIsUpdateObjectAmount(!isUpdateObjectAmount))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 25,
                      title: 25,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalProjectAdd(true)}
            />
          </div>
        </div>

        {((!!filterEmployees &&
            filterEmployees.length > 0 &&
            !!listEmployees)
          // ||
          // (!!filterCuratorEmployees && filterCuratorEmployees.length > 0 && !!listEmployees) ||
          // (!!filterSpecialistEmployees && filterSpecialistEmployees.length > 0 && !!listEmployees)
        ) && (
          <div
            style={{
              marginBottom: 12,
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {!!filterEmployees &&
                !!listEmployees &&
                filterEmployees.map((filter) => (
                  <div
                    key={"filter1_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 4px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      marginBottom: 3,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Сотрудник
                    </span>
                    {!!getEmployeeByFilter(listEmployees, filter) && (
                      <span style={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            {!!getEmployeeByFilter(listEmployees, filter).account.avatar && isMD5Hash(getEmployeeByFilter(listEmployees, filter).account.avatar) && (
                                              <img height={16} width={16} style={{
                                                borderRadius: 16,
                                              }}
                                                   src={ROOT_URL + "/api/account/avatar/" + getEmployeeByFilter(listEmployees, filter).account.avatar}/>
                                            )}
                                            {!getEmployeeByFilter(listEmployees, filter).account.avatar && (
                                              <Avatar size={16}
                                                      value={getAccountFio(getEmployeeByFilter(listEmployees, filter).account)}/>
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(getEmployeeByFilter(listEmployees, filter).account)}
                                        </span>
                                      </span>
                    )}
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterEmployees,
                          filter,
                          setFilterEmployees
                        )
                      }
                    />
                  </div>
                ))}

              {/*{!!filterCuratorEmployees &&*/}
              {/*  !!listEmployees &&*/}
              {/*  filterCuratorEmployees.map((filter) => (*/}
              {/*    <div*/}
              {/*      key={"filter11_" + filter}*/}
              {/*      style={{*/}
              {/*        fontSize: 12,*/}
              {/*        fontWeight: 400,*/}
              {/*        padding: "3px 3px 3px 9px",*/}
              {/*        backgroundColor: "#dfdfdf",*/}
              {/*        margin: "0 2px",*/}
              {/*        borderRadius: 24,*/}
              {/*        display: "flex",*/}
              {/*        alignItems: "center",*/}
              {/*        position: "relative",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <span*/}
              {/*        style={{*/}
              {/*          fontSize: 10,*/}
              {/*          position: "absolute",*/}
              {/*          left: 12,*/}
              {/*          top: -6,*/}
              {/*          opacity: 0.75,*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        Куратор*/}
              {/*      </span>*/}
              {/*      <span>*/}
              {/*        {!!getEmployeeByFilter(listEmployees, filter) &&*/}
              {/*          getAccountFio(*/}
              {/*            getEmployeeByFilter(listEmployees, filter).account*/}
              {/*          )}*/}
              {/*      </span>*/}
              {/*      <ButtonIcon*/}
              {/*        tooltipText={"Убрать"}*/}
              {/*        iconName={"custom-circle-close"}*/}
              {/*        iconColor={"#20202075"}*/}
              {/*        size={18}*/}
              {/*        style={{marginLeft: 3}}*/}
              {/*        onClick={() =>*/}
              {/*          deleteFilter(*/}
              {/*            filterCuratorEmployees,*/}
              {/*            filter,*/}
              {/*            setFilterCuratorEmployees*/}
              {/*          )*/}
              {/*        }*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  ))}*/}

              {/*{!!filterSpecialistEmployees &&*/}
              {/*  !!listEmployees &&*/}
              {/*  filterSpecialistEmployees.map((filter) => (*/}
              {/*    <div*/}
              {/*      key={"filter12_" + filter}*/}
              {/*      style={{*/}
              {/*        fontSize: 12,*/}
              {/*        fontWeight: 400,*/}
              {/*        padding: "3px 3px 3px 9px",*/}
              {/*        backgroundColor: "#dfdfdf",*/}
              {/*        margin: "0 2px",*/}
              {/*        borderRadius: 24,*/}
              {/*        display: "flex",*/}
              {/*        alignItems: "center",*/}
              {/*        position: "relative",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <span*/}
              {/*        style={{*/}
              {/*          fontSize: 10,*/}
              {/*          position: "absolute",*/}
              {/*          left: 12,*/}
              {/*          top: -6,*/}
              {/*          opacity: 0.75,*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        Специалист*/}
              {/*      </span>*/}
              {/*      <span>*/}
              {/*        {!!getEmployeeByFilter(listEmployees, filter) &&*/}
              {/*          getAccountFio(*/}
              {/*            getEmployeeByFilter(listEmployees, filter).account*/}
              {/*          )}*/}
              {/*      </span>*/}
              {/*      <ButtonIcon*/}
              {/*        tooltipText={"Убрать"}*/}
              {/*        iconName={"custom-circle-close"}*/}
              {/*        iconColor={"#20202075"}*/}
              {/*        size={18}*/}
              {/*        style={{marginLeft: 3}}*/}
              {/*        onClick={() =>*/}
              {/*          deleteFilter(*/}
              {/*            filterSpecialistEmployees,*/}
              {/*            filter,*/}
              {/*            setFilterSpecialistEmployees*/}
              {/*          )*/}
              {/*        }*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  ))}*/}
            </div>
          </div>
        )}

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    style={{
                      backgroundColor: !!isSelectProject && isSelectProject(selectProjects, item.uuid) && "#0061ff25"
                    }}
                    onClick={!!isSelect && isSelect ? () => changeSelectProject(item.uuid) : () => changeUnroll(item.uuid)}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div>
                            <TextSearchMarker
                              text={item.name}
                              search={searchQuery}
                            />
                            {!!item.project_year && (
                              <span
                                style={{
                                  opacity: 0.5,
                                  marginLeft: 4,
                                  fontSize: 13,
                                }}
                              >
                                {item.project_year}
                              </span>
                            )}

                            {!!item.law && (
                              <span
                                style={{
                                  marginLeft: 4,
                                  fontSize: 13,
                                  opacity: 0.5,
                                }}
                              >
                                {"("}
                                <TextSearchMarker
                                  text={item.law.name}
                                  search={searchQuery}
                                />
                                {")"}
                              </span>
                            )}
                          </div>
                          {!!item.company && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              <TextSearchMarker
                                text={item.company.short_name}
                                search={searchQuery}
                              />
                            </div>
                          )}

                          {(!!item.clients || !!item.employees) && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                marginTop: 6,
                              }}
                            >
                              {!!item.clients && item.clients.map((item) => (
                                <div
                                  key={item.uuid}
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    padding: "3px 9px 3px 3px",
                                    backgroundColor: "#20202015",
                                    marginRight: 4,
                                    borderRadius: 24,
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                    marginBottom: 3,
                                  }}
                                >
                                      <span
                                        style={{
                                          fontSize: 10,
                                          position: "absolute",
                                          left: 16,
                                          top: -8,
                                          opacity: 1,
                                          backgroundColor: "#202020",
                                          color: "white",
                                          padding: "0px 4px",
                                          borderRadius: 24
                                        }}
                                      >
                                        Клиент
                                      </span>
                                  <span style={{
                                    display: "flex"
                                  }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            <Icon
                                              name={"custom-account-circle"}
                                              size={16}
                                              color={"#202020"}
                                            />
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(item.client)}
                                        </span>
                                      </span>
                                </div>
                              ))}

                              {!!item.employees && item.employees.map((item) => (
                                <div
                                  key={item.uuid}
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    padding: "3px 9px 3px 3px",
                                    backgroundColor: "#20202015",
                                    marginRight: 4,
                                    borderRadius: 24,
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                    marginBottom: 3,
                                  }}
                                >
                                  {!!item.project_employee_role_type && (
                                    <span
                                      style={{
                                        fontSize: 10,
                                        position: "absolute",
                                        left: 16,
                                        top: -8,
                                        opacity: 1,
                                        backgroundColor: "#202020",
                                        color: "white",
                                        padding: "0px 4px",
                                        borderRadius: 24,
                                        whiteSpace: "nowrap"
                                      }}
                                    >
                                          {item.project_employee_role_type.name === 'Специалист по исполнению'
                                            ? 'Специалист'
                                            : item.project_employee_role_type.name}
                                        </span>
                                  )}
                                  <span style={{
                                    display: "flex"
                                  }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            {!!item.employee.avatar && isMD5Hash(item.employee.avatar) && (
                                              <img height={16} width={16} style={{
                                                borderRadius: 16,
                                              }} src={ROOT_URL + "/api/account/avatar/" + item.employee.avatar}/>
                                            )}
                                            {!item.employee.avatar && (
                                              <Avatar size={16} value={getAccountFio(item.employee)}/>
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(item.employee)}
                                        </span>
                                      </span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">

                      {(!!item.count_registration_errors &&
                        item.count_registration_errors > 0) && (
                        <Tooltip
                          text={
                            "Ошибок&nbsp;регистрации&nbsp;" +
                            item.count_registration_errors
                          }
                          style={{
                            marginRight: 8,
                            height: 28
                          }}
                        >
                          <div
                            className={"Collected__count Collected__count_red"}
                            style={{
                              margin: 0,
                              borderRadius: 24,
                              flexDirection: "row",
                              height: 28
                            }}
                          >
                            <span
                              style={{marginRight: 4, height: 12}}>
                            <Icon
                              name={"custom-alert"}
                              color={"#ffffff"}
                              size={14}
                            />
                            </span>
                            <span
                              style={{height: 16}}>
                            {item.count_registration_errors}
                            </span>
                          </div>
                        </Tooltip>
                      )}

                      {(!!item.count_registration_formalization &&
                        item.count_registration_formalization > 0) && (
                        <Tooltip
                          text={
                            "Исполнение&nbsp;договоров&nbsp;" +
                            item.count_registration_formalization
                          }
                          style={{
                            marginRight: 8,
                            height: 28
                          }}
                        >
                          <div
                            className={"Collected__count Collected__count_red"}
                            style={{
                              margin: 0,
                              borderRadius: 24,
                              flexDirection: "row",
                              height: 28,
                              backgroundColor: "#0061fc",
                              borderColor: "#0061fc"
                            }}
                          >
                            {/*<span*/}
                            {/*  style={{marginRight: 4, height: 12}}>*/}
                            {/*<Icon*/}
                            {/*  name={"custom-circle-close"}*/}
                            {/*  color={"#ffffff"}*/}
                            {/*  size={14}*/}
                            {/*/>*/}
                            {/*</span>*/}
                            <span
                              style={{height: 16}}>
                            {item.count_registration_formalization}
                            </span>
                          </div>
                        </Tooltip>
                      )}

                      {(!!item.count_closure_agreement &&
                        item.count_closure_agreement > 0) && (
                        <Tooltip
                          text={
                            "Закрытие&nbsp;договоров&nbsp;" +
                            item.count_closure_agreement
                          }
                          style={{
                            marginRight: 8,
                            height: 28
                          }}
                        >
                          <div
                            className={"Collected__count Collected__count_red"}
                            style={{
                              margin: 0,
                              borderRadius: 24,
                              flexDirection: "row",
                              height: 28,
                              backgroundColor: "#673ab7",
                              borderColor: "#673ab7"
                            }}
                          >
                            <span
                              style={{marginRight: 4, height: 12}}>
                            <Icon
                              name={"custom-circle-close"}
                              color={"#ffffff"}
                              size={14}
                            />
                            </span>
                            <span
                              style={{height: 16}}>
                            {item.count_closure_agreement}
                            </span>
                          </div>
                        </Tooltip>
                      )}


                      <div style={{
                        display: "flex",
                        alignItems: "center",
                      }}>

                        {!!item.ris_schedulers && item.ris_schedulers[0].is_work && !!item.ris_schedulers[0].attempt_date && (
                          <div style={{
                            marginLeft: 6,
                            height: 32,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400
                            }}>
                              Дата обновления
                            </div>
                            <div style={{
                              fontSize: 12,
                              fontWeight: 400
                            }}>
                              {moment(item.ris_schedulers[0].attempt_date.substring(0, 19)).format('DD.MM.YYYY HH:mm:ss')}
                            </div>
                            {item.ris_schedulers[0].is_queue && (
                              <div style={{
                                fontSize: 10,
                                fontWeight: 400,
                                color: "#0064ff"
                              }}>
                                В очереди ({item.ris_schedulers[0].count_queue})
                              </div>
                            )}
                            {!item.ris_schedulers[0].attempt_is_auth && (
                              <div style={{
                                fontSize: 10,
                                fontWeight: 400,
                                color: "#ff0000"
                              }}>
                                Не авторизован
                              </div>
                            )}
                          </div>
                        )}

                        <ButtonIcon
                          tooltipText={
                            "В очереди:\n\r Общая&nbsp;за&nbsp;год&nbsp;" +
                            (!!item.ris_schedulers && item.ris_schedulers[0].count_project_contracts || 0) +
                            "\n\r ПФХД&nbsp;за&nbsp;год&nbsp;" +
                            (!!item.ris_schedulers && item.ris_schedulers[0].count_project_pfhd || 0) +
                            "\n\r Одиночные&nbsp;договоры&nbsp;" +
                            (!!item.ris_schedulers && item.ris_schedulers[0].count_project_contract || 0) +
                            "\n\r Группой&nbsp;договоры&nbsp;" +
                            (!!item.ris_schedulers && item.ris_schedulers[0].count_project_select_contracts || 0) +
                            "\n\r Отложенные&nbsp;группой&nbsp;" +
                            (!!item.ris_schedulers && item.ris_schedulers[0].count_slow_project_select_contracts || 0)
                          }
                          iconName={"custom-priority"}
                          iconColor={
                            !!item.ris_schedulers && item.ris_schedulers[0].is_work &&
                            !!item.ris_schedulers[0].company_authorize &&
                            !item.ris_schedulers[0].company_authorize.deleted_at
                              ? "#0064ff"
                              : "#202020"
                          }
                          size={24}
                          style={{marginLeft: 12}}
                        />

                      </div>

                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginLeft: 12,
                        marginRight: 2,
                        textAlign: "right"
                      }}>
                      </div>


                      {!!item.procurement_tasks &&
                        item.procurement_tasks.length > 0 && (
                          <Tooltip
                            text={"Текущие&nbsp;закупки"}
                            style={{
                              marginLeft: 4,
                              marginRight: 4,
                            }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                backgroundColor: "#202020",
                                padding: "3px 7px",
                                fontSize: 12,
                                borderRadius: 24,
                                color: "white",
                                fontWeight: 700,
                              }}
                            >
                              {item.procurement_tasks.length}
                            </div>
                          </Tooltip>
                        )}

                      {!!item.duplicate_contracts && (
                        <Tooltip
                          text={"Дубли&nbsp;договоров!"}
                          style={{
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          <div
                            style={{
                              width: 22,
                              height: 22,
                            }}
                          >
                            <Icon name={"custom-alert"} color={"#202020"} size={22}/>
                          </div>
                        </Tooltip>
                      )}

                      {((!!item.count_overdue_points &&
                          item.count_overdue_points > 0) ||
                        (!!item.count_overdue_tasks &&
                          item.count_overdue_tasks > 0) ||
                        (!!item.count_overdue_generals &&
                          item.count_overdue_generals > 0)) && (
                        <Tooltip
                          text={
                            "Просроченные: Контрольные&nbsp;точки&nbsp;" +
                            item.count_overdue_points +
                            " Задачи&nbsp;проекта&nbsp;" +
                            item.count_overdue_tasks +
                            " Общие&nbsp;задачи&nbsp;" +
                            item.count_overdue_generals
                          }
                          style={{
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          <div
                            className={"Collected__count Collected__count_red"}
                            style={{
                              margin: 0,
                              borderRadius: 24,
                            }}
                          >
                            {item.count_overdue_points +
                              item.count_overdue_tasks +
                              item.count_overdue_generals}
                          </div>
                        </Tooltip>
                      )}
                      {((!!item.count_current_points &&
                          item.count_current_points > 0) ||
                        (!!item.count_current_tasks &&
                          item.count_current_tasks > 0) ||
                        (!!item.count_current_generals &&
                          item.count_current_generals > 0)) && (
                        <Tooltip
                          text={
                            "Текущие: Контрольные&nbsp;точки&nbsp;" +
                            item.count_current_points +
                            " Задачи&nbsp;проекта&nbsp;" +
                            item.count_current_tasks +
                            " Общие&nbsp;задачи&nbsp;" +
                            item.count_current_generals
                          }
                          style={{
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          <div
                            className={
                              "Collected__count Collected__count_yellow"
                            }
                            style={{
                              margin: 0,
                              borderRadius: 24,
                            }}
                          >
                            {item.count_current_points +
                              item.count_current_tasks +
                              item.count_current_generals}
                          </div>
                        </Tooltip>
                      )}
                      {((!!item.count_planned_points &&
                          item.count_planned_points > 0) ||
                        (!!item.count_planned_tasks &&
                          item.count_planned_tasks > 0) ||
                        (!!item.count_planned_generals &&
                          item.count_planned_generals > 0)) && (
                        <Tooltip
                          text={
                            "Ближайшие: Контрольные&nbsp;точки&nbsp;" +
                            item.count_planned_points +
                            " Задачи&nbsp;проекта&nbsp;" +
                            item.count_planned_tasks +
                            " Общие&nbsp;задачи&nbsp;" +
                            item.count_planned_generals
                          }
                          style={{
                            marginLeft: 4,
                            marginRight: 4,
                          }}
                        >
                          <div
                            className={
                              "Collected__count Collected__count_green"
                            }
                            style={{
                              margin: 0,
                              borderRadius: 24,
                            }}
                          >
                            {item.count_planned_points +
                              item.count_planned_tasks +
                              item.count_planned_generals}
                          </div>
                        </Tooltip>
                      )}


                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginRight: 12,
                        marginLeft: 2,
                        textAlign: "right"
                      }}>
                      </div>

                      <Tooltip
                        text={"Перейти"}
                      >
                        <div style={{height: 24}}>
                          <NavLink to={"/project/" + item.uuid + "/contracts"} target={"_black"}>
                            <Icon
                              name={"custom-circle-arrow-right"}
                              size={24}
                              color={"#202020"}
                            />
                          </NavLink>
                        </div>
                      </Tooltip>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header" style={{
                        marginBottom: 12
                      }}>
                        <div className="Header__title">
                          <ul className={styleTumbler._} style={{
                            marginRight: 24
                          }}>
                            <li className={styleTumbler._link}>
                              <a
                                className={
                                  styleTumbler._text +
                                  ((!!tumbler &&
                                      tumbler === "tasks" &&
                                      " " + styleTumbler.active + " ") ||
                                    "")
                                }
                                onClick={() => setTumbler("tasks")}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Задачи по закупкам
                              </a>
                            </li>
                            <li className={styleTumbler._link}>
                              <a
                                className={
                                  styleTumbler._text +
                                  ((!!tumbler &&
                                      tumbler === "clients" &&
                                      " " + styleTumbler.active + " ") ||
                                    "")
                                }
                                onClick={() => setTumbler("clients")}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Клиенты
                              </a>
                            </li>
                            <li className={styleTumbler._link}>
                              <a
                                className={
                                  styleTumbler._text +
                                  ((!!tumbler &&
                                      tumbler === "employees" &&
                                      " " + styleTumbler.active + " ") ||
                                    "")
                                }
                                onClick={() => setTumbler("employees")}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                Сотрудники
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="Header__settings">
                          <div
                            className="Button__text"
                            style={{
                              marginLeft: 24,
                            }}
                            onClick={() => (setModalProjectImportClean(item))}
                          >
                            Очистить импорт
                          </div>
                          <div
                            className="Button__text"
                            style={{
                              marginLeft: 24,
                            }}
                            onClick={() => setModalProjectClientAdd(item)}
                          >
                            Добавить клиента
                          </div>

                          <div
                            className="Button__text"
                            style={{
                              marginLeft: 24,
                            }}
                            onClick={() =>
                              setModalProjectEmployeeAdd(item)
                            }
                          >
                            Добавить сотрудника
                          </div>
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{marginLeft: 12}}
                            onClick={() => setModalProjectEdit(item)}
                          />
                        </div>
                      </div>

                      {!!tumbler && tumbler === "tasks" && (
                        <>
                          {!!item.procurement_tasks && (
                            <div className={styleContent._block}>
                              <div className={styleContent._block__title}>
                                Задачи по закупкам{" "}
                                {!!item.procurement_tasks &&
                                  " (" + item.procurement_tasks.length + ")"}
                              </div>
                              <div className={styleContent._block__content}>
                                <ProcurementTasksTable {...props} item={item}/>
                              </div>
                            </div>
                          )}
                          {!item.procurement_tasks && (
                            <div style={{
                              opacity: .25,
                              fontSize: 21,
                              fontWeight: 400,
                              width: "100%"
                            }}>
                              Нет задач по закупкам
                            </div>
                          )}
                        </>
                      )}

                      {!!tumbler && tumbler === "clients" && (
                        <>
                          {!!item.clients && (
                            <ProjectClientsList {...props} object={item}/>
                          )}
                          {!item.clients && (
                            <div style={{
                              opacity: .25,
                              fontSize: 21,
                              fontWeight: 400,
                              width: "100%"
                            }}>
                              Нет привязанных клиентов
                            </div>
                          )}
                        </>
                      )}

                      {!!tumbler && tumbler === "employees" && (
                        <>
                          {!!item.employees && (
                            <ProjectEmployeesList {...props} object={item}/>
                          )}
                          {!item.employees && (
                            <div style={{
                              opacity: .25,
                              fontSize: 21,
                              fontWeight: 400,
                              width: "100%"
                            }}>
                              Нет привязанных сотрудников
                            </div>
                          )}
                        </>
                      )}
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>

            {!!selectProjects && selectProjects.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  bottom: 24,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  right: 48,
                  zIndex: 10,
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#202020",
                      padding: "8px 12px",
                      color: "white",
                      marginLeft: 3,
                      marginRight: 3,
                      cursor: "pointer",
                      borderRadius: 24,
                      fontSize: 15,
                      fontWeight: 700,
                      pointerEvents: "auto",
                    }}
                    onClick={() => setIsModalProjectsEditYear(true)}
                  >
                    Смена года
                  </div>
                  <div
                    style={{
                      backgroundColor: "#0061ff",
                      padding: "8px 12px",
                      color: "white",
                      marginLeft: 3,
                      marginRight: 3,
                      cursor: "pointer",
                      borderRadius: 24,
                      fontSize: 15,
                      fontWeight: 700,
                      pointerEvents: "auto",
                    }}
                    onClick={() => setIsModalProjectsImport(true)}
                  >
                    На импорт
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {!!isModalProjectAdd && (
          <ModalProjectAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalProjectAdd(false)}
          />
        )}

        {/*{!!isModalFilter && (*/}
        {/*  <ModalProjectsFilter*/}
        {/*    useSearchFilter={{*/}
        {/*      filterEmployees,*/}
        {/*      setFilterEmployees,*/}
        {/*      // filterCuratorEmployees,*/}
        {/*      // setFilterCuratorEmployees,*/}
        {/*      // filterSpecialistEmployees,*/}
        {/*      // setFilterSpecialistEmployees,*/}
        {/*    }}*/}
        {/*    successfulRequest={() => setIsUpdateList(!isUpdateList)}*/}
        {/*    handleClose={() => setIsModalFilter(false)}*/}
        {/*  />*/}
        {/*)}*/}

        {!!isModalFilter && (
          <ModalProjectsFilterNew
            useSearchFilter={{
              filterEmployees,
              setFilterEmployees,
              // filterCuratorEmployees,
              // setFilterCuratorEmployees,
              // filterSpecialistEmployees,
              // setFilterSpecialistEmployees,
            }}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalFilter(false)}
          />
        )}

        {!!modalProjectClientAdd && (
          <ModalProjectClientAdd
            project={modalProjectClientAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalProjectClientAdd(null)}
          />
        )}

        {!!modalProjectEmployeeAdd && (
          <ModalProjectEmployeeAdd
            project={modalProjectEmployeeAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalProjectEmployeeAdd(null)}
          />
        )}

        {!!modalProjectEdit && (
          <ModalProjectEdit
            project={modalProjectEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalProjectEdit(null)}
          />
        )}

        {!!isModalProjectsImport && (
          <ModalProjectsImport
            projects={selectProjects}
            successfulRequest={() => (setSelectProjects([]), setIsUpdateList(!isUpdateList), setIsUpdateObjectAmount(!isUpdateObjectAmount))}
            handleClose={() => setIsModalProjectsImport(false)}
          />
        )}

        {!!isModalProjectsEditYear && (
          <ModalProjectsEditYear
            projects={selectProjects}
            successfulRequest={() => (setSelectProjects([]), setIsUpdateList(!isUpdateList))}
            handleClose={() => setIsModalProjectsEditYear(false)}
          />
        )}

        {!!isModalProjectsImportClean && (
          <ModalProjectsImportClean
            successfulRequest={() => (setSelectProjects([]), setIsUpdateList(!isUpdateList), setIsUpdateObjectAmount(!isUpdateObjectAmount))}
            handleClose={() => setIsModalProjectsImportClean(false)}
          />
        )}

        {!!modalProjectImportClean && (
          <ModalProjectImportClean
            project={modalProjectImportClean}
            successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsUpdateObjectAmount(!isUpdateObjectAmount))}
            handleClose={() => setModalProjectImportClean(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
