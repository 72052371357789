import React, {useEffect, useState} from "react";
import {useMatch, useNavigate} from "react-router-dom";
import "./AppealsMenu.scss";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ROOT_URL} from "../../../config/url";
import {getAccountFio, Icon, useAction, useObject} from "@vokymlak/kabinet-ui";
import styleCard from "../../../styles/modules/Card.module.scss";

export const AppealsMenu = (props) => {
  const match = useMatch("/appeals/:appealType");
  const navigate = useNavigate();

  const [type, setType] = useState(
    (!!match && !!match.params && !!match.params.appealType &&
    (match.params.appealType === 'all'
      ? "null"
      : match.params.appealType) || "null")
  );
  const [status, setStatus] = useState("null");

  const [selectAppeal, setSelectAppeal] = useState(null);


  const getParams = () => {
    let params = null;
    if (!!props.selectCompany) {
      params = {
        ...params,
        company: props.selectCompany.uuid,
      };
    }
    if (!!props.selectEmployee) {
      params = {
        ...params,
        employee: props.selectEmployee.uuid,
      };
    }
    if (!!props.selectTab) {
      params = {
        ...params,
        tab: props.selectTab,
      };
    }

    return params
  }


  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, changeUpdateObject],
    setApiUrlParams,
  } = useObject({
    apiUrl: "/api/appeals/count",
    rootUrl: ROOT_URL,
    urlParams: getParams()
  });

  useEffect(() => {
    props.setObject(object);
  }, [object]);

  useEffect(() => {
    changeUpdateObject();
  }, [props.updateMenu]);

  const changeSelectAppeal = (t) => {
    if (!!t && type !== t.tag) {
      setType(t.tag)
      setSelectAppeal(t)
      navigate("/appeals/" + t.tag)
    } else {
      setSelectAppeal(null)
      setType("null")
      setStatus("null")
      navigate("/appeals/all")
    }
  }

  useEffect(() => {
    setApiUrlParams({...getParams()});
  }, [props.selectCompany, props.selectEmployee, props.selectTab])


  useAction("appeals", () => changeUpdateObject());

  const getIconNameAppeal = (tag) => {
    switch (tag) {
      // case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
      //   return "line-event";
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "line-free-cancellation";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "line-task";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "line-task-alt";

      case "DOCUMENTS_FOR_PURCHASE":
        return "line-receipt-long";

      case "REGISTRATION_AGREEMENT":
        return "line-lab-profile";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "line-note-stack-add";

      case "REGISTRATION_FORMALIZATION":
        return "line-contract";

      case "CLOSURE_AGREEMENT":
        return "line-contract-delete";

      case "ONE_MORE_QUESTION":
        return "line-help";
      // case "ONE_MORE_QUESTION":
      //   return "line-unknown-document";
    }
    return null;
  }


  const getNameAppeal = (tag) => {
    switch (tag) {
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "Включение в план закупки / план график";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "Согласование договора / дополнительного соглашения";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "Согласование независимых гарантий";

      case "DOCUMENTS_FOR_PURCHASE":
        return "Документы для закупки";

      case "REGISTRATION_AGREEMENT":
        return "Договор на регистрацию";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "Дополнительное соглашение на регистрацию";

      case "REGISTRATION_FORMALIZATION":
        return "Исполнение на регистрацию";

      case "CLOSURE_AGREEMENT":
        return "Закрытие договора";

      case "ONE_MORE_QUESTION":
        return "Иной вопрос";
    }
    return null;
  }

  function getAllAppealsCount(counts) {
    let count = 0
    if (!!counts && !!counts.types) {
      counts.types.map((t, index) => {
        count += t.count
      })
    }

    return count
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="AppealsMenu">
        <div className="Menu__header">
          <div className="Header__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 15,
                fontWeight: 700,
              }}
            >

              {!!props.selectTab && props.selectTab === 'all' && (
                <div>
                  По всем
                </div>
              )}

              {!!props.selectTab && props.selectTab === 'my' && (
                <div>
                  Я исполнитель
                </div>
              )}

              {!!props.selectTab && props.selectTab === 'my-company' && (
                <div>
                  Моя компания
                </div>
              )}

              {!!props.selectTab && props.selectTab === 'company' && (
                <div>
                  Компания
                </div>
              )}

              {!!props.selectTab && props.selectTab === 'employee' && (
                <div>
                  Сотрудник
                </div>
              )}

              {!!props.selectTab && props.selectTab === 'employee-company' && (
                <div>
                  Компания у сотрудника
                </div>
              )}

              {!props.selectCompany && !!props.selectEmployee && (
                <div style={{
                  fontSize: 16,
                  fontWeight: 400
                }}>
                  {getAccountFio(props.selectEmployee)}
                </div>
              )}

              {!!props.selectCompany && (
                <div style={{
                  fontSize: 16,
                  fontWeight: 400
                }}>
                  {props.selectCompany.short_name}
                </div>
              )}

            </div>
          </div>
        </div>

        <ul className="Menu__list">
          <li className="List__link">
            <div
              className={styleCard._block + " " + styleCard._block_hover + " " + (type === "null" && styleCard._block_focus)}
              onClick={() => changeSelectAppeal(null)}
              style={{
                position: "relative",
                backgroundColor: type === "null" ? "#ffffff" : "#dddddd",
                border: type === "null" ? "2px solid #0061ff" : "2px solid #dddddd00",
              }}
            >
              <div
                className={styleCard._block__number}
                style={{
                  fontSize: 17,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-start"
                }}
              >
                {/*<Icon size={28} name={getIconNameAppeal(t.tag)}/>*/}
                <span style={{marginLeft: 4}}>{(!!object && getAllAppealsCount(object)) || "-"}</span>

              </div>
              <div className={styleCard._block__title} style={{fontSize: 12}}>
                Все обращения от клиентов компаний
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  top: -8,
                  left: 0,
                  justifyContent: " flex-end",
                  width: "100%",
                  padding: "0 8px",
                }}
              >
                {/*{t.is_new && (*/}
                {/*  <div*/}
                {/*    style={{*/}
                {/*      fontSize: 11,*/}
                {/*      fontWeight: 700,*/}
                {/*      backgroundColor: "#509d1d",*/}
                {/*      display: "inline-block",*/}
                {/*      color: "white",*/}
                {/*      padding: "2px 4px 2px",*/}
                {/*      borderRadius: 3,*/}
                {/*      margin: "0 2px",*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Новые*/}
                {/*  </div>*/}
                {/*)}*/}

                {/*{!t.is_new && t.is_work && (*/}
                {/*  <div*/}
                {/*    style={{*/}
                {/*      fontSize: 11,*/}
                {/*      fontWeight: 700,*/}
                {/*      backgroundColor: "#0061ff",*/}
                {/*      display: "inline-block",*/}
                {/*      color: "white",*/}
                {/*      padding: "2px 4px 2px",*/}
                {/*      borderRadius: 3,*/}
                {/*      margin: "0 2px",*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    В работе*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            </div>
          </li>
        </ul>


        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap"
        }}>

          {!!object && !!object.types && object.types.map((t, index) => (
            <div
              className={styleCard._block + " " + styleCard._block_hover + " " + (type === t.tag && styleCard._block_focus)}
              key={'type_' + t.tag}
              onClick={() => changeSelectAppeal(t)}
              style={{
                position: "relative",
                backgroundColor: type === t.tag ? "#ffffff" : t.is_new ? "#ffc30050" : t.is_work ? "#0061ff25" : t.is_return ? "#ff000050" : "#dddddd",
                border: type === t.tag ? "2px solid #0061ff" : "2px solid #dddddd00",
              }}
            >
              <div
                className={styleCard._block__number}
                style={{
                  fontSize: 17,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-start"
                }}
              >
                <Icon size={28} name={getIconNameAppeal(t.tag)}/>
                <span style={{marginLeft: 4}}>{t.count || "-"}</span>

              </div>
              <div className={styleCard._block__title} style={{fontSize: 12}}>
                {getNameAppeal(t.tag)}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  top: -8,
                  left: 0,
                  justifyContent: " flex-end",
                  width: "100%",
                  padding: "0 8px",
                }}
              >
                {t.is_new && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#509d1d",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 2px",
                    }}
                  >
                    Новые
                  </div>
                )}

                {t.is_work && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#0061ff",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 2px",
                    }}
                  >
                    В работе
                  </div>
                )}

                {t.is_return && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#f44336",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 2px",
                    }}
                  >
                    !
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ScrollBlock>
  );
};
