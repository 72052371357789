import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import moment from "moment";
import {
  getAccountFio,
  getErrorMessageByKey,
  Input,
  Modal,
  Select,
  Textarea,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

function ModalAppealEditExecutor(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/appeal/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    list: [employees],
  } = useList({apiUrl: "/api/employees/list", rootUrl: ROOT_URL});

  const inputExecutorId = useRef(null);
  const inputDate = useRef(null);
  const inputNote = useRef(null);

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const executorId = inputExecutorId.current.value;
    const date = inputDate.current.value;
    const note = inputNote.current.value;

    const jsonData = JSON.stringify({
      executor_id: executorId.length > 0 ? (executorId === "null" ? null : Number(executorId)) : null,
      date_control: !!date && date.length > 0 ? date : null,
      work_note: note
    });

    axios
      .put("/api/appeal/" + props.object.uuid + "/edit/executor", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectExecutorOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбран",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!object &&
    !!employees && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            Обращение
            {/*<div className="Text__signature">Ответственный</div>*/}
            <div className="Text__signature">Передать в работу</div>
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputExecutorId}
              options={employees && getSelectExecutorOptions(employees)}
              value={!!object.executor_id ? object.executor_id : null}
              label="Исполнитель"
              // label="Ответственный"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputDate}
              type="date"
              isFixed={true}
              label="Срок исполнения до"
              value={
                !!object.date_control
                  ? moment(object.date_control).format("YYYY-MM-DD")
                  : null
              }
            />
          </div>

          <div className="Form__field Form__field_last">
            <Textarea
              reference={inputNote}
              isFixed={true}
              label="Примечание"
              value={
                !!object.work_note
                  ? object.work_note
                  : null
              }
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Назначить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalAppealEditExecutor;
