import React, {useRef, useState} from "react";
import axios from "axios";
import {
  formatBytes,
  getErrorMessageByKey,
  Icon,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Select,
  Textarea,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import {useViewer} from "../../../components/Viewer";
import moment from "moment/moment";
import ModalAppealFileEdit from "./ModalAppealFileEdit";
import ModalAppealDelete from "./ModalAppealDelete";
import ModalContractItemSelect from "./ModalContractItemSelect";

function ModalAppealDraft(props) {
  const [selectItem, setSelectItem] = useState(null);
  const [isModalSelectItem, setIsModalSelectItem] = useState(false);

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl: "/api/my/appeal/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (response) => {
      setSelectItem(response.contract)
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputTicketFile = useRef(null);
  const [files, setFiles] = useState(null);
  const fileSend = (event) => {
    const files = event.target.files;
    setFiles(Array.from(files));
  };

  const inputSourceFunding = useRef(null);
  const inputRequirement = useRef(null);
  const inputAmount = useRef(null);
  const inputPurchaseMethod = useRef(null);
  const inputDatePlacement = useRef(null);
  const inputDateAgreement = useRef(null);
  const inputContractRequisite = useRef(null);
  const inputQuestion = useRef(null);
  const inputNote = useRef(null);
  const inputContractNumber = useRef(null);
  const inputDocumentType = useRef(null);
  const inputDocumentDate = useRef(null);
  const selectProjectUuid = useRef(null);

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const {
    list: [projects],
  } = useList(
    {
      apiUrl: "/api/client/projects/list",
      rootUrl: ROOT_URL,
      callbackError: () => {
        props.handleClose();
      },
    });

  const {notification} = useNotification();

  const changeAdd = (isDraft) => {
    let formData = new FormData()

    const projectUuid = selectProjectUuid.current.value;

    // Обязательно указать проект.
    if (projectUuid.length > 0 && projectUuid !== "null") {
      formData.append("project_uuid", projectUuid);
    } else {
      notification("Укажите проект", "red");
      return;
    }

    let sourceFunding = null; // (text)
    let requirement = null; // (text)
    let amount = null; // (number)
    let purchaseMethod = null; // (select)
    let datePlacement = null; // (date)
    let dateAgreement = null; // (date)
    let contractRequisite = null; // (text)
    let question = null; // (text)
    let note = null; // (text)
    let contractNumber = null; // (text)
    let documentType = null; // (select)
    let documentDate = null; // (date)

    if (object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE") {
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      requirement = jsonFormatText(inputRequirement.current.value); // * (text)
      amount = inputAmount.current.value; // * (number)
      purchaseMethod = inputPurchaseMethod.current.value; // * (select)
      datePlacement = inputDatePlacement.current.value; // * (date)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (isValidateNullText(sourceFunding) || isValidateNullText(requirement)
        || isValidateNullText(amount)
        || isValidateNullText(purchaseMethod) || purchaseMethod === "null"
        || isValidateNullText(datePlacement)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("source_funding", sourceFunding);
      formData.append("requirement", requirement);
      formData.append("amount", numberConvert(amount));
      formData.append("purchase_method",
        purchaseMethod.length > 0
          ? purchaseMethod === "null"
            ? null
            : purchaseMethod
          : null);
      formData.append("date_placement", !!datePlacement && datePlacement.length > 0 ? datePlacement : null);
      formData.append("note", (!!note && note) || "");
    }

    if (object.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT") {
      contractRequisite = jsonFormatText(inputContractRequisite.current.value); // * (text)
      question = jsonFormatText(inputQuestion.current.value); // * (text)

      if (isValidateNullText(contractRequisite) || isValidateNullText(question)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("contract_requisite", contractRequisite);
      formData.append("question", question);
    }

    if (object.appeal_type.tag === "COORDINATION_OF_INDEPENDENT_GUARANTEES") {
      note = jsonFormatText(inputNote.current.value); // * (text)

      if (isValidateNullText(note)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("note", (!!note && note) || "");
    }

    if (object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE") {
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      purchaseMethod = inputPurchaseMethod.current.value; // * (select)
      datePlacement = inputDatePlacement.current.value; // * (date)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (isValidateNullText(sourceFunding)
        || isValidateNullText(purchaseMethod) || purchaseMethod === "null"
        || isValidateNullText(datePlacement)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("source_funding", sourceFunding);
      formData.append("purchase_method",
        purchaseMethod.length > 0
          ? purchaseMethod === "null"
            ? null
            : purchaseMethod
          : null);
      formData.append("date_placement", !!datePlacement && datePlacement.length > 0 ? datePlacement : null);
      formData.append("note", (!!note && note) || "");
    }

    if (object.appeal_type.tag === "REGISTRATION_AGREEMENT") {
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      requirement = jsonFormatText(inputRequirement.current.value); // * (text)
      amount = inputAmount.current.value; // * (number)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (isValidateNullText(sourceFunding) || isValidateNullText(requirement) || isValidateNullText(amount)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("source_funding", sourceFunding);
      formData.append("requirement", requirement);
      formData.append("amount", numberConvert(amount));
      formData.append("note", (!!note && note) || "");
    }

    if (object.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION") {
      dateAgreement = inputDateAgreement.current.value; // * (date)
      note = jsonFormatText(inputNote.current.value); // * (text)

      if (isValidateNullText(dateAgreement) || isValidateNullText(note)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("date_agreement", !!dateAgreement && dateAgreement.length > 0 ? dateAgreement : null);
      formData.append("note", (!!note && note) || "");
    }

    if (object.appeal_type.tag === "REGISTRATION_FORMALIZATION") {
      // contractNumber = jsonFormatText(inputContractNumber.current.value); // * (text)
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      documentType = inputDocumentType.current.value; // * (select)
      documentDate = inputDocumentDate.current.value; // * (date)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (
        // isValidateNullText(contractNumber) ||
        isValidateNullText(sourceFunding) ||
        isValidateNullText(documentType) ||
        isValidateNullText(documentDate)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      if (selectItem === null) {
        notification("Укажите номер договора", "red");
        return;
      } else {
        formData.append("contract_uuid", selectItem.uuid);
      }

      // formData.append("contract_number", contractNumber);
      formData.append("source_funding", sourceFunding);
      formData.append("document_type",
        documentType.length > 0
          ? documentType === "null"
            ? null
            : documentType
          : null);
      formData.append("document_date", !!documentDate && documentDate.length > 0 ? documentDate : null);
      formData.append("note", (!!note && note) || "");
    }

    if (object.appeal_type.tag === "CLOSURE_AGREEMENT") {
      sourceFunding = jsonFormatText(inputSourceFunding.current.value); // * (text)
      amount = inputAmount.current.value; // * (number)
      note = jsonFormatText(inputNote.current.value); // ? (text)

      if (isValidateNullText(sourceFunding)
        || isValidateNullText(amount)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      if (selectItem === null) {
        notification("Укажите номер договора", "red");
        return;
      } else {
        formData.append("contract_uuid", selectItem.uuid);
      }

      formData.append("source_funding", sourceFunding);
      formData.append("amount", numberConvert(amount));
      formData.append("note", (!!note && note) || "");
    }

    if (object.appeal_type.tag === "ONE_MORE_QUESTION") {
      question = jsonFormatText(inputQuestion.current.value); // * (text)

      if (isValidateNullText(question)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      formData.append("question", question);
    }

    // if (object.appeal_type.tag !== "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE") {
    // if (!files || (!!files && files.length === 0)) {
    //   notification("Прикрепите файл", "red");
    //   return;
    // }

    if (!!files && files.length > 0) {

      if (files.filter(f => f.size > 262144000).length > 0) {
        notification("Один из файлов превышает допустимый размер в 25Мб", "red");
        return;
      }

      if (files.reduce(function (previousValue, currentValue) {
        return {
          size: previousValue.size + currentValue.size
        }
      }).size > (262144000 * 10)) {
        notification("Общий размер файлов превышает 250Мб", "red");
        return;
      }

      if (files.length > 10) {
        notification("Можно прикрепить не больше 10 файлов за раз", "red");
        return;
      }

      files.forEach(f => {
        formData.append("files", f);
      })
    }
    // }

    axios
      .post("/api/my/appeal/" + object.uuid + "/save" + (isDraft ? "?draft=true" : ""), formData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification(isDraft ? "Обращение сохранено как черновик" : "Обращение подано", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getNameAppeal = (tag) => {
    switch (tag) {
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "Включение в план закупки / план график";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "Согласование договора / дополнительного соглашения";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "Согласование независимых гарантий";

      case "DOCUMENTS_FOR_PURCHASE":
        return "Документы для закупки";

      case "REGISTRATION_AGREEMENT":
        return "Договор на регистрацию";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "Дополнительное соглашение на регистрацию";

      case "REGISTRATION_FORMALIZATION":
        return "Исполнение на регистрацию";

      case "CLOSURE_AGREEMENT":
        return "Закрытие договора";

      case "ONE_MORE_QUESTION":
        return "Иной вопрос";
    }
    return null;
  }

  const getIconNameAppeal = (tag) => {
    switch (tag) {
      // case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
      //   return "line-event";
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "line-free-cancellation";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "line-task";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "line-task-alt";

      case "DOCUMENTS_FOR_PURCHASE":
        return "line-receipt-long";

      case "REGISTRATION_AGREEMENT":
        return "line-lab-profile";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "line-note-stack-add";

      case "REGISTRATION_FORMALIZATION":
        return "line-contract";

      case "CLOSURE_AGREEMENT":
        return "line-contract-delete";

      case "ONE_MORE_QUESTION":
        return "line-help";
      // case "ONE_MORE_QUESTION":
      //   return "line-unknown-document";
    }
    return null;
  }

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }

  const [modalItemFileEdit, setModalItemFileEdit] = useState(null);
  const [modalItemDelete, setModalItemDelete] = useState(null);

  const changeDownload = (item) => {
    axios
      .get("/api/appeal-file/" + item.uuid + "/download", {
        responseType: "arraybuffer",
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Accept: item.mime_type,
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          item.name + item.expansion
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const {viewer} = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  const getSelectOptions = (list) => {
    const options = [
      ...(list.length === 0 ? [
        {
          value: "null",
          title: "Не указан",
        },
      ] : [])
    ];

    list.map((item) => {
      let title = !!item.project_year ? item.project_year + " " : ""
      title = title + item.name
      title = title + (!!item.law ? " (" + item.law.name + ")" : "")

      const option = {
        value: item.uuid,
        title: title,
      };

      options.push(option);
    });

    return options;
  };

  const changeSelectProject = () => {
    setSelectItem(null);
  }

  return (
    !!object &&
    <>
      <Modal handleClose={() => null} contentStyle={{
        width: 520
      }}>
        <div className="Form">
          <div className="Form__title" style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: 600
          }}>
            <div style={{
              marginRight: 12
            }}>
              <Icon size={56} name={getIconNameAppeal(object.appeal_type.tag)}/>
            </div>
            <div>
              {(!!getNameAppeal(object.appeal_type.tag) && getNameAppeal(object.appeal_type.tag)) || "Новое"}
              <div className="Text__signature">Обращение</div>
            </div>
          </div>

          <div className="Form__field">
            <div
              style={{
                fontSize: 15,
                fontWeight: 400,
                backgroundColor: "#20202010",
                borderRadius: 5,
                padding: "10px 12px",
              }}
            >
              {object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
                <>
                  Направление проекта контракта и источников финансирования для включения
                </>
              )}
              {object.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
                <>
                  Направление проекта договора, проекта дополнительного соглашения для правовой проверки юристом
                </>
              )}
              {object.appeal_type.tag === "COORDINATION_OF_INDEPENDENT_GUARANTEES" && (
                <>
                  Направление проекта независимой гарантии для правовой проверки юристом
                </>
              )}
              {object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" && (
                <>
                  Направление потребности и документов для проведения закупочной процедуры.<br/>
                  Необходимые документы:<br/>
                  -техническое задание;<br/>
                  -расчет начально-максимальной стоимости договора;<br/>
                  -коммерческие предложения;<br/>
                  -источники финансирования;<br/>
                  -проект договора.<br/>
                </>
              )}
              {object.appeal_type.tag === "REGISTRATION_AGREEMENT" && (
                <>
                  Направление подписанного документа для регистрации с приложенными источниками финансирования
                </>
              )}
              {object.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
                <>
                  Направление подписанного документа для регистрации
                </>
              )}
              {object.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
                <>
                  Направление актов выполненных работ / товарных накладных / УПД / платежных поручений на размещение
                </>
              )}
              {object.appeal_type.tag === "CLOSURE_AGREEMENT" && (
                <>
                  Направление документов на закрытие договора
                </>
              )}
              {object.appeal_type.tag === "ONE_MORE_QUESTION" && (
                <>
                  Вопросы иного характера
                </>
              )}
            </div>
          </div>

          <div className="Form__field">
            <Select
              reference={selectProjectUuid}
              isFixed={true}
              options={!!projects ? getSelectOptions(projects) : getSelectOptions([])}
              label="Проект"
              onChange={() => changeSelectProject()}
            />
          </div>

          {(object.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <>
              {/*<div className="Form__field">*/}
              {/*  <Input*/}
              {/*    reference={inputContractNumber}*/}
              {/*    label="Номер договора *"*/}
              {/*    value={object.contract_number}*/}
              {/*  />*/}
              {/*</div>*/}

              {!selectItem && (
                <div className="Form__field"
                     style={{
                       cursor: "pointer"
                     }}
                     onClick={!!selectProjectUuid ? () => setIsModalSelectItem(true) : () => null}>
                  <div className="Field__label">
                    Договор *
                  </div>
                  <div className="Field__text">
                    -
                  </div>
                </div>
              )}

              {!!selectItem && (
                <div className="Form__field"
                     style={{
                       cursor: "pointer"
                     }}
                     onClick={!!selectProjectUuid ? () => setIsModalSelectItem(true) : () => null}>
                  <div className="Field__label">
                    Договор *
                  </div>
                  <div className="Field__text">
                    <div>
                      <div>
                        {!!selectItem.project && (
                          <div>
                            {!!selectItem.project.name && (
                              <span
                                style={{
                                  marginRight: 4,
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                    {!!selectItem.project.project_year && selectItem.project.project_year}{" "}{selectItem.project.name}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      <div>
                        {!!selectItem.registry_number && (
                          <span style={{
                            marginRight: 6
                          }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                            {selectItem.registry_number + " "}
                              </span>
                        )}
                        {!!selectItem.contract_number && selectItem.contract_number}
                      </div>
                    </div>
                    {!!selectItem.supplier && (
                      <div>
                        <span style={{
                          fontSize: 14
                        }}>
                          {selectItem.supplier}
                        </span>
                      </div>
                    )}
                    {!!selectItem.subject && (
                      <div>
                        <span style={{
                          fontSize: 14
                        }}>
                          {selectItem.subject}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
            object.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <div className="Form__field">
              <Input
                reference={inputSourceFunding}
                label="Источник финансирования (КВФО, КЦСР, КВР, КОСГУ) *"
                value={object.source_funding}
              />
            </div>
          )}


          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT") && (
            <div className="Form__field">
              <Textarea
                reference={inputRequirement}
                label="Потребность / договор *"
                value={object.requirement}
              />
            </div>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <div className="Form__field">
              <Input
                reference={inputAmount}
                type="number"
                label="Сумма *"
                value={object.amount / 100}
              />
            </div>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE") && (
            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputPurchaseMethod}
                options={[
                  {
                    value: "null",
                    title: "Не выбран",
                  },
                  {
                    value: "EP",
                    title: "ЕП. "
                  },
                  {
                    value: "KP",
                    title: "КП. "
                  }
                ]}
                label="Способ закупки *"
                value={object.purchase_method ? object.purchase_method : "null"}
              />
            </div>
          )}

          {object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
            <div className="Form__field">
              <Input
                reference={inputDatePlacement}
                type="date"
                isFixed={true}
                label="Предполагаемый срок размещения закупки *"
                value={
                  !!object.date_placement
                    ? moment(object.date_placement).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>
          )}

          {object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" && (
            <div className="Form__field">
              <Input
                reference={inputDatePlacement}
                type="date"
                isFixed={true}
                label="Планируемый срок размещения закупки *"
                value={
                  !!object.date_placement
                    ? moment(object.date_placement).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>
          )}

          {object.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
            <div className="Form__field">
              <Input
                reference={inputContractRequisite}
                label="Реквизит договора / ДС *"
                value={object.contract_requisite}
              />
              <div className={"Field__label"}>* при направлении ДС, указывать номер договора к которому прилагается ДС
              </div>
            </div>
          )}

          {(object.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" ||
            object.appeal_type.tag === "ONE_MORE_QUESTION") && (
            <div className="Form__field">
              <Textarea
                reference={inputQuestion}
                label="Вопрос *"
                value={object.question}
              />
            </div>
          )}

          {object.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
            <div className="Form__field">
              <Input
                reference={inputDateAgreement}
                type="date"
                isFixed={true}
                label="Дата ДС *"
                value={
                  !!object.date_agreement
                    ? moment(object.date_agreement).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>
          )}

          {object.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputDocumentType}
                options={[
                  {
                    value: "null",
                    title: "Не выбран",
                  },
                  {
                    value: "ACT",
                    title: "Акт"
                  },
                  {
                    value: "PAYMENT",
                    title: "Платёж"
                  }
                ]}
                label="Документ (акт / платёж) *"
                value={object.document_type ? object.document_type : "null"}
              />
            </div>
          )}

          {object.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
            <div className="Form__field">
              <Input
                reference={inputDocumentDate}
                type="date"
                isFixed={true}
                label="Дата документа *"
                value={
                  !!object.document_date
                    ? moment(object.document_date).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
            object.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <div className="Form__field">
              <Textarea
                reference={inputNote}
                label="Примечание"
                value={object.note}
              />
            </div>
          )}

          {(object.appeal_type.tag === "COORDINATION_OF_INDEPENDENT_GUARANTEES" ||
            object.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION") && (
            <div className="Form__field">
              <Textarea
                reference={inputNote}
                label="Примечание *"
                value={object.note}
              />
            </div>
          )}


          {/*{object.appeal_type.tag !== "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (*/}
          <>

            {!!object.files && (
              <div className="Form__field">
                <div className={"Field__label"}>Документы</div>

                <div className={"Field__text"}>
                  <div
                    className={
                      styleContent._block + " " + styleContent._block_bottom
                    }
                  >
                    <div className={styleContent._block__content}>
                      <div className={styleContent._block}>
                        <div className={styleContent._block__content} style={{
                          flexDirection: "column"
                        }}>
                          {object.files.map((file) => (
                            <div
                              className={styleCard._block}
                              key={object.uuid + ":" + file.uuid}
                              // onClick={(event) => (event.preventDefault(), setModalItemFileEdit(file))}
                              // onContextMenu={(!!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png"))
                              //   ? (event) => (event.preventDefault(), changeViewer(file.data, file.mime_type))
                              //   : (event) => (event.preventDefault(), changeDownload(file))
                              // }
                              onClick={(event) => (event.preventDefault(), setModalItemFileEdit(file))}
                              style={{
                                minWidth: "auto",
                                display: "flex",
                                alignItems: "center",
                                margin: "0 0 8px 0",
                                backgroundColor: "#f1f1f1",
                                cursor: "pointer",
                                position: "relative"
                              }}>
                              {!!file.expansion && (
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                    textTransform: "uppercase",
                                    fontSize: 10,
                                    fontWeight: 900,
                                    borderRadius: 3,
                                    color: "white",
                                    backgroundColor: getColorFile(file.expansion.slice(1)),
                                    marginRight: 8,
                                    padding: "2px 4px"
                                  }}>
                                    {file.expansion.slice(1)}
                                  </span>
                              )}
                              <span className={styleCard._block__title} style={{
                                fontSize: 15
                              }}>
                                  {file.name}
                                </span>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "absolute",
                                  top: -8,
                                  left: 0,
                                  justifyContent: " flex-end",
                                  width: "100%",
                                  padding: "0 8px",
                                }}
                              >
                                {!!file.created_at && (
                                  <div
                                    style={{
                                      fontSize: 11,
                                      fontWeight: 700,
                                      backgroundColor: "#202020",
                                      display: "inline-block",
                                      color: "white",
                                      padding: "2px 4px 2px",
                                      borderRadius: 3,
                                      margin: "0 2px",
                                    }}
                                  >
                                    Прикреплено {moment(file.created_at.substring(0, 19)).format(
                                    "DD.MM.YYYY"
                                  )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="Form__field">
              <Input
                reference={inputTicketFile}
                type="file"
                label="Файлы"
                isFixed={true}
                onChange={(event) => fileSend(event)}
                isMultiple={true}
              />
            </div>
            <div className="Form__field Form__field_last">
              {!!files && files.map((file, index) => (
                <div
                  key={'file_' + index}
                  style={{
                    minWidth: "auto",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#f1f1f1",
                    padding: "6px 10px 6px 6px",
                    borderRadius: 5,
                    fontSize: 15,
                    marginBottom: 4,
                    marginTop: 4
                  }}>
                  {!!file.name && (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontSize: 10,
                        fontWeight: 900,
                        borderRadius: 4,
                        color: "white",
                        backgroundColor: getColorFile(file.name.split(".").pop()),
                        marginRight: 8,
                        padding: "10px 4px",
                        minWidth: 42
                      }}>
                                    {file.name.split(".").pop()}
                                  </span>
                  )}
                  <div>
                    <div style={{
                      fontSize: 15
                    }}>
                      {file.name.slice(0, file.name.lastIndexOf("."))}
                    </div>

                    <div style={{
                      fontSize: 13
                    }}>
                      {formatBytes(file.size)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
          {/*)}*/}

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue" onClick={() => changeAdd(false)}>
              Подать
            </button>
          </div>

          {object.appeal_status.tag === "DRAFT" && (
            <div className="Form__actions Form__actions_center" onClick={() => changeAdd(true)}>
              <button className="Action__button Action__button_all Action__button_gray">
                Сохранить как черновик
              </button>
            </div>
          )}

          <div className="Form__actions">
            <div className="Action__link Action__link_red" onClick={() => setModalItemDelete(object)}>
              Удалить обращение
            </div>

            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>


          </div>
        </div>
      </Modal>

      {isModalSelectItem && (
        <ModalContractItemSelect
          item={selectItem}
          successfulRequest={item =>
            setSelectItem(item)
          }
          selectProjectUuid={selectProjectUuid.current.value}
          handleClose={() => setIsModalSelectItem(false)}
        />
      )}

      {!!modalItemFileEdit && (
        <ModalAppealFileEdit
          file={modalItemFileEdit}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalItemFileEdit(null)}
        />
      )}

      {!!modalItemDelete && (
        <ModalAppealDelete
          object={modalItemDelete}
          successfulRequest={() => props.handleClose()}
          handleClose={() => setModalItemDelete(null)}
        />
      )}
    </>
  );
}

export default ModalAppealDraft;
