import {useEffect, useRef, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import moment from "moment";
import {useList, useLocalStorage,} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

function useErrorExecutionContracts() {
  const {
    list: [listProjects],
  } = useList({
    apiUrl: "/api/projects/list",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const [filterTasks, setFilterTasks] = useLocalStorage("filterErrorExecutionContracts", {
    filterProjects: [],
    filterEmployees: [],
  });

  const [filterProjects, setFilterProjects] = useState(
    filterTasks.filterProjects || []
  );
  const [filterEmployees, setFilterEmployees] = useState(
    filterTasks.filterEmployees || []
  );

  useEffect(() => {
    setFilterTasks({
      filterProjects,
      filterEmployees,
    });
  }, [
    filterProjects,
    filterEmployees,
  ]);

  const getUrlParams = () => {
    let params = {
      view: "current",
      "registration-error": true,
      employees:
        (!!filterEmployees &&
          filterEmployees.length > 0 &&
          filterEmployees.join(",")) ||
        "",
      projects:
        (!!filterProjects &&
          filterProjects.length > 0 &&
          filterProjects.join(",")) ||
        "",
    };

    return params;
  };

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    params: [_paramsList, setParamsList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/contracts",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
    isPagination: true,
    paginationLimit: 250,
    isSearch: true,
  });

  useEffect(() => {
    setParamsList({...getUrlParams()});
  }, [
    filterProjects,
    filterEmployees,
  ]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  // Отключил, если делать то точечно.
  // useAction(["tasks", "project-tasks", "general-tasks"], () => {
  //   updateList();
  //   updateDayTasks();
  //   updateDayProjectTasks();
  //   updateOverdueTasks();
  //   updateOverdueProjectTasks();
  //   updateDayGeneralTasks();
  //   updateOverdueGeneralTasks();
  // });

  //--------------------------------------------------------------------------------------------------------------------

  const getObjectByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.uuid === filter) {
          object = o;
        }
      });
    }
    return object;
  };

  const deleteFilter = (filters, filter, setFilter) => {
    let index = null;
    if (!!filters) {
      filters.forEach((sf, i) => {
        if (sf === String(filter)) {
          index = i;
        }
      });
    }
    if (!!index || String(index) === "0") {
      filters.splice(index, 1);
      setFilter([...filters]);
    }
  };

  const getProjectByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.id === Number(filter)) {
          object = o;
        }
      });
    }
    return object;
  };

  const getEmployeeByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.account.id === Number(filter)) {
          object = o;
        }
      });
    }
    return object;
  };


  return {
    list,
    setList,
    count,
    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    listEmployees,
    listProjects,

    getObjectByFilter,
    deleteFilter,
    getProjectByFilter,
    filterProjects,
    setFilterProjects,
    getEmployeeByFilter,
    filterEmployees,
    setFilterEmployees,
  };
}

export default useErrorExecutionContracts;
