import React, {useEffect, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {ButtonIcon, getAccountFio, Modal, useList} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";
import {Avatar} from "../../../components/Avatar/Avatar";

function ModalTasksFilterNew(props) {
  const [tumbler, setTumbler] = useState("employees");

  const [selectList, setSelectList] = useState([]);

  const {
    list: [listProjects],
  } = useList({
    apiUrl: "/api/projects/list",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  useEffect(() => {
    const {filterEmployees, filterProjects} = props.useSearchFilter;

    if (!!tumbler && tumbler === "employees") {
      setSelectList([...(!!filterEmployees ? filterEmployees : [])]);
    }

    if (!!tumbler && tumbler === "projects") {
      setSelectList([...(!!filterProjects ? filterProjects : [])]);
    }

  }, [listProjects, listEmployees, tumbler]);

  const changeAddEmployee = () => {
    const {setFilterEmployees} = props.useSearchFilter;

    setFilterEmployees([...(!!selectList ? selectList : [])]);

    props.handleClose();
  };

  const changeAddProject = () => {
    const {setFilterProjects} = props.useSearchFilter;

    setFilterProjects([...(!!selectList ? selectList : [])]);

    props.handleClose();
  };

  const isSelectListValue = (selectList, value) => {
    let isSelect = false;
    if (!!selectList) {
      selectList.forEach((sf, i) => {
        if (sf === String(value)) {
          isSelect = true;
        }
      });
    }
    return isSelect
  };

  const addSelectListValue = (selectList, value) => {
    setSelectList([...((!!selectList && selectList) || []), String(value)])
  };

  const deleteSelectListValue = (selectList, value) => {
    let index = null;
    if (!!selectList) {
      selectList.forEach((sf, i) => {
        if (sf === String(value)) {
          index = i;
        }
      });
    }
    if (!!index || String(index) === "0") {
      selectList.splice(index, 1);
      setSelectList([...selectList]);
    }
  };

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    <Modal handleClose={() => props.handleClose()} contentStyle={{
      width: 360
    }}>
      <div className="Form">
        <div className="Form__title">
          Фильтры поиска
          <div className="Text__signature">По ошибкам регистрации</div>
        </div>

        <div className="Form__field">
          <ul
            className={styleTumbler._}
            style={{
              justifyContent: "space-between",
              backgroundColor: "#f5f5f5",
            }}
          >
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "employees" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("employees")}
                style={{
                  cursor: "pointer",
                }}
              >
                Сотрудник
              </a>
            </li>
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "projects" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("projects")}
                style={{
                  cursor: "pointer",
                }}
              >
                Проект
              </a>
            </li>
          </ul>
        </div>

        {!!tumbler && tumbler === "employees" && (
          <>
            <div className="Form__field Form__field_last">
              <div className="Field__text" style={{
                display: "flex"
              }}>
                Сотрудник
                <span style={{
                  marginLeft: 8
                }}>
                <div
                  style={{
                    backgroundColor: selectList.length > 0 ? "#0061ff" : "#919191",
                    padding: "3px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 24,
                    margin: "0 2px",
                  }}
                >
                  <div
                    style={{
                      padding: "0 4px",
                      color: "white",
                      fontSize: 12,
                      pointerEvents: "auto",
                      fontWeight: 400,
                      cursor: "default"
                    }}
                  >
                    {selectList.length}
                  </div>
                </div>
                </span>
              </div>
            </div>

            <div className="Form__actions Form__actions_center" style={{
              justifyContent: "space-around",
              display: "flex",
              alignItems: "center",
              marginBottom: 16
            }}>
              <div className="Action__link" style={{
                display: "flex",
                alignItems: "center",
                fontSize: 12,
                fontWeight: 400,
                padding: "3px 3px 3px 9px",
                backgroundColor: "#0061fc15",
                margin: "0 2px",
                borderRadius: 24,
                position: "relative",
                marginBottom: 4,
                cursor: "pointer",
                color: "#202020",
                justifyContent: "space-between"
              }}
                   onClick={() =>
                     setSelectList([...((!!listEmployees && listEmployees.map(obj => String(obj.account.id))) || [])])}>
                <span>Выбрать все</span>
                <ButtonIcon
                  tooltipText={"Добавить"}
                  iconName={"custom-add"}
                  iconColor={"#0061fc95"}
                  size={18}
                  style={{marginLeft: 6}}
                />
              </div>

              <div className="Action__link" style={{
                display: "flex",
                alignItems: "center",
                fontSize: 12,
                fontWeight: 400,
                padding: "3px 3px 3px 9px",
                backgroundColor: "#dfdfdf75",
                margin: "0 2px",
                borderRadius: 24,
                position: "relative",
                marginBottom: 4,
                cursor: "pointer",
                color: "#202020",
                justifyContent: "space-between"
              }}
                   onClick={() => setSelectList([])}>
                <span>Очистить выбор</span>
                <ButtonIcon
                  tooltipText={"Убрать"}
                  iconName={"custom-circle-close"}
                  iconColor={"#20202075"}
                  size={18}
                  style={{marginLeft: 6}}
                />
              </div>
            </div>

            <div
              className="Form__field Form__field_last"
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "stretch",
                justifyContent: "flex-start",
              }}
            >
              {!!listEmployees &&
                listEmployees.map((item) => (
                  <div
                    key={"item_" + item.uuid}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "4px 3px 4px 8px",
                      backgroundColor: isSelectListValue(selectList, item.account.id) ? "#dfdfdf75" : "#dfdfdf00",
                      margin: "0 2px",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "flex-start",
                      position: "relative",
                      marginBottom: 4,
                      cursor: "pointer",
                      color: "#202020",
                      justifyContent: "space-between"
                    }}
                    onClick={isSelectListValue(selectList, item.account.id) ? () =>
                        deleteSelectListValue(selectList, item.account.id, setSelectList)
                      : () => addSelectListValue(selectList, item.account.id)
                    }
                  >
                    <span style={{
                      display: "flex",
                      marginRight: 4, fontSize: 15
                    }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            {!!item.account.avatar && isMD5Hash(item.account.avatar) && (
                                              <img height={16} width={16} style={{
                                                borderRadius: 16,
                                              }} src={ROOT_URL + "/api/account/avatar/" + item.account.avatar}/>
                                            )}
                                            {!item.account.avatar && (
                                              <Avatar size={16} value={getAccountFio(item.account)}/>
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(item.account)}
                                        </span>
                                      </span>
                    <ButtonIcon
                      tooltipText={isSelectListValue(selectList, item.account.id) ? "Убрать" : "Добавить"}
                      iconName={isSelectListValue(selectList, item.account.id) ? "custom-circle-close" : "custom-add"}
                      iconColor={isSelectListValue(selectList, item.account.id) ? "#20202075" : "#0061fc95"}
                      size={18}
                      style={{marginLeft: 3}}
                    />
                  </div>
                ))}
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddEmployee()}
              >
                Применить
              </button>
            </div>
          </>
        )}

        {!!tumbler && tumbler === "projects" && (
          <>
            <div className="Form__field Form__field_last">
              <div className="Field__text" style={{
                display: "flex"
              }}>
                Проект
                <span style={{
                  marginLeft: 8
                }}>
                <div
                  style={{
                    backgroundColor: selectList.length > 0 ? "#0061ff" : "#919191",
                    padding: "3px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 24,
                    margin: "0 2px",
                  }}
                >
                  <div
                    style={{
                      padding: "0 4px",
                      color: "white",
                      fontSize: 12,
                      pointerEvents: "auto",
                      fontWeight: 400,
                      cursor: "default"
                    }}
                  >
                    {selectList.length}
                  </div>
                </div>
                </span>
              </div>
            </div>

            <div className="Form__actions Form__actions_center" style={{
              justifyContent: "space-around",
              display: "flex",
              alignItems: "center",
              marginBottom: 16
            }}>
              <div className="Action__link" style={{
                display: "flex",
                alignItems: "center",
                fontSize: 12,
                fontWeight: 400,
                padding: "3px 3px 3px 9px",
                backgroundColor: "#0061fc15",
                margin: "0 2px",
                borderRadius: 24,
                position: "relative",
                marginBottom: 4,
                cursor: "pointer",
                color: "#202020",
                justifyContent: "space-between"
              }}
                   onClick={() =>
                     setSelectList([...((!!listProjects && listProjects.map(obj => String(obj.id))) || [])])}>
                <span>Выбрать все</span>
                <ButtonIcon
                  tooltipText={"Добавить"}
                  iconName={"custom-add"}
                  iconColor={"#0061fc95"}
                  size={18}
                  style={{marginLeft: 6}}
                />
              </div>

              <div className="Action__link" style={{
                display: "flex",
                alignItems: "center",
                fontSize: 12,
                fontWeight: 400,
                padding: "3px 3px 3px 9px",
                backgroundColor: "#dfdfdf75",
                margin: "0 2px",
                borderRadius: 24,
                position: "relative",
                marginBottom: 4,
                cursor: "pointer",
                color: "#202020",
                justifyContent: "space-between"
              }}
                   onClick={() => setSelectList([])}>
                <span>Очистить выбор</span>
                <ButtonIcon
                  tooltipText={"Убрать"}
                  iconName={"custom-circle-close"}
                  iconColor={"#20202075"}
                  size={18}
                  style={{marginLeft: 6}}
                />
              </div>
            </div>

            <div
              className="Form__field Form__field_last"
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "stretch",
                justifyContent: "flex-start",
              }}
            >
              {!!listProjects &&
                listProjects.map((item) => (
                  <div
                    key={"item_" + item.uuid}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "4px 3px 4px 8px",
                      backgroundColor: isSelectListValue(selectList, item.id) ? "#dfdfdf75" : "#dfdfdf00",
                      margin: "0 2px",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "flex-start",
                      position: "relative",
                      marginBottom: 4,
                      cursor: "pointer",
                      color: "#202020",
                      justifyContent: "space-between"
                    }}
                    onClick={isSelectListValue(selectList, item.id) ? () =>
                        deleteSelectListValue(selectList, item.id, setSelectList)
                      : () => addSelectListValue(selectList, item.id)
                    }
                  >
                    <span style={{
                      display: "flex",
                      marginRight: 4, fontSize: 15
                    }}>
                                        <span>
                                          {item.name}
                                        </span>
                                      </span>
                    <ButtonIcon
                      tooltipText={isSelectListValue(selectList, item.id) ? "Убрать" : "Добавить"}
                      iconName={isSelectListValue(selectList, item.id) ? "custom-circle-close" : "custom-add"}
                      iconColor={isSelectListValue(selectList, item.id) ? "#20202075" : "#0061fc95"}
                      size={18}
                      style={{marginLeft: 3}}
                    />
                  </div>
                ))}
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddProject()}
              >
                Применить
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalTasksFilterNew;
