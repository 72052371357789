import React from "react";
import moment from "moment";
import {getAccountFio, Modal,} from "@vokymlak/kabinet-ui";

function ModalControlPointHistories(props) {
  return (
    !!props.list && (
      <Modal handleClose={() => props.handleClose()} contentStyle={{
        width: 720
      }}>
        <div className="Form">
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            Контрольная точка
            <div className="Text__signature">Просмотр истории изменении</div>
          </div>

          <div className="Form__actions Form__actions_center">
            <table
              className="Table"
              style={{
                marginBottom: 12,
              }}
            >
              <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#20202050",
                  }}
                >
                  <div className="Th__title">Редактор</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#20202050",
                  }}
                >
                  <div className="Th__title">Изменение</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Назначен</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата контроля</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Примечание</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Исполнитель</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата исполнения</div>
                </th>
              </tr>
              </thead>
              <tbody>
              {props.list.map((item, index) => (
                <tr
                  className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                  key={"item_" + index}
                >
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: "#20202025",
                      }}>
                    <div className="Td__text" style={{
                      fontWeight: 400,
                      fontSize: 14
                    }}>
                      <div>{!!item.creator && getAccountFio(item.creator)}</div>
                      {!!item.created_at && moment(item.created_at).format(
                        "DD.MM.YYYY HH:mm"
                      )}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: "#20202025",
                      }}>
                  <div className="Td__text Td__text_center">
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {!item.action && ""}
                        {!!item.action && item.action === "CREATE" && "создание"}
                        {!!item.action && item.action === "DELETE" && "удаление"}
                        {!!item.action && item.action === "UPDATE" && "изменение"}
                        {!!item.action && item.action === "UPDATE_DATE_CONTROL" && "смена даты (авто)"}
                        {!!item.action && item.action === "SET_DATE_EXECUTION" && "отметка даты"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {(!!item.whom && getAccountFio(item.whom)) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text">
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {(!!item.date_control && moment(item.date_control).format(
                          "DD.MM.YYYY"
                        )) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {(!!item.note_when_updating && item.note_when_updating) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {(!!item.executor && getAccountFio(item.executor)) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {(!!item.date_execution && moment(item.date_execution).format(
                          "DD.MM.YYYY"
                        )) || "-"}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>

            {props.list.length === 0 && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "32px 0",
                  fontSize: 48,
                  opacity: 0.1,
                }}
              >
                Нет истории
              </div>
            )}
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Закрыть
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}

export default ModalControlPointHistories;
