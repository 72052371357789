import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {isProfileRoleTypeTags, useAction, useObject, useProfile,} from "@vokymlak/kabinet-ui";
import {ROLE_ADMINISTRATOR, ROLE_CLIENT, ROLE_DIRECTOR, ROLE_EMPLOYEE,} from "../../config/constants";
import {ROOT_URL} from "../../config/url";

function MenuRouting(props) {
  const {profile} = useProfile();

  const [isProfile, setIsProfile] = useState(true);

  const getParams = () => {
    let params = null;
    return params;
  };

  const getUrlParams = () => {
    return {
      ...getParams(),
      employee:
        (isProfile &&
          profile.uuid) ||
        "",
    };
  };

  // const {
  //   object: [object, setObject],
  //   update: [isUpdateObject, setIsUpdateObject, updateObject],
  //   params: [_paramsHome, setParamsHome],
  // } = useObject({
  //   apiUrl: "/api/home",
  //   rootUrl: ROOT_URL,
  //   urlParams: getUrlParams(),
  // });

  const {
    object: [appealsStatistic, setAppealsStatistic],
    update: [isUpdateAppealsStatistic, setIsUpdateAppealsStatistic, updateAppealsStatistic],
    params: [_paramsAppealsStatistic, setParamsAppealsStatistic],
  } = useObject({
    apiUrl: "/api/appeals-statistic",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const {
    object: [tasksStatistic, setTasksStatistic],
    update: [isUpdateTasksStatistic, setIsUpdateTasksStatistic, updateTasksStatistic],
    params: [_paramsTasksStatistic, setParamsTasksStatistic],
  } = useObject({
    apiUrl: "/api/tasks-statistic",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  const onChange = () => {
    if (!!props.onChange) {
      props.onChange()
    }
  }

  useEffect(() => {
    setParamsAppealsStatistic({...getUrlParams()});
    setParamsTasksStatistic({...getUrlParams()});
  }, [
    isProfile,
  ]);

  useAction(["project-tasks", "tasks"], () => updateTasksStatistic());
  useAction(["appeals"], () => updateAppealsStatistic());

  return (
    <>
      {isProfileRoleTypeTags(profile, [ROLE_CLIENT]) && (
        <>
          <ul className="Menu__list Menu__list_box">
            <li className="List__link">
              <NavLink className={"Link__text"} style={{fontSize: 14}} to={"/my/projects"} onClick={() => onChange()}>
                Проекты
              </NavLink>
            </li>

            <li className="List__link">
              <NavLink className={"Link__text"} style={{fontSize: 14}} to={"/my/contracts"} onClick={() => onChange()}>
                Документы на размещение исполнения
              </NavLink>
            </li>

            <li className="List__link">
              <NavLink className={"Link__text"} style={{fontSize: 14}} to={"/my/appeals"} onClick={() => onChange()}>
                Обращения
              </NavLink>
            </li>
          </ul>
          <ul className="Menu__list Menu__list_box">
            <li className="List__link">
              <NavLink className={"Link__text"} style={{fontSize: 14}} to={"/handbook"} onClick={() => onChange()}>
                Справочник
              </NavLink>
            </li>
          </ul>
        </>
      )}

      {/*{isProfileRoleTypeTags(profile, [*/}
      {/*  ROLE_ADMINISTRATOR,*/}
      {/*  ROLE_DIRECTOR,*/}
      {/*  ROLE_EMPLOYEE,*/}
      {/*]) && (*/}
      {/*  <ul className="Menu__list Menu__list_box">*/}
      {/*    <li className="List__link">*/}
      {/*      <NavLink className={"Link__text"} to={"/home"} onClick={() => onChange()}>*/}
      {/*        Главная*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*)}*/}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/projects"} onClick={() => onChange()}>
              Проекты
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/companies"} onClick={() => onChange()}>
              Компании
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/fns-certificates"} onClick={() => onChange()}>
              Сертификаты
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tasks"} onClick={() => onChange()}
                     style={{marginRight: 4}}>
              Список задач
            </NavLink>

            {!!tasksStatistic && (
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
                {!!tasksStatistic.count_tasks && (
                  (tasksStatistic.count_tasks.task_person_projects.count_day +
                    tasksStatistic.count_tasks.task_person_control_points.count_day
                  ) > 0 && (
                    <div style={{
                      height: 16,
                      marginRight: 2,
                      padding: "1px 5px 1px",
                      backgroundColor: "#0061ff",
                      color: "white",
                      fontSize: 12,
                      fontWeight: 700,
                      borderRadius: 24,
                    }}>{(tasksStatistic.count_tasks.task_person_projects.count_day +
                      tasksStatistic.count_tasks.task_person_control_points.count_day
                    )}</div>
                  ) || ''
                )}
                {/*{!!object.count_tasks && (*/}
                {/*  (object.count_tasks.task_person_projects.count_overdue +*/}
                {/*    object.count_tasks.task_person_control_points.count_overdue*/}
                {/*  ) > 0 && (*/}
                {/*    <div style={{*/}
                {/*      height: 16,*/}
                {/*      marginRight: 2,*/}
                {/*      padding: "1px 5px 1px",*/}
                {/*      backgroundColor: "#f44336",*/}
                {/*      color: "white",*/}
                {/*      fontSize: 12,*/}
                {/*      fontWeight: 700,*/}
                {/*      borderRadius: 24,*/}
                {/*    }}>{(object.count_tasks.task_person_projects.count_overdue +*/}
                {/*      object.count_tasks.task_person_control_points.count_overdue*/}
                {/*    )}</div>*/}
                {/*  ) || ''*/}
                {/*)}*/}
              </div>
            )}
          </li>

          <li className="List__link">
            <NavLink className={"Link__text"} to={"/execution-tasks"} onClick={() => onChange()}
                     style={{marginRight: 4}}>
              Задачи исполнения
            </NavLink>

            {!!tasksStatistic && (
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
                {!!tasksStatistic.count_tasks && (
                  (tasksStatistic.count_tasks.task_person_executions.count_day) > 0 && (
                    <div style={{
                      height: 16,
                      marginRight: 2,
                      padding: "1px 5px 1px",
                      backgroundColor: "#0061ff",
                      color: "white",
                      fontSize: 12,
                      fontWeight: 700,
                      borderRadius: 24,
                    }}>{(tasksStatistic.count_tasks.task_person_executions.count_day)}</div>
                  ) || ''
                )}
                {/*{!!object.count_tasks && (*/}
                {/*  (object.count_tasks.task_person_executions.count_overdue) > 0 && (*/}
                {/*    <div style={{*/}
                {/*      height: 16,*/}
                {/*      marginRight: 2,*/}
                {/*      padding: "1px 5px 1px",*/}
                {/*      backgroundColor: "#f44336",*/}
                {/*      color: "white",*/}
                {/*      fontSize: 12,*/}
                {/*      fontWeight: 700,*/}
                {/*      borderRadius: 24,*/}
                {/*    }}>{(object.count_tasks.task_person_executions.count_overdue)}</div>*/}
                {/*  ) || ''*/}
                {/*)}*/}
              </div>
            )}
          </li>

          <li className="List__link">
            <NavLink className={"Link__text"} to={"/general-tasks"} onClick={() => onChange()}>
              Общие задачи
            </NavLink>
          </li>
          {/*<li className="List__link">*/}
          {/*  <NavLink className={"Link__text"} to={"/appeals-all"} onClick={() => onChange()}>*/}
          {/*    Все обращения*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/appeals"} onClick={() => onChange()}>
              Обращения
            </NavLink>

            {!!appealsStatistic && (
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
                {!!appealsStatistic.count_appeals && appealsStatistic.count_appeals.statuses.map((s, index) => (
                  s.tag === 'CONSIDERATION' && (
                    s.count && s.count > 0 && (
                      <div
                        key={"appeals_" + "CONSIDERATION_" + index}
                        style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#509d1d",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{s.count}</div>
                    ) || ''
                  )
                ))}
                {!!appealsStatistic.count_appeals && appealsStatistic.count_appeals.statuses.map((s, index) => (
                  s.tag === 'PROCESSING' && (
                    s.count && s.count > 0 && (
                      <div
                        key={"appeals_" + "PROCESSING_" + index}
                        style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#0061ff",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{s.count}</div>
                    ) || ''
                  )
                ))}
                {!!appealsStatistic.count_appeals && appealsStatistic.count_appeals.statuses.map((s, index) => (
                  s.tag === 'RETURNED' && (
                    s.count && s.count > 0 && (
                      <div
                        key={"appeals_" + "RETURNED_" + index}
                        style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#f44336",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{s.count}</div>
                    ) || ''
                  )
                ))}
              </div>
            )}
          </li>

          <li className="List__link">
            <NavLink className={"Link__text"} to={"/error-execution-contracts"} onClick={() => onChange()}>
              Ошибки по регистрации
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
        <li className="List__link">
            <NavLink className={"Link__text"} to={"/calculator"} onClick={() => onChange()}>
              Калькуляторы
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/handbook"} onClick={() => onChange()}>
              Справочник
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/clients"} onClick={() => onChange()}>
              Клиенты
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/employees"} onClick={() => onChange()}>
              Сотрудники
            </NavLink>
          </li>
          {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
            <li className="List__link">
              <NavLink className={"Link__text"} to={"/accounts"} onClick={() => onChange()}>
                Аккаунты
              </NavLink>
            </li>
          )}
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/parser-ris"} onClick={() => onChange()}>
              Парсер РИС
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/settings-view"} onClick={() => onChange()}>
              Просмотр настроек
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tickets"} onClick={() => onChange()}>
              Тикеты
              <div style={{
                fontSize: 12,
                opacity: .75
              }}>
                Задачи по улучшениям и ошибкам
              </div>
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/settings"} onClick={() => onChange()}>
              Настройки
            </NavLink>
          </li>
        </ul>
      )}

      {/*{isProfileRoleTypeTags(profile, [*/}
      {/*  ROLE_ADMINISTRATOR,*/}
      {/*  ROLE_DIRECTOR,*/}
      {/*  ROLE_EMPLOYEE,*/}
      {/*]) && (*/}
      {/*  <ul className="Menu__list Menu__list_box">*/}
      {/*    <li className="List__link" style={{*/}
      {/*      display: "flex",*/}
      {/*      alignItems: "center",*/}
      {/*      justifyContent: "space-between",*/}
      {/*    }}*/}

      {/*        onClick={() => setIsProfile(!isProfile)}>*/}
      {/*      Персональные*/}

      {/*      <div className="Button__swith">*/}
      {/*        <div*/}
      {/*          className={*/}
      {/*            "Swith" +*/}
      {/*            ((isProfile && " Swith_active ") ||*/}
      {/*              "")*/}
      {/*          }*/}
      {/*        ></div>*/}
      {/*      </div>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*)}*/}
    </>
  );
}

export default MenuRouting;
