import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import moment from "moment";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  Input,
  useObject,
  jsonFormatText,
  isValidateNullText, formatBytes,
} from "@vokymlak/kabinet-ui";

function ModalAppealCommentAdd(props) {
  const inputTicketFile = useRef(null);
  const [files, setFiles] = useState(null);

  const fileSend = (event) => {
    const files = event.target.files;
    setFiles(Array.from(files));
  };

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/appeal/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputText = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const text = jsonFormatText(inputText.current.value);

    if (isValidateNullText(text)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    // const jsonData = JSON.stringify({
    //   comment: text,
    // });

    let formData = new FormData();

    formData.append("comment", text);

    // if (!files || (!!files && files.length === 0)) {
    //   notification("Прикрепите файл", "red");
    //   return;
    // }

    if (!!files && files.length > 0) {

      if (files.filter(f => f.size > 262144000).length > 0) {
        notification("Один из файлов превышает допустимый размер в 25Мб", "red");
        return;
      }

      if (files.reduce(function (previousValue, currentValue) {
        return {
          size: previousValue.size + currentValue.size
        }
      }).size > (262144000 * 10)) {
        notification("Общий размер файлов превышает 250Мб", "red");
        return;
      }

      if (files.length > 10) {
        notification("Можно прикрепить не больше 10 файлов за раз", "red");
        return;
      }

      files.forEach(f => {
        formData.append("files", f);
      })
    }


    axios
      .post("/api/appeal/" + props.object.uuid + "/comment/add", formData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Сообщение добавлено", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            Новое сообщение
            <div className="Text__signature">К обращению</div>
          </div>

          <div className="Form__field">
            <Textarea
              reference={inputText}
              label="Сообщение *"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputTicketFile}
              type="file"
              label="Файлы"
              isFixed={true}
              onChange={(event) => fileSend(event)}
              isMultiple={true}
            />
          </div>

          <div className="Form__field Form__field_last">
            {!!files && files.map((file, index) => (
              <div
                key={'file_' + index}
                style={{
                  minWidth: "auto",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f1f1f1",
                  padding: "6px 10px 6px 6px",
                  borderRadius: 5,
                  fontSize: 15,
                  marginBottom: 4,
                  marginTop: 4
                }}>
                {!!file.name && (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textTransform: "uppercase",
                      fontSize: 10,
                      fontWeight: 900,
                      borderRadius: 4,
                      color: "white",
                      backgroundColor: getColorFile(file.name.split(".").pop()),
                      marginRight: 8,
                      padding: "10px 4px",
                      minWidth: 42
                    }}>
                                    {file.name.split(".").pop()}
                                  </span>
                )}
                <div>
                  <div style={{
                    fontSize: 15
                  }}>
                    {file.name.slice(0, file.name.lastIndexOf("."))}
                  </div>

                  <div style={{
                    fontSize: 13
                  }}>
                    {formatBytes(file.size)}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Отправить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalAppealCommentAdd;
