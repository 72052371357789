import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  Input,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalCompanyFnsCertificateAdd(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/company/" + props.company.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputFile = useRef(null);
  const [file, setFile] = useState(null);

  const { notification } = useNotification();

  const fileSend = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const changeAdd = (event) => {
    event.preventDefault();

    if (!file) {
      notification("Выберите сертификат", "red");
      return;
    }

    let formData = new FormData();
    formData.append("file", file);

    axios
      .post(
        "/api/company/" + props.company.uuid + "/fns-certificate/add",
        formData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        // props.successfulRequest();
        notification("Сертификат добавлен", "green");
        // props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый сертификат
            <div className="Text__signature">{object.short_name}</div>
          </div>

          <div className="Form__field Form__field_last">
            <Input
              reference={inputFile}
              type="file"
              label="Сертификат"
              isFixed={true}
              onChange={(event) => fileSend(event)}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalCompanyFnsCertificateAdd;
