import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import axios from "axios";
import {
  ButtonIcon,
  getAccountFio,
  getErrorMessageByKey,
  Icon,
  isProfileRoleTypeTags,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Textarea,
  useNotification,
  useObject,
  useProfile,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import moment from "moment/moment";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import {useViewer} from "../../../components/Viewer";
import ModalAppealCommentAdd from "./ModalAppealCommentAdd";
import {Avatar} from "../../../components/Avatar/Avatar";
import ModalAppealMessageEdit from "./ModalAppealMessageEdit";
import ModalAppealEmployeeViewed from "./ModalAppealEmployeeViewed";
import {ROLE_CLIENT} from "../../../config/constants";
import ModalAppealProcessing from "./ModalAppealProcessing";
import ModalAppealRejected from "./ModalAppealRejected";
import ModalAppealExecuted from "./ModalAppealExecuted";
import ModalAppealFileAdd from "./ModalAppealFileAdd";
import ModalAppealFileEdit from "./ModalAppealFileEdit";
import ModalAppealDelete from "./ModalAppealDelete";
import ModalAppealArchive from "./ModalAppealArchive";
import ModalAppealArchiveIn from "./ModalAppealArchiveIn";
import ModalAppealReturned from "./ModalAppealReturned";
import ModalAppealEditExecutor from "./ModalAppealEditExecutor";
import ModalAppealCommentEdit from "./ModalAppealCommentEdit";
import ModalAppealHistories from "./ModalAppealHistories";
// import ModalAppealClientViewed from "./ModalAppealClientViewed";
// import ModalMyAppealFileAdd from "./ModalMyAppealFileAdd";
// import ModalAppealArchive from "./ModalAppealArchive";

function ModalAppealShow(props) {
  const {profile} = useProfile();

  const [link, setLink] = useState('comments');
  const [histories, setHistories] = useState(null);

  const inputText = useRef(null);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  };

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl: "/api/appeal/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const changePrivateMessageAdd = (object) => {
    const text = jsonFormatText(inputText.current.value);

    if (isValidateNullText(text)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      message: text,
    });

    axios
      .post("/api/appeal/" + object.uuid + "/private-message/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateObject(!isUpdateObject);
        notification("Сообщение добавлено", "green");
        inputText.current.value = "";
        scrollToBottom();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [link, object]);


  const {notification} = useNotification();


  const getNameAppeal = (tag) => {
    switch (tag) {
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "Включение в план закупки / план график";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "Согласование договора / дополнительного соглашения";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "Согласование независимых гарантий";

      case "DOCUMENTS_FOR_PURCHASE":
        return "Документы для закупки";

      case "REGISTRATION_AGREEMENT":
        return "Договор на регистрацию";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "Дополнительное соглашение на регистрацию";

      case "REGISTRATION_FORMALIZATION":
        return "Исполнение на регистрацию";

      case "CLOSURE_AGREEMENT":
        return "Закрытие договора";

      case "ONE_MORE_QUESTION":
        return "Иной вопрос";
    }
    return null;
  }

  const getIconNameAppeal = (tag) => {
    switch (tag) {
      // case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
      //   return "line-event";
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "line-free-cancellation";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "line-task";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "line-task-alt";

      case "DOCUMENTS_FOR_PURCHASE":
        return "line-receipt-long";

      case "REGISTRATION_AGREEMENT":
        return "line-lab-profile";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "line-note-stack-add";

      case "REGISTRATION_FORMALIZATION":
        return "line-contract";

      case "CLOSURE_AGREEMENT":
        return "line-contract-delete";

      case "ONE_MORE_QUESTION":
        return "line-help";
      // case "ONE_MORE_QUESTION":
      //   return "line-unknown-document";
    }
    return null;
  }

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }


  const changeDownload = (item) => {
    axios
      .get("/api/appeal-file/" + item.uuid + "/download", {
        responseType: "arraybuffer",
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Accept: item.mime_type,
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          item.name + item.expansion
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const {viewer} = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };


  function isEmployeeViewed(item) {
    let isViewed = false

    if (!!item.comments) {
      item.comments.map(comment => {
        if (comment.index > item.employee_viewed_at) {
          isViewed = true
        }
      })
    }

    return isViewed
  }

  const [modalItemPROCESSING, setModalItemPROCESSING] = useState(null);
  const [modalItemREJECTED, setModalItemREJECTED] = useState(null);
  const [modalItemEXECUTED, setModalItemEXECUTED] = useState(null);
  const [modalItemRETURNED, setModalItemRETURNED] = useState(null);
  const [modalItemCommentAdd, setModalItemCommentAdd] = useState(null);
  const [modalItemMessageViewed, setModalItemMessageViewed] = useState(null);
  const [modalItemMessageEdit, setModalItemMessageEdit] = useState(null);
  const [modalItemCommentEdit, setModalItemCommentEdit] = useState(null);
  const [modalItemArchive, setModalItemArchive] = useState(null);
  const [modalItemArchiveIn, setModalItemArchiveIn] = useState(null);

  const [modalItemFileAdd, setModalItemFileAdd] = useState(null);
  const [modalItemDelete, setModalItemDelete] = useState(null);
  const [modalItemFileEdit, setModalItemFileEdit] = useState(null);
  const [isModalItemHistories, setIsModalItemHistories] = useState(false);

  const [modalItemExecutor, setModalItemExecutor] = useState(null);

  const [modalObjectShow, setModalObjectShow] = useState(null);

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  useEffect(() => {
    if (!!object) {
      axios.get(
        "api/appeal/" +
        object.uuid +
        "/histories",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          }
        })
        .then((response) => {
          setHistories(response.data);
        })
        .catch(error => {
          console.error(error);
          setHistories(null);
        });
    }
  }, [object]);

  function formatFileSize(bytes) {
    if (bytes === 0) return '0 байт';

    const units = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'];
    const kilo = 1024;
    const factor = Math.floor(Math.log(bytes) / Math.log(kilo));

    const size = (bytes / Math.pow(kilo, factor)).toFixed(2);
    return `${size} ${units[factor]}`;
  }

  return (
    !!object &&
    <>
      <Modal handleClose={() => props.handleClose()} contentStyle={{
        width: 520
      }}>
        <div className="Form" style={{
          padding: 6
        }}>
          <div className="Form__title" style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: 600
          }}>
            <div style={{
              marginRight: 12
            }}>
              <Icon size={56} name={getIconNameAppeal(object.appeal_type.tag)}/>
            </div>
            <div>
              {(!!getNameAppeal(object.appeal_type.tag) && getNameAppeal(object.appeal_type.tag)) || "Новое"}
              <div className="Text__signature">Обращение</div>
            </div>
          </div>

          <div className="Form__field">
            <div style={{
              margin: "12px 0 0",
              backgroundColor: "#20202015",
              padding: "8px 10px",
              borderRadius: 5
            }}>

              {!!object.project && !!object.project.company && (
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.project.company.short_name && object.project.company.short_name) || "-"}
                </div>
              )}
              {!!object.client && (
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  {getAccountFio(object.client)}
                </div>
              )}
            </div>
          </div>

          {!!object.executor && (
            <div
              style={{
                width: "100%",
                marginTop: 4,
              }}
            >
              <div style={{
                padding: "8px",
                backgroundColor: "#20202010",
                borderRadius: 7,
                textAlign: "left"
              }}>

                <div style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <div style={{
                    marginRight: 8,
                    height: 36
                  }}>
                    {!!object.executor.avatar && isMD5Hash(object.executor.avatar) && (
                      <img height={36} width={36} style={{
                        borderRadius: 36,
                      }} src={ROOT_URL + "/api/account/avatar/" + object.executor.avatar}/>
                    )}
                    {!!object.executor.avatar && !isMD5Hash(object.executor.avatar) && (
                      <img height={36} width={36} style={{
                        borderRadius: 36,
                      }} src={"data:image/jpeg;base64, " + object.executor.avatar}/>
                    )}

                    {!object.executor.avatar && (
                      <Avatar size={36} value={getAccountFio(object.executor)}/>
                    )}
                  </div>
                  <div>
                    <div style={{
                      fontSize: 12,
                      fontWeight: 400,
                    }}>
                      Исполнитель
                    </div>
                    <div style={{
                      fontSize: 15,
                      fontWeight: 700,
                    }}>
                      {getAccountFio(object.executor)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!!object.work_note && (
            <div
              style={{
                width: "100%",
                marginTop: 4,
              }}
            >
              <div style={{
                padding: "8px",
                borderRadius: 7,
                textAlign: "left",
                border: "2px dashed #20202025"
              }}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 400,
                  fontSize: 16
                }}>
                  {object.work_note}
                </div>
              </div>
            </div>
          )}

          {!!object.date_control && (
            <div
              style={{
                width: "100%",
                margin: "4px 4px 0",
                fontWeight: 400,
                fontSize: 16
              }}
            >
              Срок исполнения до {moment(object.date_control).format("DD.MM.YYYY")}
            </div>
          )}

          {(!!object.executor || !!object.date_control) && !!object.who && (
            <div
              style={{
                width: "100%",
                margin: "4px 4px 0",
                fontWeight: 400,
                fontSize: 14
              }}
            >
              <span style={{opacity: .5}}>Назначил:</span> {getAccountFio(object.who)}
            </div>
          )}

          {!!object.executor && (
            <div className="Form__actions Form__actions_center">
              <div className="Button__text" style={{
                color: "#0064ff"
              }}
                   onClick={() => setModalItemExecutor(object)}
              >
                {/*Изменить ответственного*/}
                Передать в работу далее
              </div>
            </div>
          )}

          {!object.executor && (
            <div className="Form__actions Form__actions_center">
              <div className="Button__text" style={{
                color: "#0064ff"
              }}
                   onClick={() => setModalItemExecutor(object)}
              >
                {/*Назначить ответственного*/}
                Передать в работу
              </div>
            </div>
          )}

          <div className="Form__actions Form__actions_center" style={{
            margin: "8px 0 0"
          }}>
            {!!histories && (
              <div className="Action__link"
                   onClick={() => setIsModalItemHistories(true)}>
                Просмотр истории изменении ({histories.length})
              </div>
            )}
          </div>

          <hr className={"Page__hr"} style={{marginTop: 20, marginBottom: 16}}/>

          <div className="Form__field" style={{
            marginBottom: 8
          }}>
            <div className={"Field__label"} style={{
              display: "flex",
              justifyContent: "center"
            }}>Выбор статуса обращения
            </div>
          </div>

          <div className="Form__actions" style={{
            marginTop: 0,
            justifyContent: "space-around"
          }}>
            {!object.employee_archived_at && (
              <>
                {(object.appeal_status.tag === "CONSIDERATION" ||
                  object.appeal_status.tag === "REJECTED" ||
                  object.appeal_status.tag === "EXECUTED" ||
                  object.appeal_status.tag === "PROCESSING" ||
                  object.appeal_status.tag === "RETURNED") && (
                  <>
                    {(object.appeal_status.tag === "CONSIDERATION" ||
                      object.appeal_status.tag === "REJECTED" ||
                      object.appeal_status.tag === "EXECUTED" ||
                      object.appeal_status.tag === "RETURNED") && (
                      <>
                        <div
                          className="Button__text"
                          onClick={() => setModalItemPROCESSING(object)}
                        >
                          В работу
                        </div>
                      </>
                    )}

                    {(object.appeal_status.tag === "PROCESSING") && (
                      <>
                        <div
                          className="Button__text"
                          onClick={() => setModalItemPROCESSING(object)}
                        >
                          Продлить работу
                        </div>
                      </>
                    )}

                    {(object.appeal_status.tag === "PROCESSING") && (
                      <>
                        <div
                          className="Button__text"
                          onClick={() => setModalItemRETURNED(object)}
                        >
                          Вернуть
                        </div>
                      </>
                    )}
                  </>
                )}

                {(object.appeal_status.tag === "PROCESSING" ||
                  object.appeal_status.tag === "RETURNED") && (
                  <>
                    <div
                      className="Button__text"
                      onClick={() => setModalItemEXECUTED(object)}
                    >
                      Завершить
                    </div>
                  </>
                )}

                {(object.appeal_status.tag === "PROCESSING" ||
                  object.appeal_status.tag === "CONSIDERATION" ||
                  object.appeal_status.tag === "RETURNED") && (
                  <>
                    <div
                      className="Button__text"
                      onClick={() => setModalItemREJECTED(object)}
                    >
                      Отклонить
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <hr className={"Page__hr"} style={{marginTop: 12, marginBottom: 24}}/>

          <div className="Form__field">
            <div
              style={{
                fontSize: 15,
                fontWeight: 400,
                backgroundColor: "#20202010",
                borderRadius: 5,
                padding: "10px 12px",
              }}
            >
              {object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
                <>
                  Направление проекта контракта и источников финансирования для включения
                </>
              )}
              {object.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
                <>
                  Направление проекта договора, проекта дополнительного соглашения для правовой проверки юристом
                </>
              )}
              {object.appeal_type.tag === "COORDINATION_OF_INDEPENDENT_GUARANTEES" && (
                <>
                  Направление проекта независимой гарантии для правовой проверки юристом
                </>
              )}
              {object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" && (
                <>
                  Направление потребности и документов для проведения закупочной процедуры.<br/>
                  Необходимые документы:<br/>
                  -техническое задание;<br/>
                  -расчет начально-максимальной стоимости договора;<br/>
                  -коммерческие предложения;<br/>
                  -источники финансирования;<br/>
                  -проект договора.<br/>
                </>
              )}
              {object.appeal_type.tag === "REGISTRATION_AGREEMENT" && (
                <>
                  Направление подписанного документа для регистрации с приложенными источниками финансирования
                </>
              )}
              {object.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
                <>
                  Направление подписанного документа для регистрации
                </>
              )}
              {object.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
                <>
                  Направление актов выполненных работ / товарных накладных / УПД / платежных поручений на размещение
                </>
              )}
              {object.appeal_type.tag === "CLOSURE_AGREEMENT" && (
                <>
                  Направление документов на закрытие договора
                </>
              )}
              {object.appeal_type.tag === "ONE_MORE_QUESTION" && (
                <>
                  Вопросы иного характера
                </>
              )}
            </div>
          </div>

          <div className="Form__field">
            <div className={"Field__label"}>Проект</div>
            <div className={"Field__text"}>
              {(!!object.project &&
                ((!!object.project.project_year ? object.project.project_year + " " : "") +
                  object.project.name +
                  (!!object.project.law ? " (" + object.project.law.name + ")" : ""))) || "-"}
            </div>
          </div>

          {(object.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <div className="Form__field">
              <div className={"Field__label"}>Договор</div>
              {!!object.contract_number && (
                <div className={"Field__text"}>
                  {(object.contract_number)}
                </div>
              )}
              <div className={"Field__text"} style={{
                fontSize: 13,
              }}>
                {!!object.contract && (
                  <div>
                    <div>
                      {object.contract.registry_number && (
                        <span style={{
                          marginRight: 6
                        }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                          {object.contract.registry_number + " "}
                              </span>
                      )}
                      {!!object.contract.contract_number && object.contract.contract_number}
                    </div>
                    {!!object.contract.supplier && (
                      <div>
                        <span style={{
                          fontSize: 14
                        }}>
                          {object.contract.supplier}
                        </span>
                      </div>
                    )}
                    {!!object.contract.subject && (
                      <div>
                        <span style={{
                          fontSize: 14
                        }}>
                          {object.contract.subject}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
            object.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <div className="Form__field">
              <div className={"Field__label"}>Источник финансирования (КВФО, КЦСР, КВР, КОСГУ)</div>
              <div className={"Field__text"}>
                {(!!object.source_funding &&
                  object.source_funding) || "-"}
              </div>
            </div>
          )}


          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT") && (
            <div className="Form__field">
              <div className={"Field__label"}>Потребность / договор</div>
              <div className={"Field__text"}>
                {(!!object.requirement &&
                  object.requirement) || "-"}
              </div>
            </div>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <div className="Form__field">
              <div className={"Field__label"}>Сумма</div>
              <div className={"Field__text"}>
                {(!!object.amount &&
                  new Intl.NumberFormat("ru-RU").format(
                    Number(object.amount / 100).toFixed(2)
                  )) || "-"}
              </div>
            </div>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE") && (
            <div className="Form__field">
              <div className={"Field__label"}>Способ закупки</div>
              <div className={"Field__text"}>
                {(!!object.purchase_method &&
                object.purchase_method === "EP" ? "ЕП." : object.purchase_method === "KP" ? "КП." : "Не указан") || "-"}
              </div>
            </div>
          )}

          {object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
            <div className="Form__field">
              <div className={"Field__label"}>Предполагаемый срок размещения закупки</div>
              <div className={"Field__text"}>
                {(!!object.date_placement &&
                  moment(object.date_placement.substring(0, 19)).format(
                    "DD.MM.YYYY"
                  )) || "-"}
              </div>
            </div>
          )}

          {object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" && (
            <div className="Form__field">
              <div className={"Field__label"}>Планируемый срок размещения закупки</div>
              <div className={"Field__text"}>
                {(!!object.date_placement &&
                  moment(object.date_placement.substring(0, 19)).format(
                    "DD.MM.YYYY"
                  )) || "-"}
              </div>
            </div>
          )}

          {object.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
            <div className="Form__field">
              <div className={"Field__label"}>Реквизит договора / ДС</div>
              <div className={"Field__text"}>
                {(!!object.contract_requisite &&
                  object.contract_requisite) || "-"}
              </div>
            </div>
          )}

          {(object.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" ||
            object.appeal_type.tag === "ONE_MORE_QUESTION") && (
            <div className="Form__field">
              <div className={"Field__label"}>Вопрос</div>
              <div className={"Field__text"}>
                {(!!object.question &&
                  object.question) || "-"}
              </div>
            </div>
          )}

          {object.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
            <div className="Form__field">
              <div className={"Field__label"}>Дата ДС</div>
              <div className={"Field__text"}>
                {(!!object.date_agreement &&
                  moment(object.date_agreement.substring(0, 19)).format(
                    "DD.MM.YYYY"
                  )) || "-"}
              </div>
            </div>
          )}

          {object.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
            <div className="Form__field">
              <div className={"Field__label"}>Документ (акт / платёж)</div>
              <div className={"Field__text"}>
                {(!!object.document_type &&
                object.document_type === "ACT" ? "Акт" : object.document_type === "PAYMENT" ? "Платёж" : "Не указан") || "-"}
              </div>
            </div>
          )}

          {object.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
            <div className="Form__field">
              <div className={"Field__label"}>Дата документа</div>
              <div className={"Field__text"}>
                {(!!object.document_date &&
                  moment(object.document_date.substring(0, 19)).format(
                    "DD.MM.YYYY"
                  )) || "-"}
              </div>
            </div>
          )}

          {(object.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
            object.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
            object.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
            object.appeal_type.tag === "COORDINATION_OF_INDEPENDENT_GUARANTEES" ||
            object.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" ||
            object.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
            object.appeal_type.tag === "CLOSURE_AGREEMENT") && (
            <div className="Form__field">
              <div className={"Field__label"}>Примечание</div>
              <div className={"Field__text"}>
                {(!!object.note && object.note) || "-"}
              </div>
            </div>
          )}

          <hr className={"Page__hr"} style={{marginTop: 24}}/>


          <div className="Form__field" style={{
            marginTop: 24,
            marginBottom: 24,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <span>
            <a
              className={styleCard._block + ' ' + styleCard._block_hover + ' ' + styleCard._block_black + ' ' +
                (link === 'comments' ? styleCard._focus : '')}
              onClick={() => setLink('comments')}
              style={{
                opacity: 1,
                minWidth: "inherit",
                padding: "8px 10px"
              }}>
              Сообщения {!!object.comments && " (" + object.comments.length + ")"}
            </a>
            <a
              className={styleCard._block + ' ' + styleCard._block_hover + ' ' + styleCard._block_black + ' ' +
                (link === 'files' ? styleCard._focus : '')}
              onClick={() => setLink('files')}
              style={{
                opacity: 1,
                minWidth: "inherit",
                padding: "8px 10px"
              }}>
              Документы {!!object.files && " (" + object.files.length + ")"}
            </a>
            </span>
            <span>
            <a
              className={styleCard._block + ' ' + styleCard._block_hover + ' ' + styleCard._block_black + ' ' +
                (link === 'messages' ? styleCard._focus : '')}
              onClick={() => setLink('messages')}
              style={{
                opacity: 1,
                minWidth: "inherit",
                padding: "8px 10px",
                marginRight: 0
              }}>
              Чат сотрудников {!!object.messages && " (" + object.messages.length + ")"}
            </a>
            </span>
          </div>

          {/*<hr className={"Page__hr"}/>*/}

          {link === 'messages' && (
            <>
              {!!object.messages && (
                <div className="Form__field">
                  {/*<div className={"Field__label"}>Сообщения</div>*/}

                  <div className={"Field__text"}>
                    <div
                      className={
                        styleContent._block + " " + styleContent._block_bottom
                      }
                    >
                      <div className={styleContent._block__content}>
                        <div className={styleContent._block}>
                          <div className={styleContent._block__content} style={{
                            flexDirection: "column",
                            flexWrap: "nowrap",
                            maxHeight: 320,
                            overflowY: "auto"
                          }}>
                            {object.messages.map((comment) => (
                              <div key={"comment_" + object.uuid + ":" + comment.uuid}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: !!comment.author && profile.uuid === comment.author.uuid && "flex-end"
                                  }}>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: 320,
                                      backgroundColor: "#ffffff",
                                      marginBottom: 4,
                                      borderRadius: 5,

                                      border: "1px solid #dbdbdb75",
                                      margin: "0 0 8px 0",
                                    }}>

                                    <div style={{
                                      fontSize: 17,
                                      fontWeight: 400,
                                      padding: "8px 10px",
                                      borderRadius: "5px 5px 0 0",
                                      wordWrap: "break-word",
                                      whiteSpace: "pre-line"
                                    }}>
                                      {comment.message}
                                    </div>
                                    <div style={{
                                      padding: "4px 10px 4px 8px",
                                      // backgroundColor: comment.index > object.employee_viewed_at
                                      //   ? "#0061ff33"
                                      //   : !!comment.author && profile.uuid === comment.author.uuid ? "white" : "#20202025",
                                      backgroundColor: !!comment.author && profile.uuid === comment.author.uuid ? "white" : "#20202025",
                                      borderRadius: "0 0 5px 5px"
                                    }}>
                                      <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}>
                                        <div style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                          <div style={{
                                            marginRight: 8
                                          }}>
                                            {!!comment.author && !!comment.author.avatar && isMD5Hash(comment.author.avatar) && (
                                              <img height={36} width={36} style={{
                                                borderRadius: 36,
                                              }} src={ROOT_URL + "/api/account/avatar/" + comment.author.avatar}/>
                                            )}
                                            {!!comment.author && !!comment.author.avatar && !isMD5Hash(comment.author.avatar) && (
                                              <img height={36} width={36} style={{
                                                borderRadius: 36,
                                              }} src={"data:image/jpeg;base64, " + comment.author.avatar}/>
                                            )}

                                            {!!comment.author && !comment.author.avatar && (
                                              <Avatar size={36} value={getAccountFio(comment.author)}/>
                                            )}
                                          </div>
                                          <div>
                                            <div style={{
                                              fontSize: 15,
                                              fontWeight: 600
                                            }}>{getAccountFio(comment.author)}</div>
                                            <div style={{
                                              marginTop: 2,
                                              fontSize: 13,
                                              fontWeight: 400
                                            }}>{moment.unix(comment.index).format(
                                              "HH:mm DD.MM.YYYY"
                                            )} {!!comment.updated_at && "(изменено)"}</div>
                                          </div>
                                        </div>

                                        {!object.employee_archived_at && (
                                          <div>
                                            {!isProfileRoleTypeTags(profile, [
                                              ROLE_CLIENT,
                                            ]) && !!comment.author && profile.uuid === comment.author.uuid && (
                                              <ButtonIcon
                                                // tooltipText={"Редактировать"}
                                                iconName={"custom-edit"}
                                                iconColor={"#202020"}
                                                size={36}
                                                style={{marginLeft: 12}}
                                                onClick={() => setModalItemMessageEdit(comment)}
                                              />
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            ))}
                            <div ref={messagesEndRef}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!object.messages && (
                <div className="Form__field">
                  <div className={"Field__label"} style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>Нет отправленных сообщений
                  </div>
                </div>
              )}

              {!object.employee_archived_at && (
                <>
                  <div className="Form__field Form__field_last">
                    <Textarea
                      reference={inputText}
                      label="Сообщение *"
                    />
                  </div>
                  <div className="Form__actions Form__actions_center">
                    <button className="Action__button Action__button_all Action__button_blue"
                            onClick={() => changePrivateMessageAdd(object)}>
                      Отправить сообщение
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {link === 'comments' && (
            <>
              {!!object.comments && (
                <div className="Form__field">
                  {/*<div className={"Field__label"}>Сообщения</div>*/}

                  <div className={"Field__text"}>
                    <div
                      className={
                        styleContent._block + " " + styleContent._block_bottom
                      }
                    >
                      <div className={styleContent._block__content}>
                        <div className={styleContent._block}>
                          <div className={styleContent._block__content} style={{
                            flexDirection: "column",
                            flexWrap: "nowrap",
                          }}>
                            {object.comments.map((comment) => (
                              <div key={"comment_" + object.uuid + ":" + comment.uuid}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: !!comment.author && profile.uuid === comment.author.uuid && "flex-end"
                                  }}>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: 320,
                                      backgroundColor: "#ffffff",
                                      marginBottom: 4,
                                      borderRadius: 5,

                                      border: "1px solid #dbdbdb",
                                      margin: "0 0 8px 0",
                                    }}>

                                    {!!comment.files && (
                                      <div style={{
                                        width: "100%",
                                        padding: 4,
                                        borderRadius: "5px 5px 0 0",
                                        backgroundColor: "#20202025",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: "wrap",
                                        flexDirection: "column"
                                      }}>
                                        {comment.files.map((file) => (
                                          <div
                                            className={styleCard._block + " " + styleCard._block_hover + " " + styleCard._block_white}
                                            key={"file_" + object.uuid + ":" + file.uuid}
                                            // onContextMenu={(event) => (event.preventDefault(), changeDownload(file))}
                                            // onClick={(!!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png"))
                                            //   ? (event) => (event.preventDefault(), changeViewer(file.data, file.mime_type))
                                            //   : (event) => (event.preventDefault(), changeDownload(file))
                                            // }
                                            // onClick={(event) => (event.preventDefault(), changeDownload(file))}
                                            onClick={(event) => (event.preventDefault(), setModalItemFileEdit(file))}
                                            style={{
                                              minWidth: "auto",
                                              display: "flex",
                                              alignItems: "center",
                                              border: "none",
                                              margin: 4,
                                              position: "relative",
                                            }}>
                                            {!!file.expansion && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  textTransform: "uppercase",
                                                  fontSize: 15,
                                                  fontWeight: 900,
                                                  borderRadius: 3,
                                                  color: getColorFile(file.expansion.slice(1)),
                                                  // backgroundColor: getColorFile(file.expansion.slice(1)),
                                                  marginRight: 8,
                                                  padding: 2,
                                                  width: 42
                                                }}>
                                                {file.expansion.slice(1)}
                                              </div>
                                            )}
                                            <div className={styleCard._block__title} style={{
                                              display: "flex",
                                              flexDirection: "column"
                                            }}>
                                <span style={{
                                  fontSize: 14,
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                }}>{file.name}</span>
                                              <span style={{
                                                fontSize: 12,
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}>{formatFileSize(file.size)}</span>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    <div style={{
                                      fontSize: 17,
                                      fontWeight: 400,
                                      padding: "8px 10px",
                                      borderRadius: "5px 5px 0 0",
                                      wordWrap: "break-word",
                                      whiteSpace: "pre-line"
                                    }}>
                                      {comment.comment}
                                    </div>
                                    <div style={{
                                      padding: "4px 10px 4px 8px",
                                      backgroundColor: comment.index > object.employee_viewed_at
                                        ? "#0061ff33"
                                        : !!comment.author && profile.uuid === comment.author.uuid ? "white" : "#20202025",
                                      borderRadius: "0 0 5px 5px"
                                    }}>
                                      <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}>
                                        <div style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                          <div style={{
                                            marginRight: 8
                                          }}>
                                            {!!comment.author && !!comment.author.avatar && isMD5Hash(comment.author.avatar) && (
                                              <img height={36} width={36} style={{
                                                borderRadius: 36,
                                              }} src={ROOT_URL + "/api/account/avatar/" + comment.author.avatar}/>
                                            )}
                                            {!!comment.author && !!comment.author.avatar && !isMD5Hash(comment.author.avatar) && (
                                              <img height={36} width={36} style={{
                                                borderRadius: 36,
                                              }} src={"data:image/jpeg;base64, " + comment.author.avatar}/>
                                            )}

                                            {!!comment.author && !comment.author.avatar && (
                                              <Avatar size={36} value={getAccountFio(comment.author)}/>
                                            )}
                                          </div>
                                          <div>
                                            <div style={{
                                              fontSize: 15,
                                              fontWeight: 600
                                            }}>{getAccountFio(comment.author)}</div>
                                            <div style={{
                                              marginTop: 2,
                                              fontSize: 13,
                                              fontWeight: 400
                                            }}>{moment.unix(comment.index).format(
                                              "HH:mm DD.MM.YYYY"
                                            )} {!!comment.updated_at && "(изменено)"}</div>
                                          </div>
                                        </div>

                                        {!object.employee_archived_at && (
                                          <div>
                                            {!isProfileRoleTypeTags(profile, [
                                              ROLE_CLIENT,
                                            ]) && !!comment.author && profile.uuid === comment.author.uuid && (
                                              <ButtonIcon
                                                // tooltipText={"Редактировать"}
                                                iconName={"custom-edit"}
                                                iconColor={"#202020"}
                                                size={36}
                                                style={{marginLeft: 12}}
                                                onClick={() => setModalItemCommentEdit(comment)}
                                              />
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!object.comments && (
                <div className="Form__field">
                  <div className={"Field__label"} style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>Нет отправленных сообщений
                  </div>
                </div>
              )}

              {isEmployeeViewed(object) && (
                <div className="Form__actions Form__actions_center">
                  <div className="Button__text"
                       onClick={() => setModalItemMessageViewed(object)}
                  >
                    Отметить сообщения как прочитанные
                  </div>
                </div>
              )}

              {!object.employee_archived_at && (
                <div className="Form__actions Form__actions_center">
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => setModalItemCommentAdd(object)}>
                    Добавить сообщение
                  </button>
                </div>
              )}
            </>
          )}

          {link === 'files' && (
            <>
              {!!object.files && (
                <div className="Form__field">
                  {/*<div className={"Field__label"}>Документы</div>*/}

                  <div className={"Field__text"}>
                    <div
                      className={
                        styleContent._block + " " + styleContent._block_bottom
                      }
                    >
                      <div className={styleContent._block__content}>
                        <div className={styleContent._block}>
                          <div className={styleContent._block__content} style={{
                            flexDirection: "column"
                          }}>
                            {object.files.map((file) => (
                              <div
                                className={styleCard._block + " " + styleCard._block_hover + " " + styleCard._block_white}
                                key={"file_" + object.uuid + ":" + file.uuid}
                                // onContextMenu={(event) => (event.preventDefault(), changeDownload(file))}
                                // onClick={(!!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png"))
                                //   ? (event) => (event.preventDefault(), changeViewer(file.data, file.mime_type))
                                //   : (event) => (event.preventDefault(), changeDownload(file))
                                // }
                                // onClick={(event) => (event.preventDefault(), changeDownload(file))}
                                onClick={(event) => (event.preventDefault(), setModalItemFileEdit(file))}
                                style={{
                                  minWidth: "auto",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  border: "2px solid #dbdbdb75",
                                  margin: "0 0 8px 0",
                                  position: "relative",
                                }}>
                                {!!file.expansion && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textTransform: "uppercase",
                                      fontSize: 17,
                                      fontWeight: 900,
                                      borderRadius: 3,
                                      color: getColorFile(file.expansion.slice(1)),
                                      // backgroundColor: getColorFile(file.expansion.slice(1)),
                                      marginRight: 8,
                                      padding: "6px 4px",
                                      width: 48
                                    }}>
                                    {file.expansion.slice(1)}
                                  </div>
                                )}
                                <div className={styleCard._block__title} style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}>
                                <span style={{
                                  fontSize: 15,
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                }}>{file.name}</span>
                                  <span style={{
                                    fontSize: 12,
                                    wordWrap: "break-word",
                                    wordBreak: "break-all",
                                  }}>{formatFileSize(file.size)}</span>
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex"
                                    }}
                                  >
                                    {!!file.author && (
                                      <div
                                        style={{
                                          fontSize: 14,
                                          fontWeight: 400,
                                          padding: "3px 0 3px 0",
                                          // backgroundColor: "#dbdbdb",
                                          marginRight: 12,
                                          borderRadius: 24,
                                          display: "flex",
                                          alignItems: "center",
                                          position: "relative",
                                          marginTop: 4
                                        }}
                                      >
                                        <div style={{
                                          display: "flex",
                                        }}>
                                          {(
                                            <div style={{
                                              marginRight: 4,
                                              height: 20,
                                              borderRadius: 20,
                                              backgroundColor: "#dbdbdb"
                                            }}>
                                              {!!file.author.avatar && isMD5Hash(file.author.avatar) && (
                                                <img height={20} width={20} style={{
                                                  borderRadius: 20,
                                                }} src={ROOT_URL + "/api/account/avatar/" + file.author.avatar}/>
                                              )}
                                              {!file.author.avatar && (
                                                <Avatar size={20} value={getAccountFio(file.author)}/>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div style={{
                                          marginTop: 2,
                                        }}>
                                          {getAccountFio(file.author)}
                                        </div>
                                      </div>
                                    )}

                                    {!!file.created_at && (
                                      <div
                                        style={{
                                          fontSize: 14,
                                          // backgroundColor: "#202020",
                                          display: "inline-block",
                                          color: "#202020",
                                          padding: "5px 0 4px",
                                          borderRadius: 3,
                                          // margin: "0 2px",
                                          marginRight: 12,
                                          marginTop: 4
                                        }}
                                      >
                                        {moment(file.created_at.substring(0, 19)).format(
                                          "HH:mm DD.MM.YYYY"
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!object.files && (
                <div className="Form__field">
                  <div className={"Field__label"} style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>Нет прикреплённых документов
                  </div>
                </div>
              )}

              {!object.employee_archived_at && (
                <div className="Form__actions Form__actions_center">
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => setModalItemFileAdd(object)}
                  >
                    Прикрепить документ
                  </button>
                </div>
              )}
            </>
          )}

          <hr className={"Page__hr"} style={{marginTop: 24}}/>

          <div className="Form__actions">
            <div
              className="Button__text"
              onClick={() => setModalItemDelete(object)}
              style={{
                color: "#cf2f00"
              }}
            >
              Удалить обращение
            </div>

            {/*{!object.employee_archived_at && (*/}
            {/*  <div*/}
            {/*    className="Button__text"*/}
            {/*    onClick={() => setModalItemArchive(object)}*/}
            {/*    style={{*/}
            {/*      color: "#0061ff"*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Убрать в архив*/}
            {/*  </div>*/}
            {/*)}*/}
            {!!object.employee_archived_at && (
              <>
                <div
                  className="Button__text"
                  onClick={() => setModalItemArchiveIn(object)}
                  style={{
                    color: "#0061ff"
                  }}
                >
                  Восстановить из архива
                </div>
              </>
            )}

            <div className="Action__link" onClick={() => props.handleClose()}>
              Закрыть
            </div>
          </div>
        </div>
      </Modal>


      {!!modalItemExecutor && (
        <ModalAppealEditExecutor
          object={modalItemExecutor}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemExecutor(null)}
        />
      )}


      {!!modalItemPROCESSING && (
        <ModalAppealProcessing
          object={modalItemPROCESSING}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemPROCESSING(null)}
        />
      )}

      {!!modalItemREJECTED && (
        <ModalAppealRejected
          object={modalItemREJECTED}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemREJECTED(null)}
        />
      )}

      {!!modalItemEXECUTED && (
        <ModalAppealExecuted
          object={modalItemEXECUTED}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemEXECUTED(null)}
        />
      )}

      {!!modalItemRETURNED && (
        <ModalAppealReturned
          object={modalItemRETURNED}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemRETURNED(null)}
        />
      )}

      {!!modalItemCommentAdd && (
        <ModalAppealCommentAdd
          object={modalItemCommentAdd}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemCommentAdd(null)}
        />
      )}

      {!!modalItemMessageEdit && (
        <ModalAppealMessageEdit
          object={modalItemMessageEdit}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemMessageEdit(null)}
        />
      )}

      {!!modalItemCommentEdit && (
        <ModalAppealCommentEdit
          object={modalItemCommentEdit}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemCommentEdit(null)}
        />
      )}

      {!!modalItemMessageViewed && (
        <ModalAppealEmployeeViewed
          object={modalItemMessageViewed}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemMessageViewed(null)}
        />
      )}

      {!!modalItemFileAdd && (
        <ModalAppealFileAdd
          object={modalItemFileAdd}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemFileAdd(null)}
        />
      )}

      {!!modalItemFileEdit && (
        <ModalAppealFileEdit
          file={modalItemFileEdit}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemFileEdit(null)}
        />
      )}

      {!!modalItemArchive && (
        <ModalAppealArchive
          object={modalItemArchive}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemArchive(null)}
        />
      )}

      {!!modalItemArchiveIn && (
        <ModalAppealArchiveIn
          object={modalItemArchiveIn}
          successfulRequest={() => (props.successfulRequest(), setIsUpdateObject(!isUpdateObject))}
          handleClose={() => setModalItemArchiveIn(null)}
        />
      )}

      {!!modalItemDelete && (
        <ModalAppealDelete
          object={modalItemDelete}
          successfulRequest={() => (props.successfulRequest(), props.handleClose())}
          handleClose={() => setModalItemDelete(null)}
        />
      )}

      {!!histories && !!isModalItemHistories && (
        <ModalAppealHistories
          list={histories}
          successfulRequest={() => null}
          handleClose={() => setIsModalItemHistories(false)}
        />
      )}
    </>
  );
}

export default ModalAppealShow;
