import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Icon, Loading, Pagination, Search, Select, TextSearchMarker, Tooltip} from "@vokymlak/kabinet-ui";
import ModalAppealView from "./ModalAppealView";
import moment from "moment";
import ModalAppealShow from "./ModalAppealShow";
import ModalAppealDraft from "./ModalAppealDraft";
import styleCard from "../../../styles/modules/Card.module.scss";

function MyAppealsList(props) {
  const {
    object,
    isUpdateObject,
    setIsUpdateObject,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    isUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isArchive,
    setIsArchive,

    setStatus,
    status,
  } = props;

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalObjectShow, setModalObjectShow] = useState(null);
  const [modalObjectDraft, setModalObjectDraft] = useState(null);

  function calculatePercentage(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    const now = moment();

    const totalMilliseconds = end.diff(start);
    const elapsedMilliseconds = now.diff(start);

    const percentage = (elapsedMilliseconds / totalMilliseconds) * 100;

    if (percentage > 100) {
      return 100;
    }

    if (percentage < 0) {
      return 0;
    }

    return Math.floor(percentage); // Округляем до ближайшего целого числа
  }

  const getIconNameAppeal = (tag) => {
    switch (tag) {
      // case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
      //   return "line-event";
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "line-free-cancellation";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "line-task";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "line-task-alt";

      case "DOCUMENTS_FOR_PURCHASE":
        return "line-receipt-long";

      case "REGISTRATION_AGREEMENT":
        return "line-lab-profile";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "line-note-stack-add";

      case "REGISTRATION_FORMALIZATION":
        return "line-contract";

      case "CLOSURE_AGREEMENT":
        return "line-contract-delete";

      case "ONE_MORE_QUESTION":
        return "line-help";
      // case "ONE_MORE_QUESTION":
      //   return "line-unknown-document";
    }
    return null;
  }

  function isClientViewed(item) {
    let isViewed = false

    if (!!item.comments) {
      item.comments.map(comment => {
        if (comment.index > item.client_viewed_at) {
          isViewed = true
        }
      })
    }

    return isViewed
  }

  function getAllAppealsCount(counts) {
    let count = 0
    if (!!counts) {
      counts.types.map((t, index) => {
        count += t.count
      })
    }

    return count
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Обращения
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 160, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            <ButtonIcon
              tooltipText={"Архив"}
              iconName={"custom-archive"}
              iconColor={isArchive ? "#202020" : "#20202075"}
              size={24}
              style={{
                marginLeft: 16,
              }}
              onClick={() => setIsArchive(!isArchive)}
            />
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (
                setList(null),
                  setIsUpdateList(!isUpdateList),
                  setIsUpdateObject(!isUpdateObject)
              )}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 25,
                      title: 25,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Подать"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!!object && (
          <>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap"
            }}>
              <div
                className={styleCard._block + " " + styleCard._block_hover + " " + (status === "null" && styleCard._block_focus)}
                onClick={() => setStatus("null")}
                style={{
                  position: "relative",
                  backgroundColor: status === "null" ? "#ffffff" : "#dddddd"
                }}
              >
                <div
                  className={styleCard._block__number}
                  style={{
                    fontSize: 24,
                  }}
                >
                  {getAllAppealsCount(object) || "-"}
                </div>
                <div className={styleCard._block__title} style={{fontSize: 12}}>
                  Все статусы
                </div>
              </div>
              {object.statuses.map((s, index) => (
                <div
                  className={styleCard._block + " " + styleCard._block_hover + " " + (status === s.tag && styleCard._block_focus)}
                  key={'status_' + s.tag}
                  // onClick={status !== s.tag ? () => setStatus(s.tag) : () => setStatus("null")}
                  onClick={() => setStatus(s.tag)}
                  style={{
                    position: "relative",
                    backgroundColor: status === s.tag
                      ? "#ffffff"
                      : (s.tag === 'PROCESSING' && s.count > 0 ? "rgba(0, 97, 255, 0.145)"
                        : (s.tag === 'CONSIDERATION' && s.count > 0 ? "rgba(255, 195, 0, 0.314)"
                          : (s.tag === 'RETURNED' && s.count > 0 ? "rgba(255, 0, 0, 0.314)"
                            : (s.tag === 'DRAFT' && s.count > 0 ? "#bbbbbb" : "#dddddd"))))
                  }}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 24,
                    }}
                  >
                    {s.count || "-"}
                  </div>
                  <div className={styleCard._block__title} style={{fontSize: 12}}>
                    {s.name}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "absolute",
                      top: -8,
                      left: 0,
                      justifyContent: " flex-end",
                      width: "100%",
                      padding: "0 8px",
                    }}
                  >
                    {s.tag === 'RETURNED' && s.count > 0 && (
                      <div
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          backgroundColor: "#f44336",
                          display: "inline-block",
                          color: "white",
                          padding: "2px 4px 2px",
                          borderRadius: 3,
                          margin: "0 2px",
                        }}
                      >
                        Есть замечания
                      </div>
                    )}

                    {s.tag === 'DRAFT' && s.count > 0 && (
                      <div
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          backgroundColor: "#818181",
                          display: "inline-block",
                          color: "white",
                          padding: "2px 4px 2px",
                          borderRadius: 3,
                          margin: "0 2px",
                        }}
                      >
                        Не отправлены
                      </div>
                    )}

                    {s.tag === 'CONSIDERATION' && s.count > 0 && (
                      <div
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          backgroundColor: "#509d1d",
                          display: "inline-block",
                          color: "white",
                          padding: "2px 4px 2px",
                          borderRadius: 3,
                          margin: "0 2px",
                        }}
                      >
                        Новые
                      </div>
                    )}

                    {s.tag === 'PROCESSING' && s.count > 0 && (
                      <div
                        style={{
                          fontSize: 11,
                          fontWeight: 700,
                          backgroundColor: "#0061ff",
                          display: "inline-block",
                          color: "white",
                          padding: "2px 4px 2px",
                          borderRadius: 3,
                          margin: "0 2px",
                        }}
                      >
                        На исполнении
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <hr className={"Page__hr"}/>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content show">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active pointer" : "List__item pointer"
                    }
                    onClick={item.appeal_status.tag === "DRAFT" ? () => setModalObjectDraft(item) : () => setModalObjectShow(item)}
                    style={{
                      padding: "10px 12px",
                      // backgroundColor:
                      //   isUnroll(item.uuid) ? "white" :
                      //     item.appeal_status.tag === "DRAFT" ? "#8D6E6350" :
                      //       item.appeal_status.tag === "RETURNED" ? "#ff000033" :
                      //         item.appeal_status.tag === "CONSIDERATION" ? "#ffc30050" :
                      //           item.appeal_status.tag === "PROCESSING" ? "#0061ff25" :
                      //             "#e1e1e1",
                      position: "relative",
                      flexDirection: "column",
                      alignItems: "stretch",
                      // border: item.appeal_status.tag === "DRAFT" ? "3px solid #8D6E63" :
                      //   item.appeal_status.tag === "RETURNED" ? "3px solid #ff0000" :
                      //     item.appeal_status.tag === "CONSIDERATION" ? "3px solid #ffc300" :
                      //       item.appeal_status.tag === "PROCESSING" ? "3px solid #0061ff" :
                      //         false,
                      border: item.appeal_status.tag === "DRAFT" ? "3px solid #BBBBBB" :
                        item.appeal_status.tag === "RETURNED" ? "3px solid #f44336" :
                          item.appeal_status.tag === "CONSIDERATION" ? "3px solid #ffc107" :
                            item.appeal_status.tag === "PROCESSING" ? "3px solid #0061ff" :
                              false,
                      borderRadius: (item.appeal_status.tag === "DRAFT" ||
                        item.appeal_status.tag === "RETURNED" ||
                        item.appeal_status.tag === "CONSIDERATION" ||
                        item.appeal_status.tag === "PROCESSING") && 9
                    }}
                  >
                    <div className={"Item__wrapper"}>
                      <div
                        className="Item__title"
                      >
                        <div
                          className="Element__title"
                        >
                          <Icon size={36} name={getIconNameAppeal(item.appeal_type.tag)}/>
                          <div style={{marginLeft: 8}}>
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 600,
                                width: "100%",
                              }}
                            >
                              {(!!item.appeal_type && (
                                <TextSearchMarker
                                  text={item.appeal_type.name}
                                  search={searchQuery}
                                />
                              )) || "-"}

                              {!!item.files && (
                                <Tooltip text={'Прикрепления:&nbsp;' + item.files.length}
                                         style={{height: 14, marginLeft: 8}}>
                                  <Icon
                                    name={"custom-attach-file"}
                                    color={"#202020"}
                                    size={14}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </div>

                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {item.appeal_status.tag === 'RETURNED' && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#f44336",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Есть замечания
                            </div>
                          )}

                          {item.appeal_status.tag === 'DRAFT' && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#818181",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Не отправлено
                            </div>
                          )}

                          {item.appeal_status.tag === 'CONSIDERATION' && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#509d1d",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Новое
                            </div>
                          )}

                          {item.appeal_status.tag === 'PROCESSING' && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              На исполнении
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime">
                          {isClientViewed(item) && (
                            <Tooltip
                              text={"Новые&nbsp;сообщения!"}
                              style={{
                                marginRight: 4,
                              }}
                            >
                              <div
                                style={{
                                  width: 28,
                                  height: 28,
                                }}
                              >
                                <Icon name={"custom-comment"} color={"#0061ff"} size={28}/>
                              </div>
                            </Tooltip>
                          )}

                          {!!item.appeal_status && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 700,
                                // backgroundColor: "#0066ff",
                                backgroundColor: "white",
                                display: "inline-block",
                                color: "#202020",
                                // color: "white",
                                padding: "4px 8px 2px",
                                borderRadius: 24,
                                margin: "0 2px",
                              }}
                            >
                              {item.appeal_status.name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={"Item__wrapper"}
                         style={{
                           marginTop: 4
                         }}>
                      <div
                        className="Item__title"
                        style={{
                          width: "100%",
                          // backgroundColor: "#00000010",
                          borderRadius: 5,
                          border: "1px solid #dbdbdb"
                        }}
                      >
                        <div
                          className="Element__title"
                          style={{
                            width: "100%",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div className="Form" style={{
                            padding: 6
                          }}>

                            <div className="Form__field" style={{
                              marginBottom: 5,
                            }}>
                              <div className={"Field__label"} style={{
                                marginBottom: 0,
                                fontSize: 11,
                              }}>Проект
                              </div>
                              <div className={"Field__text"} style={{
                                fontSize: 13,
                              }}>
                                {(!!item.project &&
                                  ((!!item.project.project_year ? item.project.project_year + " " : "") +
                                    item.project.name +
                                    (!!item.project.law ? " (" + item.project.law.name + ")" : ""))) || "-"}
                              </div>
                            </div>

                            {(item.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
                              item.appeal_type.tag === "CLOSURE_AGREEMENT") && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Договор
                                </div>
                                {!!item.contract_number && (
                                  <div className={"Field__text"}>
                                    {item.contract_number}
                                  </div>
                                )}
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {!!item.contract && (
                                    <div>
                                      <div>
                                        {!!item.contract.registry_number && (
                                          <span style={{
                                            marginRight: 6
                                          }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                                            {item.contract.registry_number + " "}
                              </span>
                                        )}
                                        {!!item.contract.contract_number && item.contract.contract_number}
                                      </div>
                                    </div>
                                  )}
                                  {!!item.contract.supplier && (
                                    <div>
                        <span style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}>
                          {item.contract.supplier}
                        </span>
                                    </div>
                                  )}
                                  {!!item.contract.subject && (
                                    <div>
                        <span style={{
                          fontSize: 14,
                          fontWeight: 400
                        }}>
                          {item.contract.subject}
                        </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                              item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
                              item.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
                              item.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
                              item.appeal_type.tag === "CLOSURE_AGREEMENT") && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Источник финансирования (КВФО, КЦСР, КВР, КОСГУ)
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.source_funding &&
                                    item.source_funding) || "-"}
                                </div>
                              </div>
                            )}


                            {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                              item.appeal_type.tag === "REGISTRATION_AGREEMENT") && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Потребность / договор
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.requirement &&
                                    item.requirement) || "-"}
                                </div>
                              </div>
                            )}

                            {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                              item.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
                              item.appeal_type.tag === "CLOSURE_AGREEMENT") && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Сумма
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.amount &&
                                    new Intl.NumberFormat("ru-RU").format(
                                      Number(item.amount / 100).toFixed(2)
                                    )) || "-"}
                                </div>
                              </div>
                            )}

                            {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                              item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE") && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Способ закупки
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.purchase_method &&
                                  item.purchase_method === "EP" ? "ЕП." : item.purchase_method === "KP" ? "КП." : "Не указан") || "-"}
                                </div>
                              </div>
                            )}

                            {item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Предполагаемый срок размещения закупки
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.date_placement &&
                                    moment(item.date_placement.substring(0, 19)).format(
                                      "DD.MM.YYYY"
                                    )) || "-"}
                                </div>
                              </div>
                            )}

                            {item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Планируемый срок размещения закупки
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.date_placement &&
                                    moment(item.date_placement.substring(0, 19)).format(
                                      "DD.MM.YYYY"
                                    )) || "-"}
                                </div>
                              </div>
                            )}

                            {item.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Реквизит договора / ДС
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.contract_requisite &&
                                    item.contract_requisite) || "-"}
                                </div>
                              </div>
                            )}

                            {(item.appeal_type.tag === "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT" ||
                              item.appeal_type.tag === "ONE_MORE_QUESTION") && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Вопрос
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.question &&
                                    item.question) || "-"}
                                </div>
                              </div>
                            )}

                            {item.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Дата ДС
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.date_agreement &&
                                    moment(item.date_agreement.substring(0, 19)).format(
                                      "DD.MM.YYYY"
                                    )) || "-"}
                                </div>
                              </div>
                            )}

                            {item.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Документ (акт / платёж)
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.document_type &&
                                  item.document_type === "ACT" ? "Акт" : item.document_type === "PAYMENT" ? "Платёж" : "Не указан") || "-"}
                                </div>
                              </div>
                            )}

                            {item.appeal_type.tag === "REGISTRATION_FORMALIZATION" && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Дата документа
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.document_date &&
                                    moment(item.document_date.substring(0, 19)).format(
                                      "DD.MM.YYYY"
                                    )) || "-"}
                                </div>
                              </div>
                            )}

                            {(item.appeal_type.tag === "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE" ||
                              item.appeal_type.tag === "DOCUMENTS_FOR_PURCHASE" ||
                              item.appeal_type.tag === "REGISTRATION_AGREEMENT" ||
                              item.appeal_type.tag === "COORDINATION_OF_INDEPENDENT_GUARANTEES" ||
                              item.appeal_type.tag === "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION" ||
                              item.appeal_type.tag === "REGISTRATION_FORMALIZATION" ||
                              item.appeal_type.tag === "CLOSURE_AGREEMENT") && (
                              <div className="Form__field" style={{
                                marginBottom: 5,
                              }}>
                                <div className={"Field__label"} style={{
                                  marginBottom: 0,
                                  fontSize: 11,
                                }}>Примечание
                                </div>
                                <div className={"Field__text"} style={{
                                  fontSize: 13,
                                }}>
                                  {(!!item.note && item.note) || "-"}
                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={"Item__wrapper"}>
                      <div
                        className="Item__title"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          className="Element__title"
                          style={{
                            width: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              opacity: 0.75,
                              marginTop: 4,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {!!item.date_filing && (
                                <div
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 600,
                                    backgroundColor: "#20202015",
                                    display: "inline-block",
                                    color: "#202020",
                                    padding: "3px 6px 3px",
                                    borderRadius: 3,
                                    margin: "0 2px 0 0",
                                  }}
                                >
                                  {"Дата подачи: "}
                                  {!!item.date_filing &&
                                    moment(item.date_filing.substring(0, 19)).format(
                                      "DD.MM.YYYY HH:mm"
                                    )}
                                </div>
                              )}

                              {!!item.date_end && item.appeal_status.tag === "PROCESSING" && (
                                <div
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 600,
                                    backgroundColor: "#20202015",
                                    display: "inline-block",
                                    color: "#202020",
                                    padding: "3px 6px 3px",
                                    borderRadius: 3,
                                    margin: "0 2px 0 0",
                                  }}
                                >
                                  {"В работе до: "}
                                  {!!item.date_end &&
                                    moment(item.date_end.substring(0, 19)).format(
                                      "DD.MM.YYYY"
                                    )}
                                </div>
                              )}
                              {!!item.date_closing && item.appeal_status.tag === "EXECUTED" && (
                                <div
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 600,
                                    backgroundColor: "#20202015",
                                    display: "inline-block",
                                    color: "#202020",
                                    padding: "3px 6px 3px",
                                    borderRadius: 3,
                                    margin: "0 2px 0 0",
                                  }}
                                >
                                  {"Дата завершения: "}
                                  {!!item.date_closing &&
                                    moment(item.date_closing.substring(0, 19)).format(
                                      "DD.MM.YYYY"
                                    )}
                                </div>
                              )}
                            </div>
                          </div>

                          {!!item.date_filing && !!item.date_end && item.appeal_status.tag === "PROCESSING" && (
                            <div
                              style={{
                                height: 4,
                                width: "100%",
                                backgroundColor: "#2020200f",
                                borderRadius: 2,
                                marginTop: 4,
                              }}
                            >
                              <div
                                style={{
                                  height: 4,
                                  width: calculatePercentage(item.date_filing, item.date_end) + "%",
                                  backgroundColor: "#0061ff75",
                                  borderRadius: 2,
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime">
                        </div>
                      </div>
                    </div>
                  </li>

                  {/*{isUnroll(item.uuid) && (*/}
                  {/*  <li className="List__content">*/}
                  {/*  </li>*/}
                  {/*)}*/}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!isModalObjectAdd && (
          <ModalAppealView
            successfulRequest={() => (setIsUpdateList(!isUpdateList), setIsModalObjectAdd(false))}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalObjectShow && (
          <ModalAppealShow
            object={modalObjectShow}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectShow(null)}
          />
        )}

        {!!modalObjectDraft && (
          <ModalAppealDraft
            object={modalObjectDraft}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectDraft(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default MyAppealsList;
