import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Icon, Loading, Pagination, Search, Select, TextSearchMarker, Tooltip} from "@vokymlak/kabinet-ui";
import ModalMyMarkContractEdit from "./ModalMyMarkContractEdit";
import ModalAppealAdd from "../MyAppeals/ModalAppealAdd";
import ModalAppealShow from "../MyAppeals/ModalAppealShow";

function MyMarkContractsPage(props) {
  const {
    projects,
    setSelectProjectUuid,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
  } = props;

  const getProjectSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не указан",
      },
    ];

    list.map((item) => {
      let title = !!item.project_year ? item.project_year + " " : ""
      title = title + item.name
      title = title + (!!item.law ? " (ФЗ-" + item.law.name + ")" : "")

      const option = {
        value: item.uuid,
        title: title,
      };

      options.push(option);
    });

    return options;
  };

  const [modalContractEdit, setModalContractEdit] = useState(null);
  const [modalObjectRegistrationFormalization, setModalObjectRegistrationFormalization] = useState(null);
  const [modalObjectClosureAgreement, setModalObjectClosureAgreement] = useState(null);
  const [modalObjectShow, setModalObjectShow] = useState(null);

  function isClientViewed(item) {
    let isViewed = false

    if (!!item.comments) {
      item.comments.map(comment => {
        if (comment.index > item.client_viewed_at) {
          isViewed = true
        }
      })
    }

    return isViewed
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Документы на размещение исполнения
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            <span style={{minWidth: 320, maxWidth: 560, marginLeft: 16, fontWeight: 400}}>
                <Select
                  isFixed={true}
                  options={!!projects ? getProjectSelectOptions(projects) : getProjectSelectOptions([])}
                  label="Проект"
                  onChange={(event) => setSelectProjectUuid(event.target.value)}
                />
              </span>
          </div>

          <div className="Title__settings">
            {/*{!!list && (*/}
            {/*  <ButtonIcon*/}
            {/*    tooltipText={*/}
            {/*      list.length === unrolls.length ? "Свернуть" : "Развернуть"*/}
            {/*    }*/}
            {/*    iconName={*/}
            {/*      list.length === unrolls.length*/}
            {/*        ? "custom-unfold-less"*/}
            {/*        : "custom-unfold-more"*/}
            {/*    }*/}
            {/*    iconColor={"#20202075"}*/}
            {/*    size={28}*/}
            {/*    style={{ marginLeft: 8 }}*/}
            {/*    onClick={() => changeAllUnroll(list, "uuid")}*/}
            {/*  />*/}
            {/*)}*/}


            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {/*{!!count && !!pageLimit && (*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      marginLeft: 12,*/}
            {/*      width: 64,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      isFixed={true}*/}
            {/*      isDefaultOption={false}*/}
            {/*      value={pageLimit}*/}
            {/*      options={[*/}
            {/*        {*/}
            {/*          value: 100,*/}
            {/*          title: 100,*/}
            {/*        },*/}
            {/*        {*/}
            {/*          value: 500,*/}
            {/*          title: 500,*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*      label="Лимит"*/}
            {/*      onChangeSelect={(limit) => setPageLimit(Number(limit))}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content show">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={"List__item"}
                    style={{
                      paddingRight: 8,
                      cursor: "default",
                      position: "relative",
                      border: (!!item.is_closure_agreement && item.is_closure_agreement && "3px solid #673ab7") ||
                        (!!item.is_registration_formalization && item.is_registration_formalization && "3px solid #0061fc"),
                      borderRadius: (!!item.is_closure_agreement && item.is_closure_agreement && 9) ||
                        (!!item.is_registration_formalization && item.is_registration_formalization && 9)
                    }}
                    // onClick={() => setModalContractEdit(item)}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div>
                            {!!item.project && (
                              <div>
                                {!!item.project.name && (
                                  <span
                                    style={{
                                      marginRight: 4,
                                      fontSize: 13,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {!!item.project.project_year && item.project.project_year}{" "}{item.project.name}
                              </span>
                                )}
                              </div>
                            )}
                          </div>
                          <div>
                            {!!item.registry_number && (
                              <span style={{
                                marginRight: 6
                              }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                                <TextSearchMarker
                                  text={item.registry_number + " "}
                                  search={searchQuery}
                                />
                              </span>
                            )}
                            {!!item.contract_number && (
                              <TextSearchMarker
                                text={item.contract_number}
                                search={searchQuery}
                              />
                            )}
                            {!!item.uis_number && (
                            <div style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}>
                              <TextSearchMarker
                                text={item.uis_number}
                                search={searchQuery}
                              />
                            </div>
                          )}
                            {!!item.supplier && (
                              <div style={{
                                fontSize: 14,
                                fontWeight: 400
                              }}>
                                <TextSearchMarker
                                  text={item.supplier}
                                  search={searchQuery}
                                />
                              </div>
                            )}
                            {!!item.subject && (
                              <div style={{
                                fontSize: 16,
                                fontWeight: 400
                              }}>
                                <TextSearchMarker
                                  text={item.subject}
                                  search={searchQuery}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {!!item.is_closure_agreement && item.is_closure_agreement && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                // backgroundColor: "#673ab7",
                                backgroundColor: "#673ab7",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              На закрытии
                            </div>
                          )}
                          {!!item.is_registration_formalization && item.is_registration_formalization && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061fc",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              На исполнении
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      {/*{!item.ct_date_closing && !item.uis_number && (*/}
                      {/*  <div style={{*/}
                      {/*    marginRight: 12*/}
                      {/*  }}>*/}
                      {/*    <button*/}
                      {/*      className="Action__button Action__button_maxi Action__button_gray"*/}
                      {/*      onClick={() => setModalContractEdit(item)}*/}
                      {/*    >*/}
                      {/*      Закрыть*/}
                      {/*    </button>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      {(!item.is_closure_agreement && !item.is_registration_formalization) && !!item.uis_number && (
                        <div style={{
                          marginRight: 12
                        }}>
                          <button
                            className="Action__button Action__button_mini Action__button_blue"
                            onClick={() => setModalObjectRegistrationFormalization({project: item.project,contract: item})}
                          >
                            Исполнение
                          </button>
                        </div>
                      )}
                      {(!item.is_closure_agreement && !item.is_registration_formalization) && !item.uis_number && (
                        <div style={{
                          marginRight: 12
                        }}>
                          <button
                            className="Action__button Action__button_mini Action__button_gray"
                            onClick={() => setModalObjectClosureAgreement({project: item.project,contract: item})}
                          >
                            Закрыть
                          </button>
                        </div>
                      )}
                      {((!!item.is_closure_agreement && item.is_closure_agreement) ||
                        (!!item.is_registration_formalization && item.is_registration_formalization)) &&
                        !!item.appeals && item.appeals.length > 0 && (
                        <div style={{
                          marginRight: 12,
                          display: "flex",
                          alignItems: "center"
                        }}>
                          {item.appeals[0].appeal_status.tag === 'RETURNED' && (
                            // <div
                            //   style={{
                            //     fontSize: 11,
                            //     fontWeight: 700,
                            //     backgroundColor: "#f44336",
                            //     display: "inline-block",
                            //     color: "white",
                            //     padding: "2px 4px 2px",
                            //     borderRadius: 3,
                            //     marginRight: 4,
                            //   }}
                            // >
                            //   Есть замечания
                            // </div>
                            <Tooltip
                              text={"Есть&nbsp;замечания!"}
                              style={{
                                marginRight: 8,
                              }}
                            >
                              <div
                                style={{
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <Icon name={"custom-alert"} color={"#f44336"} size={24}/>
                              </div>
                            </Tooltip>
                          )}
                          {isClientViewed(item.appeals[0]) && (
                            <Tooltip
                              text={"Новые&nbsp;сообщения!"}
                              style={{
                                marginRight: 8,
                              }}
                            >
                              <div
                                style={{
                                  width: 28,
                                  height: 28,
                                }}
                              >
                                <Icon name={"custom-comment"} color={"#0061ff"} size={28}/>
                              </div>
                            </Tooltip>
                          )}
                          <button
                            className="Action__button Action__button_mini Action__button_gray"
                            onClick={() => setModalObjectShow(item.appeals[0])}
                          >
                            Обращение
                          </button>
                        </div>
                      )}
                      {/*{!!item.ct_date_closing && (*/}
                      {/*  <div style={{*/}
                      {/*    display: "flex",*/}
                      {/*    alignItems: "center",*/}
                      {/*    justifyContent: " flex-start",*/}
                      {/*    width: "100%",*/}
                      {/*  }}>*/}
                      {/*    /!* Комментарий по исполнению *!/*/}
                      {/*    {(!!item.ct_execution_note && (*/}
                      {/*      <Tooltip text={item.ct_execution_note}*/}
                      {/*               style={{height: 24, marginLeft: 8}}>*/}
                      {/*        <Icon*/}
                      {/*          name={"custom-info"}*/}
                      {/*          color={"#202020"}*/}
                      {/*          size={24}*/}
                      {/*        />*/}
                      {/*      </Tooltip>*/}
                      {/*    ))}*/}

                      {/*    /!* Источники исполнения *!/*/}
                      {/*    <div style={{*/}
                      {/*      padding: "4px 8px",*/}
                      {/*      backgroundColor: "#20202010",*/}
                      {/*      borderRadius: 4,*/}
                      {/*      marginLeft: 8,*/}
                      {/*      textAlign: "right"*/}
                      {/*    }}>*/}
                      {/*      <div style={{*/}
                      {/*        fontSize: 21,*/}
                      {/*        fontWeight: 700,*/}
                      {/*      }}>*/}
                      {/*        {(!!item.ct_execution_source && (item.ct_execution_source)) || "-"}*/}
                      {/*      </div>*/}
                      {/*      <div style={{*/}
                      {/*        fontSize: 12,*/}
                      {/*        fontWeight: 600,*/}
                      {/*      }}>*/}
                      {/*        Источник*/}
                      {/*      </div>*/}
                      {/*    </div>*/}

                      {/*    /!* Сумма исполнения *!/*/}
                      {/*    <div style={{*/}
                      {/*      padding: "4px 8px",*/}
                      {/*      backgroundColor: "#20202010",*/}
                      {/*      borderRadius: 4,*/}
                      {/*      marginLeft: 8,*/}
                      {/*      textAlign: "right"*/}
                      {/*    }}>*/}
                      {/*      <div style={{*/}
                      {/*        fontSize: 21,*/}
                      {/*        fontWeight: 700,*/}
                      {/*      }}>*/}
                      {/*        {(!!item.ct_execution_amount && new Intl.NumberFormat("ru-RU").format(*/}
                      {/*          Number(item.ct_execution_amount / 100).toFixed(2)*/}
                      {/*        )) || "-"}*/}
                      {/*      </div>*/}
                      {/*      <div style={{*/}
                      {/*        fontSize: 12,*/}
                      {/*        fontWeight: 600,*/}
                      {/*      }}>*/}
                      {/*        Сумма*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*)}*/}
                    </div>
                  </li>

                  {/*{!isUnroll(item.uuid) && (*/}
                  {/*  <li className="List__content">*/}
                  {/*    <CompanyProjectsList*/}
                  {/*      company={item}*/}
                  {/*      isUpdate={isUpdateList}*/}
                  {/*      isClient={true}*/}
                  {/*    />*/}
                  {/*  </li>*/}
                  {/*)}*/}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalContractEdit && (
          <ModalMyMarkContractEdit
            contract={modalContractEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalContractEdit(null)}
          />
        )}

        {!!modalObjectShow && (
          <ModalAppealShow
            object={modalObjectShow}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectShow(null)}
          />
        )}

        {!!modalObjectRegistrationFormalization && (
          <ModalAppealAdd
            type={"REGISTRATION_FORMALIZATION"}
            isDraft={false}
            project={modalObjectRegistrationFormalization.project}
            contract={modalObjectRegistrationFormalization.contract}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectRegistrationFormalization(null)}
          />
        )}

        {!!modalObjectClosureAgreement && (
          <ModalAppealAdd
            type={"CLOSURE_AGREEMENT"}
            isDraft={false}
            project={modalObjectClosureAgreement.project}
            contract={modalObjectClosureAgreement.contract}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectClosureAgreement(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default MyMarkContractsPage;
